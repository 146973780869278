<template>
    <div class="row">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">

            <div class="card ml-auto mr-auto mt-5">
                <div class="row m-0">

                    <div class="BorderBottom col-6 mt-4">
                        <span class=" DayHeading">List Of Companies</span>
                    </div>
                    <div class="BorderBottom col-6 text-right">
                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="AddCompany"><i class="fa fa-plus"></i> Add Company</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="GetCompanyInformation"> Push Record</a>

                    </div>

                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">

                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <div v-for="(company, index) in companies" :key="index+3">

                                <div class="accordion" role="tablist">
                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <table class="table table-striped table-hover table_list_bg" style="margin:0;">
                                                <tbody>
                                                    <tr>
                                                        <td style="width:3%">
                                                            {{index+1}}
                                                        </td>
                                                        <td style="width: 23%; text-align: left">
                                                            <strong>

                                                                <a href="javascript:void(0)" v-b-toggle.accordion-1 v-on:click="makeActiveCollapse(index,company.id)" style="color: #219653">{{company.nameEnglish}}</a>

                                                            </strong>
                                                            <div>
                                                                <strong>
                                                                    {{company.nameArabic}}
                                                                </strong>

                                                            </div>
                                                        </td>

                                                        <td style="width: 23%; text-align: right">

                                                            CR({{company.companyRegNo}})
                                                            <div>
                                                                Vat({{company.vatRegistrationNo}})
                                                            </div>
                                                        </td>

                                                        <td style="width: 15%; text-align: left;padding-left:8px;">
                                                            Ph({{company.phoneNo}})
                                                        </td>
                                                        <td style="width: 15%; text-align: left">
                                                            Client NO({{company.clientNo}})
                                                        </td>
                                                        <td style="width: 20%; text-align: right">
                                                            <!--<button type="button" class="btn btn-primary " style="background-color: #219653; border-color: #219653" v-on:click="EditCompany(company.id)"> <i class="fa fa-edit"></i> Edit Record </button>-->
                                                            <button type="button" class="btn btn-sm btn-primary mr-2" style="background-color: #219653; border-color: #219653" v-on:click="AddBusiness(company.id)"> <i class="fa fa-plus"></i> Bus </button>
                                                            <!--<button type="button" class="btn btn-sm btn-primary " style="background-color: #219653; border-color: #219653" v-on:click="GetCompanyInformation(company.id)"> Push Data</button>-->

                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!--<a href="javascript:void(0)" v-b-toggle.accordion-1 v-on:click="makeActiveCollapse(index)">{{company.nameEnglish}}</a>-->
                                        </b-card-header>

                                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" v-if="index==collpase">
                                            <b-card-body style="padding-right:0 !important">
                                                <div v-for="(business, busIndex) in businesses" :key="busIndex+3">
                                                    <div class="accordionchild" role="tablist">
                                                        <b-card no-body class="mb-1">
                                                            <b-card-header header-tag="header" class="p-1" role="tab">
                                                                <table class="table table-striped table-hover table_list_bg" style="margin:0;">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="width:4%">
                                                                                {{(index + 1) + '.' + (busIndex+1)}}
                                                                            </td>
                                                                            <td style="width:25%; text-align:left">
                                                                                <strong>

                                                                                    <a href="javascript:void(0)" v-b-toggle.accordion-11 v-on:click="makeActiveBusCollapse(busIndex, business.id)" style="color: darkseagreen">{{business.nameEnglish}}</a>
                                                                                </strong>
                                                                                <div>
                                                                                    <strong>
                                                                                        {{business.nameArabic}}
                                                                                    </strong>
                                                                                </div>
                                                                            </td>

                                                                            <td style="width: 30%; text-align: left">
                                                                                {{business.categoryInEnglish}}
                                                                                <div>
                                                                                    {{business.categoryInArabic}}
                                                                                </div>


                                                                            </td>

                                                                            <td style="width: 25%; text-align: left">
                                                                                {{business.addressEnglish}}
                                                                                <div>
                                                                                    {{business.addressArabic}}
                                                                                </div>
                                                                            </td>
                                                                            <td style="width: 16%; text-align: right">
                                                                                <button type="button" class="btn btn-sm btn-primary " style="background-color: darkseagreen; border-color: darkseagreen " v-on:click="Addlocation(business.id,business.clientParentId)"><i class="fa fa-plus"></i> Add Loc </button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-11" accordion="my-accordionchild" role="tabpanel" v-if="busIndex==busCollapse">
                                                                <b-card-body style="padding-right:0 !important">
                                                                    <div v-for="(location, locIndex) in locations" :key="locIndex+3">
                                                                        <div class="accordionInnerchild" role="tablist">
                                                                            <b-card no-body class="mb-1">
                                                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                                                    <table class="table table-striped table-hover table_list_bg" style="margin:0;">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td style="width: 5%">
                                                                                                    {{(index + 1) + '.' + (busIndex+1) + '.' + (locIndex+1)}}
                                                                                                </td>
                                                                                                <td v-if="location.nobleGroupId != null" style="width: 15%; text-align: left">
                                                                                                    <strong>

                                                                                                        <a href="javascript:void(0)" v-on:click="EditCompanyGroup(location.id)">{{location.nameEnglish}}</a>
                                                                                                    </strong>
                                                                                                    <div>
                                                                                                        <strong>
                                                                                                            {{location.nameArabic}}
                                                                                                        </strong>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td v-else style="width: 15%; text-align: left">
                                                                                                    <strong>

                                                                                                        {{location.nameEnglish}}
                                                                                                    </strong>
                                                                                                    <div>
                                                                                                        <strong>
                                                                                                            {{location.nameArabic}}
                                                                                                        </strong>
                                                                                                    </div>
                                                                                                </td>

                                                                                                <td class="text-center" style="width: 10%; text-align: left">
                                                                                                    Ph({{location.phoneNo}})
                                                                                                </td>
                                                                                                <td class="text-center" style="width: 30%; text-align: left">
                                                                                                    {{location.addressEnglish}}
                                                                                                    <div>
                                                                                                        {{location.addressArabic}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center" style="width: 10%; text-align: left">
                                                                                                    {{location.groupName}}
                                                                                                    <div>
                                                                                                        {{location.licenseType}}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center" style="width: 10%; text-align: left" v-if="location.technicalSupportPeriod != 'UnLimited' && !location.isTechnicalSupport">
                                                                                                    {{location.endDate}}
                                                                                                    <div>
                                                                                                        (End Date)
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center" style="width: 10%; text-align: left" v-else-if="location.technicalSupportPeriod != 'UnLimited' && location.isTechnicalSupport">
                                                                                                    {{location.endDate}}
                                                                                                    <div>
                                                                                                        (Technical End Date)
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center" style="width: 10%; text-align: left" v-else>
                                                                                                    -
                                                                                                </td>

                                                                                                <td class="text-center dropdown" style="width: 10%">
                                                                                                    <button class="dropdown-toggle btn btn-primary  btn-block" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                        Actions
                                                                                                    </button>
                                                                                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                                                        <a class="dropdown-item" href="javascript:void(0);" v-on:click="syncSetup(location.id)">Sync Setup</a>
                                                                                                        <a class="dropdown-item" href="javascript:void(0);" v-on:click="getWareHouse(location.id)">View WareHouse</a>


                                                                                                        <a class="dropdown-item" href="javascript:void(0);" v-on:click="getTerminal(location.id)">View Terminal</a>
                                                                                                    </div>

                                                                                                </td>

                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <!--<b-button block v-b-toggle.accordion-111 variant="primary" v-on:click="makeActiveLocCollapse(locIndex)">{{location}}</b-button>-->
                                                                                </b-card-header>

                                                                            </b-card>

                                                                        </div>
                                                                    </div>

                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>

                                                    </div>
                                                </div>


                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>

                                </div>

                            </div>



                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--<licensing :license="newLicense"
                   :show="show"
                   v-if="show"
                   @close="IsSave"
                   :type="type" />

        <login-history-model :show="showHistory"
                             :companyName="companyName"
                             :companyLicenceList="companyLicenceList"
                             v-if="showHistory"
                             @close="showHistory = false">

        </login-history-model>
        <payment-limit-model :show="showPaymentLimit"
                             :paymentLimit="newPaymentLimit"
                             v-if="showPaymentLimit"
                             @close="showPaymentLimit = false">

        </payment-limit-model>-->
        <loading :active.sync="loading"
                 :can-cancel="true"
                 :is-full-page="true"></loading>
    </div>
</template>


<script>
    import axios from 'axios'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        components: {
            Loading
        },
        data: function () {
            return {
                searchQuery: '',
                show: false,
                loading: false,
                companyList: [],
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                companyLicenceList: [],
                newPaymentLimit: {
                    id: '00000000-0000-0000-0000-000000000000',
                    fromDate: '',
                    toDate: '',
                    message: '',
                    isActive: '',
                    companyId: '00000000-0000-0000-0000-000000000000'
                },
                showHistory: false,
                showPaymentLimit: false,
                newLicense: {
                    nobleGroupId: '',
                    fromDate: '',
                    toDate: '',
                    isActive: false,
                    isBlock: false,
                    companyId: '',
                    licenseType: '',
                    gracePeriod: false,
                    paymentFrequency: '',
                    isTechnicalSupport: false,
                    isUpdateTechnicalSupport: false,
                    technicalSupportPeriod: '',
                    activationPlatform: ''
                },
                companies: [],
                businesses: [],
                locations: [],
                collpase: '',
                busCollapse: '',
            }
        },
        watch: {
            search: function (val) {
                this.GetCompanyData(val, 1);
            }
        },
        methods: {
            syncSetup: function (companyId) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/System/SyncSetup?companyId=' + companyId, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data);
                    })
            },
            getTerminal: function (id) {
                
                var option = ''
                //this.companyOptionList.forEach(function (x) {
                //    if (x.locationId == id && x.label == 'overWrite')
                //        option = x.value
                //});
                //console.log(option)
                this.$router.push({
                    path: '/terminal',
                    query: {
                        id: id,
                        option: option
                    }
                })
            },
            getWareHouse: function (id) {
                this.$router.push({
                    path: '/warehouse',
                    query: {
                        id: id
                    }
                })
            },
            AddCompany: function () {
                this.$router.push('/AddCompany')
            },
            EditCompany: function (Id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/EditCompany?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.companylist = response.data
                    }
                    root.$router.push({
                        path: '/AddCompany',
                        query: {
                            data: root.companylist
                        }
                    })
                });
            },
            AddBusiness: function (companyId) {
                this.$router.push({
                    path: '/addbusiness',
                    query: { data: companyId }
                })
            },
            Addlocation: function (busId, clientParentId) {

                this.$router.push({
                    path: '/addlocation',
                    query: { busId: busId, clientParentId: clientParentId }
                })
            },
            GetCompanyInformation: function () {
                var root = this;
                
                root.loading = true;
                this.$https.get('/Company/GetCompanyInformationForPermission').then(function (response) {
                    if (response.data != null) {
                        if (response.data.isSuccess) {
                            axios.post(root.$PermissionIp + '/NoblePermission/CompanyInformation', response.data.message).then(function (res) {
                                if (res.data != null) {
                                    if (res.data.isSuccess && res.data.message === 'Updated') {
                                        root.$swal({
                                            title: "Updated!",
                                            text: "Push Record Updated!",
                                            type: 'success',
                                            icon: 'success',
                                            showConfirmButton: false,
                                            timer: 1500,
                                            timerProgressBar: true,
                                        });
                                    }
                                    else if (res.data.isSuccess && res.data.message === 'Success') {
                                        root.$swal({
                                            title: "Saved!",
                                            text: "Push Record Added!",
                                            type: 'success',
                                            icon: 'success',
                                            showConfirmButton: false,
                                            timer: 1500,
                                            timerProgressBar: true,
                                        });
                                    }
                                    else if (res.data.isSuccess && res.data.message === 'Success') {
                                        root.$swal.fire(
                                            {
                                                icon: 'error',
                                                title: 'Something Went Wrong to push record!',
                                                text: res.data.message,

                                                showConfirmButton: false,
                                                timer: 5000,
                                                timerProgressBar: true,
                                            });
                                    }
                                }
                                root.loading = false;
                            }).catch(error => {
                                console.log(error)
                                root.$swal.fire(
                                    {
                                        icon: 'error',
                                        title: 'Something Went Wrong to push record!',
                                        text: error.response.data,

                                        showConfirmButton: false,
                                        timer: 5000,
                                        timerProgressBar: true,
                                    });
                                root.loading = false;

                            });
                        }

                    }

                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Something Went Wrong to fetch record!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    root.loading = false;

                });
            },
            //DisActiveLicense: function (companyId, isCompany) {
            //    var root = this;
            //    
            //    this.$https.get('/NoblePermission/DisActiveLicense?companyId=' + companyId + '&isCompany=' + isCompany).then(function (response) {
            //        if (response.data.isSuccess) {
            //            root.$swal({
            //                icon: 'success',
            //                title: 'Saved Successfully!',
            //                showConfirmButton: false,
            //                timer: 1500,
            //                timerProgressBar: true,
            //            });
            //            root.$router.push('/company');


            //        }
            //        else {
            //            root.$swal({
            //                title: "Error!",
            //                text: response.data.message,
            //                type: 'error',
            //                icon: 'error',
            //                showConfirmButton: false,
            //                timer: 1500,
            //                timerProgressBar: true,
            //            });
            //        }

            //    }).catch(error => {
            //        console.log(error)
            //        root.$swal.fire(
            //            {
            //                icon: 'error',
            //                title: 'Something Went Wrong!',
            //                text: error.response.data,

            //                showConfirmButton: false,
            //                timer: 5000,
            //                timerProgressBar: true,
            //            });

            //    });
            //},
            makeActiveCollapse: function (item, companyId) {

                this.collpase = item;
                this.businesses = []
                var root = this;
                this.companyList.businesses.forEach(function (x) {

                    if (x.clientParentId === companyId) {
                        root.businesses.push(x)
                    }
                })
                //this.businesses.push(.find(x => x.clientParentId == companyId))
            },
            makeActiveBusCollapse: function (item, busId) {
                this.busCollapse = item;
                this.locations = []
                var root = this;
                this.companyList.locations.forEach(function (x) {

                    if (x.businessParentId === busId) {
                        root.locations.push(x)
                    }
                })
            },


            getPage: function () {
                this.GetCompanyData(this.search, this.currentPage);
            },

            GetCompanyData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/GetCompanyList', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        console.log(response.data.message)
                        root.companies = response.data.message.companies
                        root.companyList = response.data.message
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            showLicenceHistory: function (name, licenceList) {

                this.companyLicenceList = [];
                this.companyName = name;
                this.showHistory = !this.showHistory;
                this.companyLicenceList = licenceList;
            }

        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.GetCompanyData();

        }
    }</script>