<template>
    <div v-if="(((isValid('CanViewJV') || isValid('CanDraftJV')) && formName=='JournalVoucher') || ((isValid('CanViewOC') || isValid('CanDraftOC')) && formName=='OpeningCash'))">
        <div class="card-body" v-if="loading">
            <div class="text-center" id="preloader">
                <div id="loader"></div>
            </div>
        </div>
        <div class="row" v-else v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
                <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <h5 class="page_title" v-if="formName=='JournalVoucher'">{{ $t('JournalVoucherView.JournalVouchers') }}</h5>
                        <h5 class="page_title" v-if="formName=='OpeningCash'">{{ $t('JournalVoucherView.OpeningCashList') }}</h5>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('JournalVoucherView.Home') }}</a></router-link></li>

                                <li class="breadcrumb-item active" aria-current="page" v-if="formName=='JournalVoucher'">{{ $t('JournalVoucherView.JournalVouchers') }}</li>
                                <li class="breadcrumb-item active" aria-current="page" v-if="formName=='OpeningCash'">{{ $t('JournalVoucherView.OpeningCashList') }}</li>
                            </ol>
                        </nav>
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-6">


                    </div>
                </div>
                <div class="card ">
                    <div class="card-header " v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'direction: rtl'">
                        <div class="row mb-3 ml-1 ">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>{{ $t('JournalVoucherView.SearchbyVoucherNumber') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('JournalVoucherView.Search')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-if="formName=='OpeningCash'" style="color:white !important">
                                <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" v-if="disable==false">
                                    <a v-on:click="AddJournalVoucher" v-if="isValid('CanAddOC') || isValid('CanDraftOC')" class="btn btn-primary "><i class="fa fa-plus"></i> {{ $t('JournalVoucherView.AddNew') }}</a>
                                    <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('JournalVoucherView.Close') }}</a></router-link>
                                </div>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-if="formName=='JournalVoucher'" style="color:white !important">
                                <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'" v-if="isValid('CanDraftJV') || isValid('CanAddJV')">
                                    <a v-on:click="AddJournalVoucher" class="btn btn-primary "><i class="fa fa-plus"></i> {{ $t('JournalVoucherView.AddNew') }}</a>
                                    <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('JournalVoucherView.Close') }}</a></router-link>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="col-lg-12">
                            <div class=" mb-2">
                                <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
                                    <ul class="nav nav-tabs" data-tabs="tabs">
                                        <li v-if=" isValid('CanDraftJV') && formName=='JournalVoucher'" class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Draft'}" v-on:click="makeActive('Draft')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('JournalVoucherView.Draft') }}</a></li>
                                        <li v-if="isValid('CanDraftOC') && formName=='OpeningCash'" class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Draft'}" v-on:click="makeActive('Draft')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{ $t('JournalVoucherView.Draft') }}</a></li>
                                        <li v-if="isValid('CanViewJV') && formName=='JournalVoucher'" class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Approved'}" v-on:click="makeActive('Approved')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('JournalVoucherView.Post') }}</a></li>
                                        <li v-if="isValid('CanViewOC') && formName=='OpeningCash'" class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Approved'}" v-on:click="makeActive('Approved')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">{{ $t('JournalVoucherView.Post') }}</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="active == 'Draft'">
                                <div class="mt-2">
                                    <div class="table-responsive">
                                        <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <thead class="">
                                                <tr>

                                                    <th>#</th>
                                                    <th v-if="formName=='JournalVoucher'">
                                                        {{ $t('JournalVoucherView.VoucherNo') }}
                                                    </th>
                                                    <th v-if="formName=='OpeningCash'">
                                                        {{ $t('JournalVoucherView.DocumentNumber') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('JournalVoucherView.Date') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('JournalVoucherView.TotalDebit') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('JournalVoucherView.TotalCredit') }}
                                                    </th>
                                                    <th v-if="( isValid('CanViewDetailOC') && formName=='OpeningCash') || (isValid('CanViewDetailJV') && formName=='JournalVoucher')">
                                                        {{ $t('JournalVoucherView.View') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(journalVoucher,index) in journalVoucherList" v-bind:key="journalVoucher.id">
                                                    <!--<td>
                                                        <label class="form-checkbox">
                                                            <input type="checkbox" :value="journalVoucher.id" v-model="selected">
                                                            <i class="form-icon"></i>
                                                        </label>
                                                    </td>-->
                                                    <td v-if="currentPage === 1">
                                                        {{index+1}}
                                                    </td>
                                                    <td v-else>
                                                        {{((currentPage*10)-10) +(index+1)}}
                                                    </td>


                                                    <td v-if=" (isValid('CanEditJV') && formName==='JournalVoucher') ||  (isValid('CanEditOC') && formName==='OpeningCash')">
                                                        <strong>
                                                            <a href="javascript:void(0)" v-on:click="EditJV(journalVoucher.id,false)">{{journalVoucher.voucherNumber}}</a>
                                                        </strong>
                                                    </td>

                                                    <td v-else>
                                                        {{journalVoucher.voucherNumber}}
                                                    </td>
                                                    <td>
                                                        {{getDate(journalVoucher.date)}}
                                                    </td>
                                                    <td>
                                                        {{currency}} {{journalVoucher.totalDr}}
                                                    </td>
                                                    <td>
                                                        {{currency}} {{journalVoucher.totalCr}}
                                                    </td>
                                                    <td v-if="( isValid('CanViewDetailOC') && formName=='OpeningCash') || (isValid('CanViewDetailJV') && formName=='JournalVoucher')">
                                                        <a href="javascript:void(0)" class="btn  btn-icon btn-danger btn-sm mr-2" v-on:click="EditJV(journalVoucher.id,true)"><i class=" fas fa-eye"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div v-if="active == 'Approved'">
                                <div class="table-responsive">
                                    <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <thead class="">
                                            <tr>
                                                <!--<th>
                                                    <label class="form-checkbox">
                                                        <input type="checkbox" v-model="selectAll" @click="select">
                                                        <i class="form-icon"></i>
                                                    </label>
                                                </th>-->
                                                <th>#</th>
                                                <th v-if="formName=='JournalVoucher'">
                                                    {{ $t('JournalVoucherView.VoucherNo') }}
                                                </th>
                                                <th v-if="formName=='OpeningCash'">
                                                    {{ $t('JournalVoucherView.DocumentNumber') }}
                                                </th>
                                                <th>
                                                    {{ $t('JournalVoucherView.Date') }}
                                                </th>
                                                <th>
                                                    {{ $t('JournalVoucherView.TotalDebit') }}
                                                </th>
                                                <th>
                                                    {{ $t('JournalVoucherView.TotalCredit') }}
                                                </th>
                                                <th v-if="( isValid('CanViewDetailOC') && formName=='OpeningCash') || (isValid('CanViewDetailJV') && formName=='JournalVoucher')">
                                                    {{ $t('JournalVoucherView.View') }}
                                                </th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(journalVoucher,index) in journalVoucherList" v-bind:key="journalVoucher.id">
                                                <!--<td>
                                                    <label class="form-checkbox">
                                                        <input type="checkbox" :value="journalVoucher.id" v-model="selected">
                                                        <i class="form-icon"></i>
                                                    </label>
                                                </td>-->
                                                <td v-if="currentPage === 1">
                                                    {{index+1}}
                                                </td>
                                                <td v-else>
                                                    {{((currentPage*10)-10) +(index+1)}}
                                                </td>

                                                <td v-if=" (isValid('CanEditJV') && formName==='JournalVoucher') ||  (isValid('CanEditOC') && formName==='OpeningCash')">
                                                    <strong>
                                                        <a href="javascript:void(0)" v-on:click="EditJV(journalVoucher.id,false)">{{journalVoucher.voucherNumber}}</a>
                                                    </strong>
                                                </td>
                                                <td v-else>

                                                    {{journalVoucher.voucherNumber}}

                                                </td>
                                                <td>
                                                    {{getDate(journalVoucher.date)}}
                                                </td>
                                                <td>
                                                    {{currency}} {{journalVoucher.totalDr}}
                                                </td>
                                                <td>
                                                    {{currency}} {{journalVoucher.totalCr}}
                                                </td>
                                                <td v-if="( isValid('CanViewDetailOC') && formName=='OpeningCash') || (isValid('CanViewDetailJV') && formName=='JournalVoucher')">
                                                    <a href="javascript:void(0)" class="btn  btn-icon btn-danger btn-sm mr-2" v-on:click="EditJV(journalVoucher.id,true)"><i class=" fas fa-eye"></i></a>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="float-left">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="float-right">
                                <div class="overflow-auto" v-on:click="getPage()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                    <b-pagination pills size="lg" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :key="randerList"
                                                  :per-page="10"
                                                  first-text="First"
                                                  prev-text="Previous"
                                                  next-text="Next"
                                                  last-text="Last"></b-pagination>
                                </div>
                                <div class="overflow-auto" v-on:click="getPage()" v-else>
                                    <b-pagination pills size="lg" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :key="randerList"
                                                  :per-page="10"
                                                  first-text="الأولى"
                                                  prev-text="السابقة"
                                                  next-text="التالية"
                                                  last-text="الأخيرة"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>

    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    export default {
        props: ['formName'],
        mixins: [clickMixin],
        data: function () {

            return {
                active: 'Draft',
                search: '',
                journalVoucherList: [],
                currentPage: 1,
                randerList: 0,
                pageCount: '',
                rowCount: '',
                loading: true,
                show: false,
                currency: '',
                disable: false,

                selected: [],
                selectAll: false,
                updateApprovalStatus: {
                    id: '',
                    approvalStatus: ''
                }

            }
        },
        watch: {
            search: function (val) {
                this.getData(val, 1, this.active);
            },
            formName: function () {

                if (this.$route.query.data == 'JournalVoucherViews' + this.formName) {
                    this.$emit('input', 'JournalVoucherViews' + this.formName);
                    if (this.formName == 'JournalVoucher') {
                        this.formName = 'JournalVoucher';
                        if (localStorage.getItem('active') != null && localStorage.getItem('active') != '' && localStorage.getItem('active') != undefined) {
                            this.currentPage = parseInt(localStorage.getItem('currentPage'));
                            this.active = (localStorage.getItem('active'));
                            this.getPage();


                        }

                    }
                    if (this.formName == 'OpeningCash') {
                        this.formName = 'OpeningCash';
                        if (localStorage.getItem('active') != null && localStorage.getItem('active') != '' && localStorage.getItem('active') != undefined) {
                            this.currentPage = parseInt(localStorage.getItem('currentPage'));
                            this.active = (localStorage.getItem('active'));
                            this.getPage();


                        }

                    }

                }
                else {
                    this.currentPage = 1;
                    this.active = 'Draft';
                    if (this.formName == 'JournalVoucher') {
                        this.formName = 'JournalVoucher';
                        this.getData(this.search, this.currentPage, this.active, this.formName);

                    }
                    if (this.formName == 'OpeningCash') {
                        this.formName = 'OpeningCash';
                        this.getData(this.search, this.currentPage, this.active, this.formName);

                    }
                    this.$emit('input', this.$route.name + this.formName);

                }


            }

        },
        methods: {
            EditJV: function (id, view) {

                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var childFormName = this.formName;

                root.$https.get('/JournalVoucher/JournalVoucherDetail?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    root.jv = response.data;
                    root.$router.push({
                        path: '/addjournalvoucher?formName=' + childFormName,
                        query: { data: root.jv, view: view }
                    })





                });


            },
            RemoveJV: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/JournalVoucher/DeleteJournalVoucher?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                            root.$store.state.journalVoucherList.splice(root.$store.state.journalVoucherList.findIndex(function (i) {
                                return i.id === response.data.id;
                            }), 1);
                            root.$swal({
                                title: 'Deleted!',
                                text: response.data.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                        } else {
                            root.$swal({
                                title: "Error!",
                                text: response.data.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        }
                    },
                        function () {

                            root.$swal({
                                title: "Error!",
                                text: "Update UnSuccessfully",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            AddJournalVoucher: function () {
                this.$router.push('/addjournalvoucher?formName=' + this.formName);


            },
            getDate: function (date) {
                return moment(date).format('l');
            },
            makeActive: function (item) {


                this.active = item;
                this.selectAll = false;
                this.selected = [];
                this.getData(this.search, 1, item, this.formName);
            },
            UpdateApprovalStatus: function (approvalStatus) {


                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.updateApprovalStatus.approvalStatus = approvalStatus;
                this.updateApprovalStatus.id = this.selected;
                root.$https.post('/JournalVoucher/UpdateApprovalStatus', this.updateApprovalStatus, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            if (response.data.message.id != '00000000-0000-0000-0000-000000000000') {

                                root.$swal({
                                    title: 'Status Changed!',
                                    text: response.data.message.isAddUpdate,
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                }).then(function (result) {
                                    if (result) {
                                        window.location.href = "/JournalVoucher";
                                    }
                                });

                            } else {
                                root.$swal({
                                    title: "Error!",
                                    text: response.data.message.isAddUpdate,
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        }
                    });
            },
            select: function () {
                this.selected = [];
                if (!this.selectAll) {
                    for (let i in this.journalVoucherList) {
                        this.selected.push(this.journalVoucherList[i].id);
                    }
                }
            },

            getPage: function () {


                this.getData(this.search, this.currentPage, this.active, this.formName);
            },
            getData: function (search, currentPage, status, formName) {
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var isOpeningCash = false;
                if (formName == 'OpeningCash') {
                    isOpeningCash = true;
                }
                else {
                    isOpeningCash = false;
                }
                localStorage.setItem('currentPage', this.currentPage);
                localStorage.setItem('active', this.active);
                root.$https.get('/JournalVoucher/JournalVoucherList?searchTerm=' + search + '&pageNumber=' + currentPage + '&status=' + status + '&isOpeningCash=' + isOpeningCash, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        root.loading = false;
                        root.$store.dispatch('GetjournalVoucherList', response.data.results);

                        root.journalVoucherList = response.data.results;
                        if (root.formName == 'OpeningCash') {

                            if (response.data.rowCount > 1) {
                                root.disable = true;
                            }
                        }
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.currentPage = currentPage;
                        root.randerList++;
                        root.loading = false;
                    });
            },



        },
        created: function () {

            if (this.$route.query.data == 'JournalVoucherViews' + this.formName) {
                this.$emit('input', 'JournalVoucherViews' + this.formName);


            }
            else {
                this.$emit('input', this.$route.name + this.formName);
                localStorage.removeItem("fromDate");
                localStorage.removeItem("toDate");
                localStorage.removeItem("active");
                localStorage.removeItem("currentPage");


            }

        },
        mounted: function () {


            if (localStorage.getItem('active') != null && localStorage.getItem('active') != '' && localStorage.getItem('active') != undefined) {

                this.currentPage = parseInt(localStorage.getItem('currentPage'));
                if (this.currentPage == undefined || isNaN(this.currentPage)) {
                    this.currentPage = 1;
                }
                this.active = (localStorage.getItem('active'));
                this.getPage();


            }
            else {

                if (this.isValid('CanDraftJV') && this.formName == 'JournalVoucher') {
                    this.makeActive("Draft");
                }
                else if (this.isValid('CanViewJV') && this.formName == 'JournalVoucher') {
                    this.makeActive("Approved");
                }
                else if (this.isValid('CanViewOC') && this.formName == 'OpeningCash') {
                    this.makeActive("Approved");
                }
                else if (this.isValid('CanDraftOC') && this.formName == 'OpeningCash') {
                    this.makeActive("Draft");
                }
            }

            this.currency = localStorage.getItem('currency');
        }

    }
</script>
