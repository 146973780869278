<template>
    <div class="row" v-if="isValid('CanViewInquirySetup')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="BorderBottom ml-2 mr-2 mt-3 mb-3">
                    <span class=" DayHeading">{{ $t('InquirySetupList.InquirySetupList') }}</span>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('InquirySetupList.SearchbySetup') }}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" name="search" id="search" :placeholder=" $t('InquirySetupList.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a v-if="isValid('CanAddInquirySetup')" href="javascript:void(0)" class="btn btn-primary " style="margin-top:27px;" v-on:click="openmodel"><i class="fa fa-plus"></i> {{ $t('InquirySetupList.AddNew') }}</a>
                            <router-link :to="'/InquirySetup'">
                                <a href="javascript:void(0)" class="btn btn-outline-danger " style="margin-top:27px;">  <i class="fas fa-arrow-circle-left fa-lg"></i> </a>
                            </router-link>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                            <div class="row" style="padding: 12px 7px; background-color: #E1ECFF; color: #060606; margin: unset;">
                                <div class="col-12" style="display: flex; ">
                                    <div style="width:4%; font-weight:bolder;text-align:center;color:black !important; "><span>#</span></div>
                                    <div style="width:15%;text-align:center;font-weight:bold;color:black !important;">{{ $t('InquirySetupList.Code') }}</div>
                                    <div style="width:20%;text-align:center;font-weight:bold;color:black !important;">{{ $t('InquirySetupList.Name') }}</div>
                                    <div style="width:20%;text-align:center;font-weight:bold;color:black !important;">{{ $t('InquirySetupList.Label') }}</div>
                                    <div style="width:30%;text-align:center;font-weight:bold;color:black !important;">{{ $t('InquirySetupList.Description') }}</div>
                                    <div style="width:10%;text-align:center;font-weight:bold;color:black !important;">{{ $t('InquirySetupList.Status') }}</div>
                                </div>
                            </div>

                            <draggable class="row" v-model="resultQuery" style="  margin: unset;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="display: flex; padding: 12px 7px !important; margin: unset; border-bottom: 1px solid #e9f5f9; " v-for="(module,index) in resultQuery" v-bind:key="module.id">
                                    <div style="width:4%; text-align:right;color:black !important; " v-if="currentPage === 1"><span>{{index+1}}</span></div>
                                    <div style="width:4%; text-align:right;color:black !important; " v-else><span>{{((currentPage*10)-10) +(index+1)}}</span></div>
                                    <div style="width: 15%; text-align: center; font-weight: bold; color: black !important;" v-if="isValid('CanEditInquirySetup')"> <a href="javascript:void(0)" v-on:click="Editmodule(module.id)">{{module.code}}</a></div>
                                    <div style="width:15%;text-align:center;color:black !important;" v-else>{{module.code}}</div>
                                    <div style="width:20%;text-align:center;color:black !important;">{{module.name}}</div>
                                    <div style="width:20%;text-align:center;color:black !important;">{{module.label}}</div>
                                    <div style="width:30%;text-align:center;color:black !important;">{{module.description}}</div>
                                    <div style="width:10%;text-align:center;color:black !important;">{{module.isActive==true?$t('InquirySetupList.Active'):$t('InquirySetupList.De-Active')}}</div>
                                    

                                </div>
                            </draggable>

                        </div>
                        
                    </div>
                    

                </div>
            </div>
        </div>
        <add-inquiry-module-model :module="newmodule"
                                  :show="show"
                                  v-if="show"
                                  @close="IsSave"
                                  :type="type" />

    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    import draggable from 'vuedraggable';
    export default {
        mixins: [clickMixin],
        components: {

            draggable
        },
        data: function () {
            return {
                searchQuery: '',
                show: false,
                modulelist: [],
                newmodule: {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    label: '',
                    description: '',
                    code: '',
                    moduleQuestionLookUps: [],
                    isActive: true
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',

               
            }
        },
        watch: {
            search: function (val) {
                this.GetmoduleData(val, 1);
            }
        },
        computed: {
            resultQuery: {
                get() {

                    return this.modulelist
                },

                set(val) {

                    //this.$store.dispatch('GetProcessList', val);
                    this.modulelist = val;
                    var count = 1;
                    this.modulelist.forEach(function (x) {
                        x.rowNumber = count
                        count++
                    });
                    this.UpdateRow()
                }
            }
        },
        methods: {
            IsSave: function () {

                this.show = !this.show;

                this.GetmoduleData(this.search, this.currentPage);
            },
            getPage: function () {
                this.GetmoduleData(this.search, this.currentPage);
            },
            openmodel: function () {
                this.newmodule = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    label: '',
                    code: '',
                    description: '',
                    moduleQuestionLookUps: [],
                    isActive: true

                }
                this.show = !this.show;
                this.type = "Add";
            },
            UpdateRow: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Project/UpdateModuleList', this.modulelist, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess) {
                        console.log("updated")
                    }
                    root.loading = false;
                });
            },
            GetmoduleData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Project/InquiryModuleList?pageNumber=' + this.currentPage + '&searchTerm=' + this.search, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.modulelist = response.data.results.inquiryModuleLookUp;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            Editmodule: function (Id) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Project/InquiryModuleDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {
                            root.newmodule.id = response.data.id;
                            root.newmodule.name = response.data.name;
                            root.newmodule.label = response.data.label;
                            root.newmodule.description = response.data.description;
                            root.newmodule.code = response.data.code;
                            root.newmodule.isActive = response.data.isActive;
                            root.newmodule.moduleQuestionLookUps = response.data.moduleQuestionLookUps;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.GetmoduleData(this.search, 1);

        }
    }
</script>