<template>
    <div class="row" v-if="isValid('CanAddSaleOrder') || isValid('CanDraftSaleOrder') || isValid('CanEditSaleOrder') || isValid('CanDuplicateSaleOrder')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card">
                <div class="card-header">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <span v-if="temporaryCash.id === '00000000-0000-0000-0000-000000000000'">{{ $t('AddTemporaryCashRequest.AddTemporaryCashRequest') }} <span style="font-weight:bold">  - {{temporaryCash.registrationNo}}</span></span>
                            <span v-else>{{ $t('AddTemporaryCashRequest.UpdateTemporaryCashRequest') }} <span style="font-weight:bold">  - {{temporaryCash.registrationNo}}</span></span>

                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">

                            <span>
                                {{temporaryCash.date}}
                            </span>
                        </div>
                    </div>
                    <div class="row bottomBorder">

                    </div>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <label>{{ $t('AddTemporaryCashRequest.Employee') }} :<span class="LabelColour"> *</span></label>
                            <!--<usersDropdown v-model="temporaryCash.userId" v-bind:values="temporaryCash.userId" :alluser="true" />-->
                            <employeeDropdown v-model="temporaryCash.userId" :values="temporaryCash.userId" :temporaryCashRequest="true" />
                        </div> 
                        
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                            <label>{{ $t('AddTemporaryCashRequest.NewEmployee') }} :<span class="LabelColour"> *</span></label>
                            <input class="form-control" v-bind:disabled="temporaryCash.userId!='00000000-0000-0000-0000-000000000000' && temporaryCash.userId!=null" v-model="temporaryCash.newUser" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                        </div>
                    </div>
                    <br />

                    <temporary-cash-request-item @input="SavePurchaseItems"  />
                    
                    
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <label>{{ $t('AddTemporaryCashRequest.TermandCondition') }}:</label>
                            <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                      v-model="temporaryCash.note" />
                        </div>
                    </div>

                    <div class="row">
                        <div v-if="!loading" class="col-md-12 text-right" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div v-if="temporaryCash.id === '00000000-0000-0000-0000-000000000000'">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddTemporaryCashRequest.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Draft')"
                                        v-if="isValid('CanDraftSaleOrder')"
                                        :disabled="$v.$invalid">
                                    <i class="far fa-save"></i>  {{ $t('AddTemporaryCashRequest.SaveAsDraft') }}
                                </button>
                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Approved')"
                                        v-if="isValid('CanAddSaleOrder')"
                                        :disabled="$v.$invalid">
                                    <i class="far fa-save"></i>  {{ $t('AddTemporaryCashRequest.SaveAsPost') }}
                                </button>                                
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddTemporaryCashRequest.Cancel') }}
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddTemporaryCashRequest.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Draft')"
                                        v-if="isValid('CanDraftSaleOrder') && isValid('CanEditSaleOrder')"
                                        :disabled="$v.$invalid">
                                    <i class="far fa-save"></i>  {{ $t('AddTemporaryCashRequest.UpdateAsDraft') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Approved')"
                                        v-if="isValid('CanAddSaleOrder') && isValid('CanEditSaleOrder')"
                                        :disabled="$v.$invalid ">
                                    <i class="far fa-save"></i> {{ $t('AddTemporaryCashRequest.UpdateAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddTemporaryCashRequest.Cancel') }}
                                </button>
                            </div>
                        </div>
                        <div class="card-footer col-md-3" v-else>
                            <loading :active.sync="loading"
                                     :can-cancel="true"
                                     :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
            <bulk-attachment :attachmentList="temporaryCash.attachmentList" :show="show" v-if="show" @close="attachmentSave" />
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required, requiredIf } from "vuelidate/lib/validators";


    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Loading
        },

        data: function () {
            return {
                show: false,
                temporaryCash: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    userId: "00000000-0000-0000-0000-000000000000",
                    newUser: "",
                    registrationNo: "",
                    approvalStatus: "",
                    note: "",
                    isCashRequesterUser: false,
                    temporaryCashItems: [],
                    attachmentList: [],
                },
                loading: false
            };
        },
        validations: {
            temporaryCash: {
                date: { required },
                newUser:
                {
                    required: requiredIf((x) => {
                        if (x.userId == '00000000-0000-0000-0000-000000000000' || x.userId == null || x.userId == undefined)
                            return true;
                        return false;
                    }),
                }
                
            },
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.temporaryCash.attachmentList = attachment;
                this.show = false;
            },

            

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/EmployeeRegistration/AutoTemporaryCashRequestCode", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.temporaryCash.registrationNo = response.data;
                        }
                    });
            },

            SavePurchaseItems: function (saleOrderItems) {
                this.temporaryCash.temporaryCashItems = saleOrderItems;
            },

            savePurchase: function (status) {
                this.temporaryCash.approvalStatus = status;

                this.loading = true;
                var root = this;

                this.$https
                    .post('/EmployeeRegistration/AddTemporaryCashRequest', root.temporaryCash, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
                    .then(response => {
                        if (response.data != null) {
                            root.loading = false
                            root.$swal({
                                title: "Saved!",
                                text: "Data Saved Successfully!",
                                type: 'success',
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,
                            }).then(function (response) {
                                if (response != undefined) {
                                    if (root.temporaryCash.id == "00000000-0000-0000-0000-000000000000") {
                                        root.$router.go('AddTemporaryCashRequest');

                                    } else {
                                        root.$router.push({
                                            path: '/TemporaryCashRequest'                                            
                                        })
                                    }
                                }
                            });

                        }
                        
                    })
                    .catch(error => {
                        console.log(error)                        
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToPurchase: function () {
                this.$router.push({
                    path: '/TemporaryCashRequest',
                })
            }
        },
        created: function () {
            this.$emit('input', this.$route.name);

            
            if (this.$route.query.data != undefined) {   
                var data = this.$route.query.data;
                this.temporaryCash.id = data.id;
                this.temporaryCash.date = moment(data.date).format('llll');
                this.temporaryCash.userId = data.userId;
                this.temporaryCash.newUser = data.newUser;
                this.temporaryCash.registrationNo = data.registrationNo;
                this.temporaryCash.note = data.note;
                this.temporaryCash.isCashRequesterUser = data.isCashRequesterUser;
                this.temporaryCash.temporaryCashItems = data.temporaryCashItems;
                this.temporaryCash.attachmentList = data.attachmentList;
            }
            else {
                this.AutoIncrementCode();
                this.temporaryCash.date = moment().format('llll');
            }
        },
        mounted: function () {

        },
    };
</script>

<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
