<template>
    <div class="row" v-if="isValid('CanAddInquiry') || isValid('CanEditInquiry')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">


                            <div class="row " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="col-lg-8 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <span>{{ $t('AddInquiry.AddInquiry') }} <span style="font-weight:bold"> - {{inquiry.inquiryNumber}}</span></span>
                                    <!--<span v-else>{{ $t('AddPurchaseOrder.UpdatePurchaseOrder') }}  <span style="font-weight:bold"> - {{purchase.registrationNo}}</span></span>-->
                                </div>
                                <div class="col-lg-4 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                    <span>
                                        {{inquiry.dateTime}}
                                    </span>
                                </div>
                            </div>

                            <div class="row bottomBorder">

                            </div>



                            <div class="row">

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.Reference') }}: <span class="LabelColour"> *</span></label>
                                    <input v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" class="form-control" v-model="inquiry.reference" />

                                </div>
                                <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <label>Due Date: <span class="LabelColour"> *</span></label>
            <VueCtkDateTimePicker v-model="inquiry.dueDateTime"></VueCtkDateTimePicker>

        </div>-->
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.Customer') }}: <span class="LabelColour"> *</span></label>
                                    <customerdropdown v-model="inquiry.customerId" v-on:input="CheckCustomerAlreadyInquiry" :values="inquiry.customerId" />

                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.CustomerStatus') }}: <span class="LabelColour"> *</span></label>
                                    <div>
                                        <multiselect :options="customerOptions" disabled="true" v-model="customerValue" :show-labels="false" :placeholder="$t('AddInquiry.SelectType')">

                                        </multiselect>

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.MediaType') }}: <span class="LabelColour"> *</span></label>
                                    <div>
                                        <multiselect :options="options" v-model="inquiry.mediaType" :show-labels="false" :placeholder="$t('AddInquiry.SelectType') " @search-change="addMediaType">
                                            <span slot="noResult" class="btn btn-primary " v-on:click="AddNewMediaType">Create New</span><br />
                                        </multiselect>

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.RequestReceiver') }}: <span class="LabelColour"> *</span></label>
                                    <div>
                                        <usersDropdown v-on:input="AssignToReceiver" :isloginhistory="true" :values="inquiry.receiverId"></usersDropdown>

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.RequestHandler') }}: <span class="LabelColour"> *</span></label>
                                    <div>
                                        <usersDropdown v-on:input="AssignToHandler" :isloginhistory="true" :values="inquiry.handlerId"></usersDropdown>

                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.InquiryType') }}: <span class="LabelColour"> *</span></label>
                                    <div>
                                        <inquiry-type-dropdown v-model="inquiry.inquiryTypeId" :values="inquiry.inquiryTypeId"></inquiry-type-dropdown>

                                    </div>
                                </div>
                                <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <label>Process: <span class="LabelColour"> *</span></label>
            <div>
                <inquiry-process-dropdown v-model="inquiry.inquiryProcessId" :values="inquiry.inquiryProcessId"></inquiry-process-dropdown>

            </div>
        </div>-->
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.Priority') }}: <span class="LabelColour"> *</span></label>
                                    <div>
                                        <multiselect :options="priorityOptions" v-model="inquiry.priority" :show-labels="false" :placeholder="$t('AddInquiry.SelectType') ">

                                        </multiselect>

                                    </div>
                                </div>
                                <!--<div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <label>Module: <span class="LabelColour"> *</span></label>
            <div>
                <inquiry-module-dropdown v-model="inquiry.inquiryModuleId" :values="inquiry.inquiryModuleId"></inquiry-module-dropdown>

            </div>
        </div>-->

                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <label>{{ $t('AddInquiry.GeneralDescription') }}:<span class="LabelColour"> *</span></label>
                                    <textarea class="form-control" v-model="inquiry.description" />

                                </div>


                                <!--v-for="loockup in modulelist[0]" :key="loockup"-->
                                <!--v-if="loockup.moduleQuestionLookUps.length > 0"-->
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2" :key="moduleRander">
                                    <label style="font-weight:bold;font-size:16px;">{{selectedModule.name}}:</label>
                                    <div class="card" style="border: 1px #dddddd solid;border-radius:0px !important" v-if="selectedModule.moduleQuestionLookUps.length > 0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-6 " v-for="(ques,index) in selectedModule.moduleQuestionLookUps" :key="ques">

                                                    <p style="margin:0px !important;padding:0px !important">
                                                        Q{{index+1}}:{{ques.question}}

                                                    </p>
                                                    <input v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" class="form-control" v-model="ques.answer" />
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-6 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                    <button class="btn btn-primary btn-sm mr-2"
                                                            v-on:click="AttachmentModule">
                                                        <span>
                                                            {{ $t('AddInquiry.Attachment') }}
                                                        </span>

                                                    </button>
                                                </div>
                                                <div class="col-6 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                                    <button class="btn btn-primary btn-sm mr-2"
                                                            v-on:click="PreviousModule">
                                                        <span>
                                                            {{ $t('AddInquiry.Previous') }}
                                                        </span>

                                                    </button>
                                                    <button class="btn btn-primary btn-sm mr-2 "
                                                            v-on:click="NextModule">
                                                        <span>
                                                            {{ $t('AddInquiry.Next') }}
                                                        </span>

                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" style="border: 1px #dddddd solid;border-radius:0px !important" v-else>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-12 ">

                                                    <label>{{ $t('AddInquiry.Description') }}:</label>
                                                    <!--<textarea class="form-control" />-->
                                                    <textarea class="form-control" v-model="selectedModule.description" />
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-6 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                                    <button class="btn btn-primary btn-sm mr-2"
                                                            v-on:click="AttachmentModule">
                                                        <span>
                                                            {{ $t('AddInquiry.Attachment') }}
                                                        </span>

                                                    </button>
                                                </div>
                                                <div class="col-6 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                                    <button class="btn btn-primary btn-sm mr-2"
                                                            v-on:click="PreviousModule">
                                                        <span>
                                                            {{ $t('AddInquiry.Previous') }}
                                                        </span>

                                                    </button>
                                                    <button class="btn btn-primary btn-sm mr-2 "
                                                            v-on:click="NextModule">
                                                        <span>
                                                            {{ $t('AddInquiry.Next') }}
                                                        </span>

                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <!--v-for="(ques,index) in loockup" :key="ques"-->




                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-2">
                                    <label>{{ $t('AddInquiry.AddTerms') }} :</label> <toggle-button class="ml-2" color="#3178F6" v-model="inquiry.isTerm" />
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-if="inquiry.isTerm">
                                    <label>{{ $t('AddInquiry.TermAndCondition') }}:</label>
                                    <textarea class="form-control" v-model="inquiry.termAndCondition" />
                                </div>
                                <!--<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label>Line Item:</label>
            <productMultiSelectDropdown v-on:input="pusProductRecord" :isRequest="false" :values="itemList"></productMultiSelectDropdown>
        </div>-->
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddInquiry.UserMessage') }}:</label>
                                    <textarea class="form-control" v-model="inquiry.userMessage" />
                                </div>

                            </div>

                            <button class="btn btn-danger mr-2 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'float-right' : 'float-left'"
                                    v-on:click="CloseInquiry">
                                <span>
                                    {{ $t('AddInquiry.Cancel') }}
                                </span>

                            </button>
                            <button v-if="inquiry.id == '00000000-0000-0000-0000-000000000000' && isValid('CanAddInquiry')" :disabled="$v.$invalid" class="btn btn-primary mr-2"
                                    v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'float-right' : 'float-left'"
                                    v-on:click="SaveData">
                                <span>
                                    {{ $t('AddInquiry.Save') }}
                                </span>

                            </button>
                            <button v-else-if="isValid('CanEditInquiry')" :disabled="$v.$invalid" class="btn btn-primary mr-2 "
                                    v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'float-right' : 'float-left'"
                                    v-on:click="SaveData">
                                <span>
                                    {{ $t('AddInquiry.Update') }}
                                </span>

                            </button>

                            <button class="btn btn-primary mr-2 "
                                    v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'float-left' : 'float-right'"
                                    v-on:click="Attachment">
                                <span>
                                    {{ $t('AddInquiry.Attachment') }}
                                </span>

                            </button>
                            <!--<div class="card-footer col-md-3 text-left" v-else>
        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>-->

                        </div>


                    </div>
                </div>
            </div>


        </div>
        <bulk-attachment :attachmentList="inquiry.attachmentList" :show="show" v-if="show" @close="attachmentSave" />
        <bulk-attachment :attachmentList="selectedModule.attachments" :show="showModuleAttachment" v-if="showModuleAttachment" @close="moduleAttachmentSave" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
   /* import { VueEditor } from "vue2-editor";*/
    import { required } from "vuelidate/lib/validators";
    import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
    import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
    import Vue from 'vue'
    Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
    import Multiselect from 'vue-multiselect'
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            //Loading,
            /*VueEditor*/
        },
        data: function () {
            return {
                inquiry: {
                    id: '00000000-0000-0000-0000-000000000000',
                    inquiryNumber: '',
                    dateTime: '',
                    dueDateTime: '',
                    reference: '',
                    description: '',
                    termAndCondition: '',
                    userMessage: '',
                    isTerm: false,
                    inquiryStatus: 'Pending',
                    customerId: '',
                    receiverId: '',
                    handlerId: '',
                    mediaTypeId: '00000000-0000-0000-0000-000000000000',
                    mediaType: '',
                    inquiryModuleId: '00000000-0000-0000-0000-000000000000',
                    inquiryProcessId: '',
                    inquiryTypeId: '00000000-0000-0000-0000-000000000000',
                    priority: '',
                    inquiryItems: [],
                    attachmentList: [],
                    inquiryModuleLookUp: []
                },
                show: false,
                isUpdate: false,
                showModuleAttachment: false,
                dueDate: '',
                options: [],
                newCC: '',
                itemList: [],
                modulelist: [],
                priorityOptions: ['High', 'Medium', 'Low'],
                customerOptions: ['New', 'Old'],
                customerValue:'',
                selectedModule: {
                    attachments: [],
                    code: '',
                    description: '',
                    id: '',
                    isActive: '',
                    label: '',
                    name: '',
                    rowNumber: '',
                    moduleQuestionLookUps: []
                },
                selectModuleIndex: 0,
                moduleRander: 0
            };
        },
        validations: {
            inquiry: {
                dueDateTime: { required },
                reference: { required },
                description: { required },
                mediaType: { required },
                customerId: { required },
                handlerId: { required },
                receiverId: { required },
                inquiryTypeId: { required },
                priority: { required },
            }
        },
        methods: {
            AttachmentModule: function () {
                this.showModuleAttachment = true;
            },
            moduleAttachmentSave: function (attachment) {
                this.selectedModule.attachments = attachment;
                this.showModuleAttachment = false;
            },
            NextModule: function () {
                if (this.selectModuleIndex < this.inquiry.inquiryModuleLookUp.length-1) {
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].attachments = this.selectedModule.attachments
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].code = this.selectedModule.code
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].description = this.selectedModule.description
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].id = this.selectedModule.id
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].isActive = this.selectedModule.isActive
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].label = this.selectedModule.label
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].name = this.selectedModule.name
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].rowNumber = this.selectedModule.rowNumber
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].moduleQuestionLookUps = this.selectedModule.moduleQuestionLookUps
                    this.selectModuleIndex++;
                    this.selectedModule.attachments = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].attachments
                    this.selectedModule.code = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].code
                    this.selectedModule.description = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].description
                    this.selectedModule.id = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].id
                    this.selectedModule.isActive = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].isActive
                    this.selectedModule.label = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].label
                    this.selectedModule.name = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].name
                    this.selectedModule.rowNumber = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].rowNumber
                    this.selectedModule.moduleQuestionLookUps = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].moduleQuestionLookUps
                    this.moduleRander++
                }
            },
            PreviousModule: function () {
                if (this.selectModuleIndex > 0) {
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].attachments = this.selectedModule.attachments
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].code = this.selectedModule.code
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].description = this.selectedModule.description
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].id = this.selectedModule.id
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].isActive = this.selectedModule.isActive
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].label = this.selectedModule.label
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].name = this.selectedModule.name
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].rowNumber = this.selectedModule.rowNumber
                    this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].moduleQuestionLookUps = this.selectedModule.moduleQuestionLookUps
                    this.selectModuleIndex--;
                    this.selectedModule.attachments = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].attachments
                    this.selectedModule.code = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].code
                    this.selectedModule.description = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].description
                    this.selectedModule.id = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].id
                    this.selectedModule.isActive = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].isActive
                    this.selectedModule.label = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].label
                    this.selectedModule.name = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].name
                    this.selectedModule.rowNumber = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].rowNumber
                    this.selectedModule.moduleQuestionLookUps = this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].moduleQuestionLookUps
                    this.moduleRander++
                }
            },
            CloseInquiry: function () {
                if (this.isValid('CanViewInquiry'))
                    this.$router.push('Inquiry')
                else {
                    this.$router.go('/AddInquiry')
                }
            },
            addMediaType: function (data) {
                if (data != '' && data != null && data != undefined) {
                    this.newMediaType = data
                }
            },
            AddNewMediaType: function () {
                this.options.push(this.newMediaType)
                this.inquiry.mediaType = this.newMediaType
            },
            Attachment: function () {
                this.show = true;
            },
            attachmentSave: function (attachment) {
                this.inquiry.attachmentList = attachment;
                this.show = false;
            },
            AssignToHandler: function (x) {
                this.inquiry.handlerId = x.id
            },
            AssignToReceiver: function (x) {
                this.inquiry.receiverId = x.id
            },
            pusProductRecord: function (x) {
                console.log(x)
                var root = this
                root.inquiry.inquiryItems = []
                x.forEach(function (y) {
                    root.inquiry.inquiryItems.push({
                        inquiryId: '00000000-0000-0000-0000-000000000000',
                        itemId: y.id
                    })
                })

            },
            GetInquiryAutoCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                root.$https.get("/Project/InquiryAutoGenerateNo", { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            if (root.isUpdate == false)
                                root.inquiry.inquiryNumber = response.data.autoNumber
                            response.data.mediaType.forEach(function (x) {
                                root.options.push(x);
                            })

                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Cannot Generate Auto Inoice Number!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                    });
            },

            SaveData: function () {

                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].attachments = this.selectedModule.attachments
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].code = this.selectedModule.code
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].description = this.selectedModule.description
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].id = this.selectedModule.id
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].isActive = this.selectedModule.isActive
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].label = this.selectedModule.label
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].name = this.selectedModule.name
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].rowNumber = this.selectedModule.rowNumber
                this.inquiry.inquiryModuleLookUp[this.selectModuleIndex].moduleQuestionLookUps = this.selectedModule.moduleQuestionLookUps
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                console.log(this.inquiry)
                root.$https.post("/Project/SaveInquiry", this.inquiry, { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            root.$swal({
                                title: root.$t('SavedSuccessfully'),
                                text: response.data.message,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function () {
                                if (response.data.isAdd) {
                                    root.$router.go({
                                        path: '/AddInquiry'
                                    });

                                }
                                else {
                                    root.$router.push('/Inquiry');
                                }
                            });

                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Cannot Generate Auto Inoice Number!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                    });
            },
            GetmoduleData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Project/InquiryModuleList?isActive=' + true, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.inquiry.inquiryModuleLookUp = response.data.results.inquiryModuleLookUp;
                        root.selectedModule.attachments = response.data.results.inquiryModuleLookUp[0].attachments
                        root.selectedModule.code = response.data.results.inquiryModuleLookUp[0].code
                        root.selectedModule.description = response.data.results.inquiryModuleLookUp[0].description
                        root.selectedModule.id = response.data.results.inquiryModuleLookUp[0].id
                        root.selectedModule.isActive = response.data.results.inquiryModuleLookUp[0].isActive
                        root.selectedModule.label = response.data.results.inquiryModuleLookUp[0].label
                        root.selectedModule.name = response.data.results.inquiryModuleLookUp[0].name
                        root.selectedModule.rowNumber = response.data.results.inquiryModuleLookUp[0].rowNumber
                        root.selectedModule.moduleQuestionLookUps = response.data.results.inquiryModuleLookUp[0].moduleQuestionLookUps
                        console.log(root.selectedModule)
                    }
                    root.loading = false;
                });
            },
            CheckCustomerAlreadyInquiry: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Project/CheckCustomerAlreadyInquiry?id=' + this.inquiry.customerId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data) {
                        root.customerValue = "Old"
                        console.log(root.selectedModule)
                    }
                    else {
                        root.customerValue = "New"
                    }
                    root.loading = false;
                });
            },
        },
        created: function () {
            if (this.$route.query.data != undefined) {
                this.inquiry = this.$route.query.data
                this.itemList = this.$route.query.data.inquiryItems
                this.inquiry.dueDateTime = moment(this.inquiry.dueDateTime)
                this.inquiry.dateTime = moment(this.inquiry.dateTime).format('lll')

                this.selectedModule.attachments = this.$route.query.data.inquiryModuleLookUp[0].attachments
                this.selectedModule.code = this.$route.query.data.inquiryModuleLookUp[0].code
                this.selectedModule.description = this.$route.query.data.inquiryModuleLookUp[0].description
                this.selectedModule.id = this.$route.query.data.inquiryModuleLookUp[0].id
                this.selectedModule.isActive = this.$route.query.data.inquiryModuleLookUp[0].isActive
                this.selectedModule.label = this.$route.query.data.inquiryModuleLookUp[0].label
                this.selectedModule.name = this.$route.query.data.inquiryModuleLookUp[0].name
                this.selectedModule.rowNumber = this.$route.query.data.inquiryModuleLookUp[0].rowNumber
                this.selectedModule.moduleQuestionLookUps = this.$route.query.data.inquiryModuleLookUp[0].moduleQuestionLookUps
                this.isUpdate = true
                this.customerValue = "Old"
                this.GetInquiryAutoCode();
            }
            else {
                this.GetInquiryAutoCode();
                this.inquiry.dueDateTime = moment()
                this.inquiry.dateTime = moment().format('lll')
                this.GetmoduleData();
            }

        },
        mounted: function () {
            //this.GenerateToken('Inquiry Management');
        },
    };
</script>
<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
