<template>
    <div>
        <div ref="mychildcomponent" hidden  id='purchaseInvoice' class="col-md-12">
            <!--page1-->
            <div>
                <!--HEADER-->
                <div class="col-md-12">
                    <table class="table table-borderless">
                        <tr>
                            <td style="width:36%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                                <p class="mb-0">
                                    <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                    <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                    <span style="font-size:13px;color:black !important;font-weight:bold;">
                                        Tel: {{headerFooters.company.phoneNo}}
                                    </span>
                                </p>
                            </td>
                            <td style="width:26%;" class="text-center pt-0 pb-0 pl-0 pr-0">
                                <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                            </td>
                            <td style="width:38%;" class="pt-0 pb-0 pl-0 pr-0">
                                <p class="text-right mb-0" v-if="arabic=='true'">
                                    <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                    <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                    <span style="font-size:14px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                    <span style="font-size:13px;color:black !important;font-weight:bold;">
                                        هاتف: {{headerFooters.company.phoneNo}}:
                                    </span>
                                </p>
                            </td>
                        </tr>

                        <tr>

                            <td style="width:100%;" class="pt-0 pb-0 pl-0 pr-0" colspan="3">
                                <div style="text-align: center;">
                                    <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{ $t('VatPayableReport.VatPayableReport') }}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>            <!--INFORMATION-->
                <!--<div style="height:60mm;" v-else></div>-->
                <div style="height:15mm;margin-top:1mm; border:2px solid #000000;">
                    <div class="row">
                        <div class="col-md-12 ml-2 mr-2">
                            <table class="table table-borderless">
                                <!--Row 1-->
                                <tr>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">From Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{list.fromDate}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:من التاريخ</td>

                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">To Date:</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{list.toDate}}</td>
                                    <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:حتى الآن</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>


                <!--INFORMATION-->
                <div style="height:200mm;border:2px solid !important;">
                    <div class="row ">
                        <div class="col-lg-12 ">
                            <table class="table">

                                <tr><th colspan="4" style="padding-left:30px;padding-top:10px;font-size:20px;border:1px solid !important;color:black !important">VAT Paid </th></tr>
                                <tr>
                                    <th style="width:15%;border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">#</th>
                                    <th style="border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">{{ $t('VatReportPrint.Code') }}</th>
                                    <th style="border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">{{ $t('VatReportPrint.Name') }}</th>
                                    <th style="border-top:1px solid !important;color:black !important;border-bottom:1px solid !important;color:black !important">{{$t('VatReportPrint.Amount')}}</th>
                                </tr>
                                <tr v-for="(account,index) in list.paidaccounts" v-bind:key="account.code">
                                    <td style="color:black !important;">{{index+1}}</td>
                                    <td style="color:black !important;">{{account.code}}</td>
                                    <td style="color:black !important;">
                                        <span>{{account.name}}</span>
                                    </td>
                                    <td style="color:black !important;">{{Number(Math.abs(parseFloat(account.amount).toFixed(2))).toLocaleString()}}</td>

                                </tr>
                                <tr style="border:1px solid !important;color:black !important">
                                    <td style="color:black !important;"></td>
                                    <td style="color:black !important;"></td>
                                    <td style="color:black !important;"><b>{{ $t('VatReportPrint.Total') }}</b></td>
                                    <td style="color:black !important;"><b>{{(parseFloat(list.totalvatpaid)).toFixed(2)}}</b></td>
                                </tr>
                                <tr>
                                    <th colspan="4" style="padding-left:30px;padding-top:10px;font-size:20px;border:1px solid !important;color:black !important">VAT Payable </th>
                                </tr>
                                <tr v-for="(account,index) in list.payableaccounts" v-bind:key="account.code">
                                    <td style="color:black !important;">{{index+1}}</td>
                                    <td style="color:black !important;">{{account.code}}</td>
                                    <td style="color:black !important;"> 
                                        <span>{{account.name}}</span>
                                    </td>
                                    <td style="color:black !important;">{{Number(Math.abs(parseFloat(account.amount).toFixed(2))).toLocaleString()}}</td>

                                </tr>

                                <tr style="border:1px solid !important;color:black !important">
                                    <td style="color:black !important;"></td>
                                    <td style="color:black !important;"></td>
                                    <td style="color:black !important;"><b>{{ $t('VatReportPrint.Total') }}</b></td>
                                    <td style="color:black !important;"><b>{{(parseFloat(list.totalvatpayable)).toFixed(2)}}</b></td>

                                </tr>

                                <tr style="border:none !important;border-color:transparent !important">
                                    <td style="padding-top:40px"></td>
                                    <td style="color:black !important;"></td>
                                    <td style="color:black !important;"><b>{{ $t('VatReportPrint.VATPayable')}}</b></td>
                                    <td style="color:black !important;"><b>{{(parseFloat(list.VatPayable)).toFixed(2)}}</b></td>
                                </tr>


                            </table>

                        </div>
                    </div>
                </div>

                <div class="col-md-12  " style="height:15mm;" v-if="isHeaderFooter=='true'">
                    <div class="row">
                        <table class="table text-center">
                            <tr>
                                <td style="color: black !important;font-size:15px; font-weight:bold;border-top:0 !important;">
                                    {{ $t('SaleInvoice.ReceivedBy') }}::
                                </td>
                                <td style="color: black !important;font-size:15px; font-weight:bold;border-top:0 !important;">
                                    {{ $t('SaleInvoice.ApprovedBy') }}::
                                </td>
                                <td style="color: black !important;font-size:15px; font-weight:bold;border-top:0 !important;">
                                    {{ $t('SaleInvoice.PreparedBy') }}::
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
                <!--Footer-->
                <div class="col-md-12" style="border:2px solid #000000; height:34mm;">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-borderless">
                                <tr>
                                    <td class="p-0" style="width:25%;">
                                        <u><span style="font-size:15px;color: black !important;font-weight:bold;">Terms & Conditions</span></u><br />
                                        <span style="font-size:12px;color: black !important;" v-html="headerFooters.footerEn"></span>
                                    </td>
                                    <td class="pl-0 pr-0 pb-0 text-center" style="width:20%;">
                                        <vue-qrcode v-bind:value="qrValue" style="width:90px;" />
                                    </td>

                                    <td class="pl-0 pb-0 pt-4 text-center" style="width:30%;">
                                        <table class="table">
                                            <tr>
                                                <td class="p-0 text-center" style="border:1px solid #000000;" colspan="2">Bank Details &nbsp; تفاصيل البنك</td>
                                            </tr>
                                            <tr>
                                                <td class="p-0 text-center" style="border:1px solid #000000;">{{headerFooters.bankAccount1}}</td>
                                                <td class="p-0 text-center" style="border:1px solid #000000;">{{headerFooters.bankIcon1}}</td>
                                            </tr>
                                            <tr>
                                                <td class="p-0 text-center" style="border:1px solid #000000;">{{headerFooters.bankAccount2}}</td>
                                                <td class="p-0 text-center" style="border:1px solid #000000;">{{headerFooters.bankIcon2}}</td>
                                            </tr>
                                        </table>
                                    </td>

                                    <td class="pr-0 pb-0 pt-0 text-left" style="width:25%;">
                                        <u><span style="font-size:15px;font-weight:bold;">البنود و الظروف</span></u><br />
                                        <span class=" text-center" style="font-size:12px;color: black !important;" v-html="headerFooters.footerAr">

                                        </span>
                                    </td>
                                </tr>

                            </table>
                        </div>

                    </div>
                </div>
                <div class="row" v-if="isHeaderFooter=='true'">
                    <div class="col-md-12">
                        <table class="table table-borderless">
                            <tr>
                                <td class="p-0 font-weight-bold" style="width:50%;font-size:12px;">
                                    {{headerFooters.company.addressEnglish}}
                                </td>
                                <td class="p-0 text-right  font-weight-bold" style="width:50%;font-size:12px;">
                                    {{headerFooters.company.addressArabic}}
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";

    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'headerFooter'],
       
        data: function () {
            return {
              
              
                isHeaderFooter: '',
                invoicePrint: '',
                arabic: '',
                english: '',
                list: {
                    fromDate: '',
                    toDate: '',
                    paidaccounts: [],
                    payableaccounts: [],
                    totalvatpaid: 0,
                    totalvatpayable:0,
                    VatPayable:0,
                },
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            
        },
        methods: {
           
            printInvoice: function () {
                
                var form = new FormData();
                form.append('htmlString', this.$refs.mychildcomponent.innerHTML);
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var date = moment().format('DD MMM YYYY');


                        link.setAttribute('download', 'Vat Report ' + date + '.pdf');
                        document.body.appendChild(link);
                        link.click();

                    });
            }




        },
        mounted: function () {
            
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            if (this.printDetails != null && this.printDetails != undefined) {
                this.list.fromDate = this.printDetails[0].fromDate;
                this.list.toDate = this.printDetails[0].toDate;
                this.list.paidaccounts = this.printDetails[0].paidaccounts;
                this.list.payableaccounts = this.printDetails[0].payableaccounts;
                this.list.totalvatpaid = this.printDetails[0].totalvatpaid;
                this.list.totalvatpayable = this.printDetails[0].totalvatpayable;
                this.list.VatPayable = this.printDetails[0].VatPayable;
            }
           
                this.headerFooters = this.headerFooter;
                

                setTimeout(function () {
                    root.printInvoice();
                }, 125)
        },

    }
</script>

