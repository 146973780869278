<template>
    <div v-if="isValid('CanViewPurchaseDraft') || isValid('CanAddPurchaseInvoice') || isValid('CanEditPurchaseInvoice')" v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="row">
            <div class="col-lg-12 top_auto_no" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'top_auto_no_ar'">
                <span>({{autoNumber}})</span> {{purchase.date}}
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-8">
                <h5 class="banner_btm_pd" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'banner_btm_pd_ar'" v-if="purchase.id == '00000000-0000-0000-0000-000000000000'">
                    {{ $t('AddPurchase.AddPurchaseInvoice') }}
                    <span>{{purchase.registrationNo}}</span>
                </h5>
                <h5 class="banner_btm_pd" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'banner_btm_pd_ar'" v-else>
                    <span>{{ $t('AddPurchase.UpdatePurchaseInvoice') }} {{purchase.registrationNo}}</span>
                </h5>
                <div class="card invoice_top_bg">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-7" style="margin-bottom: auto; margin-top: auto;">
                                <h5 class="text-white title_heading" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-align: right !important;'">{{ $t('AddPurchase.AddPurchaseInvoice') }}</h5>
                                <p class="text-white txt_description" v-bind:style="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-align: right !important;'">{{ $t('AddPurchase.purchaseDescription') }}</p>
                            </div>
                            <div class="col-lg-5 text-right">
                                <img class="inv_main_img" src="/images/make-invoice1.png" alt="Alternate Text" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="card payment_card">
                    <div class="card-body">
                        <div class="row pd_payment_methd">
                            <div class="col-lg-12 text-center">
                                <img class="payment_card_img" src="/images/walletpayment.png" alt="Alternate Text" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mt-2">
                                <p class="payment_method" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-right'" style="margin-bottom: 0 !important;">{{ $t('AddPurchase.PaymentMethod') }}</p>
                                <button v-if="isValid('CashPurchase')" v-on:click="SelectPaymentMethod(false)" type="button" v-bind:class="{ bt_bg_color: !purchase.isCredit }" class="btn btn-outline-primary btn-block btn-sm payment_method_btn">{{ $t('AddPurchase.Cash') }} <img src="/images/Left_arrow2.png" v-bind:class="{ payment_method_btn_img: !purchase.isCredit }" alt="Alternate Text" /> </button>
                                <p v-else>Upgrade your package to unlock cash payment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="card payment_card">
                    <div class="card-body">
                        <div class="row pd_payment_methd">
                            <div class="col-lg-12 text-center">
                                <img class="payment_card_img" src="/images/cardpayment.png" alt="Alternate Text" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 mt-2">
                                <p class="payment_method" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'text-right'" style="margin-bottom: 0 !important;">{{ $t('AddPurchase.PaymentMethod') }}</p>
                                <button v-if="isValid('CreditPurchase')" v-on:click="SelectPaymentMethod(true)" type="button" v-bind:class="{ bt_bg_color: purchase.isCredit }" class="btn btn-outline-primary btn-block btn-sm payment_method_btn">{{ $t('AddPurchase.Credit') }} <img src="/images/Left_arrow2.png" v-bind:class="{ payment_method_btn_img: purchase.isCredit }" alt="Alternate Text" /> </button>
                                <p v-else>Upgrade your package to unlock credit payment</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.Supplier') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger': $v.purchase.supplierId.$error}" :key="supplierRender">
                                    <supplierdropdown v-model="$v.purchase.supplierId.$model" :disable="(purchase.purchaseOrderId!=null && purchase.purchaseOrderId!='') || (purchase.goodReceiveNoteId!=null && purchase.goodReceiveNoteId!='') " :paymentTerm="purchase.isCredit" v-bind:values="purchase.supplierId" :status="purchase.isRaw" :key="rander" />
                                    <span v-if="$v.purchase.supplierId.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 form-group " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'pt-2'">{{ $t('AddPurchase.SupplierInvoiceNumber') }}  :</label>
                                <div v-bind:class="{'has-danger': $v.purchase.invoiceNo.$error}">
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'"
                                           v-model="$v.purchase.invoiceNo.$model" />
                                    <span v-if="$v.purchase.invoiceNo.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.SupplierInvoiceDate') }}  :</label>
                                <div>
                                    <datepicker @pick="purchase.invoiceDate = $event" v-bind:key="rander" />
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.WareHouse') }} :<span class="LabelColour"> *</span></label>
                                <div>
                                    <warehouse-dropdown v-model="$v.purchase.wareHouseId.$model" />
                                    <span v-if="$v.purchase.wareHouseId.$error" class="error text-danger"> </span>
                                </div>
                            </div>
                            <div v-if="isValid('CreditPurchase') && (isValid('CanViewPostOrder')|| isValid('CanAddPurchaseOrder'))" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.PurchaseOrder') }}  :</label>
                                <purchase-order-dropdown v-on:input="GetPoData(purchase.purchaseOrderId,false)" v-model="purchase.purchaseOrderId" v-bind:values="purchase.purchaseOrderId" />
                            </div>
                            <div v-if="isValid('CanAddGoodsReceiveasPost') || isValid('CanViewGoodsReceiveasPost')" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label> {{ $t('GoodReceive.GoodReceive') }}  :</label>
                                <GoodReceiveDropdown v-on:input="GetGRNData(purchase.goodReceiveNoteId,false)" v-model="purchase.goodReceiveNoteId" v-bind:values="purchase.goodReceiveNoteId" />
                            </div>
                            <div v-if="defaultVat=='DefaultVatHead' || defaultVat=='DefaultVatHeadItem'" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.TaxMethod') }} :<span class="LabelColour"> *</span></label>
                                <multiselect :options="options" v-bind:disabled="purchase.purchaseItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </div>
                            <div v-if="defaultVat=='DefaultVatHead' || defaultVat=='DefaultVatHeadItem'" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.VAT%') }} :<span class="LabelColour"> *</span></label>
                                <taxratedropdown v-model="purchase.taxRateId" v-bind:value="purchase.taxRateId" :isDisable="purchase.purchaseItems.length>0? true :false" v-bind:key="rander" />
                            </div>

                            <div v-if="!purchase.isCredit" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.PaymentType') }} :<span class="LabelColour"> *</span></label>
                                <multiselect :options="paymentTypeOptions" @select="GetAccount()" v-model="purchase.paymentType" :show-labels="false" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </div>

                            <div v-if="!purchase.isCredit" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" ($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label v-if="purchase.paymentType=='Cash'">{{ $t('AddPurchase.CashAccount') }}:<span class="LabelColour"> *</span></label>
                                <label v-if="purchase.paymentType!='Cash'">{{ $t('AddPurchase.BankAccount') }}:<span class="LabelColour"> *</span></label>
                                <accountdropdown v-model="purchase.bankCashAccountId" :formName="purchase.paymentType=='Cash'? 'CashPay' : 'BankPay'" :advance="'true'" :key="accountRender" />
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="raw=='true'">
                                <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <label>{{ $t('AddPurchase.RawProduct') }}  : </label> <br />
                                    <toggle-button v-model="purchase.isRaw" @change="ChangeSupplier" class="ml-2 mt-2" color="#3178F6" />
                                </div>
                            </div>

                        </div>
                        <br />
                        <purchase-item @input="SavePurchaseItems" ref="childComponentRef" :purchaseItems="purchase.purchaseItems" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :purchaseOrderId="purchase.purchaseOrderId" :key="randerLineItem" />
                        
                        <div class="row">
                            <div v-if="!loading && purchase.id=='00000000-0000-0000-0000-000000000000'" class="col-md-12 arabicLanguage">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddPurchase.Attachment') }}
                                </button>
                                
                                <button class="btn btn-primary mr-2"
                                        v-on:click="savePurchasePost(false)"
                                        v-if="isValid('CanViewPurchaseDraft')"
                                        v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice=='').length > 0">
                                    <i class="far fa-save"></i>  {{ $t('AddPurchase.SaveAsDraft') }}
                                </button>
                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchasePost(true)"
                                        v-if="isValid('CanAddPurchaseInvoice')"
                                        v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice=='').length > 0 || ( isFifo? (purchase.purchaseItems.filter(x => x.expiryDate=='').length > 0 || purchase.purchaseItems.filter(x => x.batchNo=='').length > 0):false)">
                                    <i class="far fa-save"></i> {{ $t('AddPurchase.SaveAndPost') }}
                                </button>
                                <button class="btn btn-outline-danger mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddPurchase.Cancel') }}
                                </button>
                            </div>
                            <div v-if="!loading && purchase.id!='00000000-0000-0000-0000-000000000000'" class="col-md-12 arabicLanguage">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddPurchase.Attachment') }}
                                </button>

                                <button class="btn btn-primary mr-2"
                                        v-on:click="savePurchasePost(false)"
                                        v-if="isValid('CanViewPurchaseDraft') && isValid('CanEditPurchaseInvoice')"
                                        v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice=='').length > 0">
                                    <i class="far fa-save"></i>  {{ $t('AddPurchase.UpdateAsDraft') }}
                                </button>
                                <button class="btn btn-primary mr-2"
                                        v-on:click="savePurchasePost(true)"
                                        v-if="isValid('CanAddPurchaseInvoice') || isValid('CanEditPurchaseInvoice')"
                                        v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece=='').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice=='').length > 0 || ( isFifo? (purchase.purchaseItems.filter(x => x.expiryDate=='').length > 0 || purchase.purchaseItems.filter(x => x.batchNo=='').length > 0):false)">
                                    <i class="far fa-save"></i> {{ $t('AddPurchase.UpdateAsPost') }}
                                </button>
                                <button class="btn btn-outline-danger mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddPurchase.Cancel') }}
                                </button>
                            </div>
                        </div>
                        

                        <div class="accordion" role="tablist" v-if="internationalPurchase=='true' && purchase.purchaseOrderId!=null && purchase.purchaseOrderId!=''">
                            <b-card no-body class="mb-1" v-if="isValid('CanViewPIAttachment')">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-1 variant="primary">{{ $t('AddPurchase.Attachment') }}</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <import-attachment :purchase="purchase" :show="attachment" v-if="attachment" @close="attachmentSave" :document="'Purchase'" />
                                        <div>
                                            <div class="row" v-if="purchase.id!='00000000-0000-0000-0000-000000000000'">
                                                <div class="col-md-12 text-right">
                                                    <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="attachment=true"> Upload</a>
                                                </div>
                                            </div>
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="m-0">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{{ $t('AddPurchase.Date') }} </th>
                                                            <th v-if="isValid('CanDownloadPIAttachment')">{{ $t('AddPurchase.Attachment') }}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(contact,index) in purchase.purchaseAttachments" v-bind:key="index">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th>{{getDate(contact.date)}}</th>
                                                            <td v-if="isValid('CanDownloadPIAttachment')">
                                                                <button class="btn btn-primary  btn-icon mr-2"
                                                                        v-if="contact.path != ''"
                                                                        v-on:click="DownloadAttachment(contact.path)">
                                                                    <i class="fa fa-download"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1" v-if="isValid('CanViewPIActions')">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-2 variant="primary">Actions</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <add-company-action :action="action" :show="show" v-if="show" @close="IsSave" :document="'Purchase'" />

                                        <div class="row">
                                            <div class="col-md-12" v-if="purchase.id!='00000000-0000-0000-0000-000000000000'">
                                                <div class="col-sm-6 float-right">
                                                    <a href="javascript:void(0)" class="btn btn-outline-primary  float-right" v-on:click="show=true"> Action</a>
                                                </div>
                                            </div>
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="m-0">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Status</th>
                                                            <th>{{ $t('AddPurchase.Date') }} </th>
                                                            <th>Description/Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(process,index) in purchase.actionProcess" v-bind:key="process.id">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th><span class="badge badge-primary">{{process.processName}}</span></th>
                                                            <th>{{getDate(process.date)}}</th>
                                                            <th>{{process.description}}</th>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1" v-if="isValid('CanViewPIPayments')">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-3 variant="primary">Payment</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div>
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="m-0">
                                                        <tr>
                                                            <th>#</th>
                                                            <th style="width:20%;">{{ $t('AddPurchase.Date') }} </th>
                                                            <th style="width:20%;">{{ $t('AddPurchase.VoucherNumber') }} </th>
                                                            <th class="text-right">{{ $t('AddPurchase.Amount') }} </th>
                                                            <th class="text-center">{{ $t('AddPurchase.PaymentMode') }} </th>
                                                            <th>{{ $t('AddPurchase.Description') }} </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(payment,index) in purchase.paymentVoucher" v-bind:key="index">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th>{{getDate(payment.date)}}</th>
                                                            <th>{{payment.voucherNumber}}</th>
                                                            <th class="text-right">{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</th>
                                                            <th class="text-center"><span v-if="payment.paymentMode==0">Cash</span><span v-if="payment.paymentMode==1">Bank</span></th>
                                                            <th>{{payment.narration}}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1" v-if="isValid('CanViewPIExpenses')">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.accordion-4 variant="primary">Expense</b-button>
                                </b-card-header>
                                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div>
                                            <div class=" table-responsive">
                                                <table class="table ">
                                                    <thead class="m-0">
                                                        <tr>
                                                            <th>#</th>
                                                            <th style="width:20%;">{{ $t('AddPurchase.Date') }} </th>
                                                            <th style="width:20%;">{{ $t('AddPurchase.VoucherNumber') }} </th>
                                                            <th class="text-center">{{ $t('AddPurchase.Amount') }} </th>
                                                            <th class="text-center">{{ $t('AddPurchase.PaymentMode') }} </th>
                                                            <th>{{ $t('AddPurchase.Description') }} </th>
                                                            <th> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(payment,index) in purchase.purchasePostExpense" v-bind:key="index">
                                                            <td>
                                                                {{index+1}}
                                                            </td>
                                                            <th>{{getDate(payment.date)}}</th>
                                                            <th>{{payment.voucherNumber}}</th>
                                                            <th>
                                                                <decimal-to-fixed v-model="payment.amount" />

                                                                <!--{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}-->
                                                            </th>
                                                            <th class="text-center">
                                                                <span v-if="payment.paymentMode==0">
                                                                    
                                                                    Cash
                                                                </span><span v-if="payment.paymentMode==1">
                                                                    
                                                                    Bank
                                                                </span>
                                                            </th>
                                                            
                                                            <th>{{payment.narration}}</th>
                                                            <th>
                                                                <a href="javascript:void(0)" title="Remove" class="btn  btn-icon btn-danger btn-sm" v-on:click="removeExpense(payment.id)" v-if="isValid('CanDeletePIExpenses')"><i class="fa fa-times"></i></a>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>

                        <div class="card-footer col-md-3 text-left" v-if="loading">
                            <loading :active.sync="loading"
                                     :can-cancel="true"
                                     :is-full-page="true"></loading>
                        </div>



                    </div>
                </div>

            </div>
        </div>
        <bulk-attachment :attachmentList="purchase.attachmentList" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSave" />
        <purchaseInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender"></purchaseInvoice>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from "vue-loading-overlay";
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required, requiredIf } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        name: "AddPurchase",
        components: {
            Loading,
            Multiselect
        },
        data: function () {
            return {
                defaultVat: '',
                accountRender: 0,
                isFifo: false,
                purchaseOrder: false,
                internationalPurchase: '',
                currency: '',
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    purchaseOrderId: "",
                    goodReceiveNoteId: "",
                    bankCashAccountId: "",
                    paymentType: "Cash",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    isCredit: false,
                    isPurchaseReturn: false,
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    purchaseItems: [],
                    attachmentList: [],
                    isRaw: false,
                    isPurchasePost: false,
                    taxMethod: '',
                    taxRateId: "00000000-0000-0000-0000-000000000000",
                    actionProcess: [],
                    purchaseAttachments: [],
                    paymentVoucher: [],
                    purchasePostExpense: [],
                    partiallyPurchase: false,
                    autoPurchaseVoucher: false,
                    expenseToGst: false,
                    internationalPurchase: false,
                    colorVariants: false,
                    dueAmount: 0,
                },

                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                options: [],
                paymentTypeOptions: [],
                loading: false,
                rander: 0,
                raw: '',
                printRender: 0,
                randerLineItem: 0,
                autoNumber: '',
                language: 'Nothing',
                supplierRender: 0,
                wareRander: 0,
                show: false,
                isAttachshow: false,
                attachment: false,
                action: {
                    id: '00000000-0000-0000-0000-000000000000',
                    purchaseOrderId: '',
                    processId: '',
                    date: '',
                    description: '',
                },
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
                purchaseItems: { required },
                bankCashAccountId: {
                    required: requiredIf((x) => {
                        if (x.isCredit)
                            return false;
                        return true;
                    })
                },
                paymentType: {
                    required: requiredIf((x) => {
                        if (x.isCredit)
                            return false;
                        return true;
                    })
                },
            },
        },
        methods: {
            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSave: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.isAttachshow = false;
            },

            GetAccount: function () {
                this.accountRender++;
            },
            SelectPaymentMethod: function (value) {
                this.purchase.isCredit = value;
                this.rander++;
            },
            removeExpense: function (id) {
                this.purchase.purchasePostExpense = this.purchase.purchasePostExpense.filter((prod) => {
                    return prod.id != id;
                });
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            ChangeSupplier: function () {
                this.supplierRender++;
                this.randerLineItem++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetPoData: function (id, isEdit) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                console.log(isEdit);
                var multi = localStorage.getItem('IsMultiUnit') == 'true' ? true : false
                root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isMultiUnit=' + multi, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            
                            if (root.internationalPurchase == 'true') {
                                root.purchase.actionProcess = response.data.actionProcess;
                                root.purchase.purchaseAttachments = response.data.purchaseAttachments;
                                root.purchase.paymentVoucher = response.data.paymentVoucher;
                                response.data.purchaseOrderExpenses.forEach(function (item) {
                                    if (item.amount - item.usedAmount > 0) {
                                        root.purchase.purchasePostExpense.push({
                                            id: item.id,
                                            date: item.date,
                                            bankCashAccountId: item.bankCashAccountId,
                                            contactAccountId: item.contactAccountId,
                                            vatAccountId: item.vatAccountId,
                                            taxRateId: item.taxRateId,
                                            taxMethod: item.taxMethod,
                                            voucherNumber: item.voucherNumber,
                                            narration: item.narration,
                                            chequeNumber: item.chequeNumber,
                                            amount: item.amount - item.usedAmount,
                                            paymentMode: item.paymentMode,
                                            paymentMethod: item.paymentMethod,
                                        });
                                    }
                                });
                            }

                            if (!isEdit) {
                                root.purchase.purchaseItems = [];
                                root.purchase.purchaseOrderId = response.data.id;
                                root.purchase.supplierId = response.data.supplierId;
                                root.purchase.taxMethod = response.data.taxMethod;
                                root.purchase.taxRateId = response.data.taxRateId;
                                root.purchase.isCredit = true;

                                response.data.purchaseOrderItems.forEach(function (item) {
                                    if (item.remainingQuantity > 0) {
                                        root.purchase.purchaseItems.push({
                                            rowId: item.id,
                                            id: item.id,
                                            description: item.description,
                                            isService: item.isService,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.productId == null ? false: item.product.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            basicUnit: item.unit == null ? '' : item.unit.name,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.quantity,
                                            highQty: item.highQty,
                                            unitPerPack: item.unitPerPack,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            serial: item.productId == null ? false :item.product.serial,
                                            serialNo: item.serialNo,
                                            guarantee: item.productId == null ? false :item.product.guarantee,
                                            guaranteeDate: item.guaranteeDate,
                                            unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        });
                                    }
                                });
                                root.rander++;
                                root.randerLineItem++;
                            }

                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },
            GetGRNData: function (id, isEdit) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                console.log(isEdit);
                var multi = localStorage.getItem('IsMultiUnit') == 'true' ? true : false
                root.$https.get('/Purchase/GoodReceiveDetail?Id=' + id + '&isMultiUnit=' + multi, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                           

                            if (!isEdit) {
                                root.purchase.purchaseItems = [];
                                //root.purchase.purchaseOrderId = response.data.id;
                                root.purchase.supplierId = response.data.supplierId;
                                root.purchase.taxMethod = response.data.taxMethod;
                                root.purchase.taxRateId = response.data.taxRateId;
                                root.purchase.isCredit = true;

                                response.data.goodReceiveNoteItems.forEach(function (item) {
                                    {
                                        root.purchase.purchaseItems.push({
                                            rowId: item.id,
                                            id: item.id,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.product.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            basicUnit: item.unit == null ? '' : item.unit.name,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.remainingQuantity,
                                            highQty: item.highQty,
                                            unitPerPack: item.unitPerPack,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            serial: item.product.serial,
                                            serialNo: item.serialNo,
                                            guarantee: item.product.guarantee,
                                            guaranteeDate: item.guaranteeDate,
                                            unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        });
                                    }
                                });
                                root.rander++;
                                root.randerLineItem++;
                            }

                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },
            AutoIncrementCode: function () {
                //eslint-disable-line
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                root.$https
                    .get("/Purchase/PurchaseAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            if (root.purchase.id == '00000000-0000-0000-0000-000000000000') {
                                root.purchase.registrationNo = response.data.post;
                                root.autoNumber = response.data.draft;
                            }
                            else {
                                root.autoNumber = response.data.post;
                            }
                            //    root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseItems) {
                this.purchase.purchaseItems = purchaseItems;
                this.getTotalAmount();
            },
            getTotalAmount: function () {
                this.purchase.dueAmount = this.$refs.childComponentRef.getTotalAmount();
            },
            savePurchasePost: function (invoiceType) {
                
                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                if (this.purchase.invoiceDate == "Invalid date") {
                    this.purchase.invoiceDate = "";
                }
                if (invoiceType == false) {
                    localStorage.setItem('active', 'Draft');

                }
                if (invoiceType == true) {
                    localStorage.setItem('active', 'post');

                }
                if (this.purchase.taxMethod == "غير شامل") {
                    this.purchase.taxMethod = "Exclusive";
                }
                if (this.purchase.taxMethod == "شامل") {
                    this.purchase.taxMethod = "Inclusive";
                }
                var purchasePost = {
                    id: this.purchase.id,
                    date: this.purchase.date,
                    registrationNo: this.purchase.registrationNo,
                    supplierId: this.purchase.supplierId,
                    invoiceNo: this.purchase.invoiceNo,
                    invoiceDate: this.purchase.invoiceDate,
                    purchaseOrder: this.purchase.purchaseOrder,
                    wareHouseId: this.purchase.wareHouseId,
                    purchaseOrderId: this.purchase.purchaseOrderId,
                    goodReceiveNoteId: this.purchase.goodReceiveNoteId,
                    taxRateId: this.purchase.taxRateId,
                    taxMethod: this.purchase.taxMethod,
                    isRaw: this.purchase.isRaw,
                    purchasePostItems: this.purchase.purchaseItems,
                    actionProcess: this.purchase.actionProcess,
                    purchaseAttachments: this.purchase.purchaseAttachments,
                    paymentVoucher: this.purchase.paymentVoucher,
                    purchasePostExpense: this.purchase.purchasePostExpense,
                    isPurchasePost: invoiceType,
                    partiallyPurchase: this.purchase.partiallyPurchase,
                    autoPurchaseVoucher: this.purchase.autoPurchaseVoucher,
                    expenseToGst: localStorage.getItem('ExpenseToGst') == 'true' ? true : false,
                    internationalPurchase: this.internationalPurchase == 'true' ? true : false,
                    dueAmount: this.purchase.dueAmount,
                    isCredit: this.purchase.isCredit,
                    attachmentList: this.purchase.attachmentList,
                    bankCashAccountId: this.purchase.isCredit ? '' : this.purchase.bankCashAccountId,
                    paymentType: this.purchase.isCredit ? '' : this.purchase.paymentType,
                    colorVariants: localStorage.getItem('ColorVariants') == 'true' ? true : false,
                };

                purchasePost.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post("/PurchasePost/SavePurchasePostInformation", purchasePost, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {

                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            })
                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }
                            
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        root.$swal.fire({
                            icon: 'error',
                            title: 'Something Went Wrong!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },
            savePurchasePostPrint: function (isPurchasePost) {
                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                var purchasePost = {
                    id: this.purchase.id,
                    date: this.purchase.date,
                    registrationNo: this.purchase.registrationNo,
                    supplierId: this.purchase.supplierId,
                    invoiceNo: this.purchase.invoiceNo,
                    invoiceDate: this.purchase.invoiceDate,
                    purchaseOrder: this.purchase.purchaseOrder,
                    wareHouseId: this.purchase.wareHouseId,
                    purchasePostItems: this.purchase.purchaseItems,
                    isPurchasePost: isPurchasePost,
                };
                purchasePost.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post("/PurchasePost/SavePurchasePostInformation", purchasePost, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        root.loading = false;
                        root.$swal.fire({
                            icon: "success",
                            title: "Saved Successfully",
                            showConfirmButton: false,

                            timer: 800,
                            timerProgressBar: true,
                        });
                        root.printId = response.data.id;
                    }).then(function () {
                        root.$https
                            .get("/PurchasePost/PurchasePostDetail?Id=" + root.printId, {
                                headers: { Authorization: `Bearer ${token}` },
                            })
                            .then(function (response) {
                                if (response.data != null) {

                                    root.printDetails = response.data;
                                    root.printRender++;
                                }
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        root.$swal.fire({
                            icon: "error",
                            title: "Something Went Wrong!",
                            text: error,
                        });

                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },
            savePurchase: function () {
                
                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post("/Purchase/SavePurchaseInformation", root.purchase, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            root.$router.go('addpurchase');
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });

                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }

                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        root.$swal.fire({
                            icon: "error",
                            title: "Something Went Wrong!",
                            text: error,
                        });

                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },

            goToPurchase: function () {
                if (this.isValid('CanViewPurchaseDraft') || this.isValid('CanViewPurchasePost')) {
                    this.$router.push({
                        path: '/purchase',
                        query: {
                            data: 'Addpurchase'
                        }
                    })
                }
                else {
                    this.$router.go();
                }
                
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            if (this.isValid('CashPurchase')) {
                this.purchase.isCredit = false
            }
            else if (this.isValid('CreditPurchase')) {
                this.purchase.isCredit = true
            }
            
            
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;

            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            this.purchase.partiallyPurchase = localStorage.getItem('PartiallyPurchase') == 'true' ? true : false;
            this.purchase.autoPurchaseVoucher = localStorage.getItem('AutoPurchaseVoucher') == 'true' ? true : false;
            this.purchase.expenseToGst = localStorage.getItem('ExpenseToGst') == 'true' ? true : false;
            this.defaultVat = localStorage.getItem('DefaultVat');
            //this.purchaseOrder = localStorage.getItem('PurchaseOrder') == 'true' ? true : false;

            if (this.$route.query.data != undefined) {
                var detail = this.$route.query.data;
                this.purchase.actionProcess = detail.actionProcess;
                this.purchase.date = detail.date;
                this.purchase.discountAmount = detail.discountAmount;
                this.purchase.id = detail.id;
                this.purchase.invoiceDate = detail.invoiceDate;
                this.purchase.invoiceFixDiscount = detail.invoiceFixDiscount;
                this.purchase.invoiceNo = detail.invoiceNo;
                this.purchase.isPurchasePost = detail.isPurchasePost;
                this.purchase.isPurchaseReturn = detail.isPurchaseReturn;
                this.purchase.isRaw = detail.isRaw;
                this.purchase.netAmount = detail.netAmount;
                this.purchase.purchaseInvoiceId = detail.purchaseInvoiceId;
                this.purchase.purchaseOrderId = detail.purchaseOrderId;
                this.purchase.goodReceiveNoteId = detail.goodReceiveNoteId;
                this.purchase.purchaseOrderNo = detail.purchaseOrderNo;
                this.purchase.purchaseItems = detail.purchasePostItems;
                this.purchase.registrationNo = detail.registrationNo;
                this.purchase.supplierId = detail.supplierId;
                this.purchase.taxMethod = detail.taxMethod;
                this.purchase.taxRateId = detail.taxRateId;
                this.purchase.wareHouseId = detail.wareHouseId;
                this.purchase.attachmentList = detail.attachmentList;
                
                this.purchase.bankCashAccountId = detail.bankCashAccountId;
                this.purchase.paymentType = detail.paymentType;
                this.purchase.isCredit = detail.isCredit;
                if (this.purchase.purchaseOrderId != null && this.internationalPurchase == 'true') {
                    this.GetPoData(this.purchase.purchaseOrderId, true);
                }
                this.rander++;
            }
            else {
                this.purchase.wareHouseId = localStorage.getItem('WareHouseId');
                this.purchase.taxRateId = localStorage.getItem('TaxRateId');
                this.purchase.taxMethod = localStorage.getItem('taxMethod');
            }
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');

            this.currency = localStorage.getItem('Currency');

            this.paymentTypeOptions = ['Cash', 'Bank'];

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {

                this.raw = localStorage.getItem('IsProduction');
            }

            this.AutoIncrementCode();
            if (this.$route.query.data == undefined) {
                this.purchase.date = moment().format("LLL");

            }

        },
    };
</script>

<style scoped>
    .invoice_top_bg {
        background-color: #3178f6;
    }

    .title_heading {
        font-weight: 600;
    }

    .bt_bg_color {
        background-color: #3178F6 !important;
        color: #ffffff !important;
        border-color: #3178F6 !important;
        box-shadow: 0px 14px 34px rgba(49, 120, 246, 0.5) !important;
        border-radius: 10px;
    }

    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>
