<template>
    <div class="row" v-if="isValid('CanViewPosTerminal')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
            <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title DayHeading">{{ $t('BankPosTerminal.BankPosTerminal') }}</h5>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('BankPosTerminal.Home') }}</a></router-link></li>

                            <li class="breadcrumb-item active" aria-current="page">{{ $t('BankPosTerminal.BankPosTerminal') }}</li>

                        </ol>
                    </nav>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                        <a href="javascript:void(0)" class="btn btn-primary  " v-on:click="openmodel" v-if="isValid('CanAddPosTerminal')"><i class="fa fa-plus"></i>  {{ $t('BankPosTerminal.AddNew') }}</a>
                        <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('BankPosTerminal.Close') }}</a></router-link>
                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header">
                    <div class="row mb-3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label>{{ $t('BankPosTerminal.SearchByTerminalIdAndBank')}}</label>
                                <div>
                                    <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('BankPosTerminal.Search')" />
                                    <span class="fas fa-search search_icon"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">

                        <div class="col-lg-12">
                            <div>
                                <table class="table table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                    <thead class="">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('BankPosTerminal.TerminalId') }}
                                            </th>
                                            <th class="text-center">
                                                {{ $t('BankPosTerminal.BankName') }}
                                            </th>
                                            <th>
                                                {{ $t('BankPosTerminal.Status') }}
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(bankPosTerminal,index) in resultQuery" v-bind:key="bankPosTerminal.id">
                                            <td>
                                                {{index+1}}
                                            </td>

                                            <td v-if="isValid('CanEditPosTerminal')">
                                                <strong>
                                                    <a href="javascript:void(0)" v-on:click="EditBankPosTerminal(bankPosTerminal.id)">  {{bankPosTerminal.terminalId}}</a>
                                                </strong>
                                            </td>
                                            <td v-else>
                                                <strong>
                                                    {{bankPosTerminal.terminalId}}
                                                </strong>
                                            </td>

                                            <td class="text-center">
                                                {{bankPosTerminal.bankName}}
                                            </td>
                                            <td>{{bankPosTerminal.isActive==true?$t('BankPosTerminal.Active'):$t('BankPosTerminal.De-Active')}}</td>



                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <bankPosTerminalmodal :bankPosTerminal="newBankPosTerminal"
                              :show="show"
                              v-if="show"
                              @close="show = false"
                              :type="type" />

    </div>

    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                bankPosTerminallist: [],
                newBankPosTerminal: {
                    id: '',
                    terminalId: '',
                    bankId: '',
                    isActive: true
                },
                type: '',
            }
        },
        computed: {
            resultQuery: function () {
                var root = this;
                if (this.searchQuery) {
                    return this.bankPosTerminallist.filter((bankPosTerminal) => {
                        return root.searchQuery.toLowerCase().split(' ').every(v => bankPosTerminal.terminalId.toLowerCase().includes(v) || bankPosTerminal.bankName.toLowerCase().includes(v))
                    })
                } else {
                    return root.bankPosTerminallist;
                }
            },
        },
        methods: {
            openmodel: function () {
                this.newBankPosTerminal = {
                    id: '00000000-0000-0000-0000-000000000000',
                    terminalId: '',
                    bankId: '',
                    isActive: true

                }
                this.show = !this.show;
                this.type = "Add";
            },
            GetBankPosTerminalData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('Company/BankPosTerminalList?isActive=false', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.bankPosTerminallist = response.data.bankPosTerminals;
                    }
                });
            },
            EditBankPosTerminal: function (Id) {


                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Company/BankPosTerminalDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data) {

                            root.newBankPosTerminal.id = response.data.id;
                            root.newBankPosTerminal.terminalId = response.data.terminalId;
                            root.newBankPosTerminal.bankId = response.data.bankId;
                            root.newBankPosTerminal.isActive = response.data.isActive;
                            root.show = !root.show;
                            root.type = "Edit"
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.GetBankPosTerminalData();
        }
    }
</script>