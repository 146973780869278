<template>
    <div>
        <div hidden id='inventoryDetailReport' class="col-md-7">
            <!--HEADER-->
            <div class="col-md-12" style="height:45mm;border:2px solid #000000;">
                <div class="row" style="height:35mm">
                    <div class="col-md-4 ">
                        <table class="text-left">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            Tel: {{headerFooters.company.phoneNo}}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                    </div>
                    <div class="col-md-4 ">
                        <table class="text-right" v-if="arabic=='true'">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            هاتف: {{headerFooters.company.phoneNo}}:
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!--<div class="row " style="margin-bottom:10px !important;height:10mm">
                    <div class="col-md-12">
                        <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                            <span style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">
                                {{ ($i18n.locale == 'en' ||isLeftToRight()) ? 'Sale Invoice ' : 'فاتورة البيع' }}
                                <span v-if="list.invoiceType==0">{{ ($i18n.locale == 'en' ||isLeftToRight()) ? '(Hold)' : '(معلق)' }} </span>
                                <span v-if="list.partiallyInvoice=='Fully' && list.isCredit">{{ ($i18n.locale == 'en' ||isLeftToRight()) ? '(Paid)' : '(مدفوعة)' }} </span>
                            </span>
                        </p>
                    </div>
                </div>-->
            </div>
            <!--<div v-else style="height: 40mm;">

            </div>-->
            <!--INFORMATION-->
            <div class="border border-dark col-md-12 my-1 ">
                <div class="row" v-if="isShown || ledger.transactionList.length > 0">
                    <div class="col-md-6" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">From Date:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{fDate}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:من التاريخ</div>
                    </div>
                    <div class="col-md-6" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">To Date:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{tDate}}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :حتي اليوم
                        </div>
                    </div>
                    <div class="col-md-6" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">Account Name:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{ledger.transactionList[0].accountName}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:أسم الحساب</div>
                    </div>
                    <div class="col-md-6" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">Opening Balanace:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{ledger.openingBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.openingBalance) }}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :الرصيد الافتتاحي
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 ">
                <div class="row">

                    <table class="table col-md-12 m-auto">
                        <tr class="heading" style="font-size:14px;">
                            <th class="text-left">#</th>
                            <th class="text-left">{{ $t('LedgerPrintReport.Date') }}</th>
                            <th class="text-left">{{ $t('LedgerPrintReport.TransactionType') }}</th>
                            <th class="text-left">{{ $t('LedgerPrintReport.DocumentNumber') }}</th>
                            <th class="text-left">{{ $t('LedgerPrintReport.Narration') }}</th>
                            <th class="text-right">{{ $t('LedgerPrintReport.Debit') }}</th>
                            <th class="text-right">{{ $t('LedgerPrintReport.Credit') }}</th>
                            <th class="text-right">{{ $t('LedgerPrintReport.Balance') }}</th>
                        </tr>
                        <tr style="font-size:13px; page-break-after: always;" v-for="(item, index) in ledger.transactionList" v-bind:key="item.id">
                            <td class="text-left">{{index+1}}</td>
                            <td class="text-left">{{item.date}}</td>
                            <td class="text-left">{{item.transactionType}}</td>
                            <td class="text-left">{{item.documentNumber}}</td>
                            <td class="text-left">{{item.description}}</td>
                            <td class="text-right">
                                {{  nonNegative(item.debitAmount)}}
                            </td>
                            <td class="text-right">
                                {{nonNegative(item.creditAmount)}}
                            </td>
                            <td class="text-right">
                                {{item.openingBalance>0?'Dr':'Cr'}} {{nonNegative(item.openingBalance) }}
                            </td>
                        </tr>

                        <tr style="font-size:13px;">
                            <td class="text-right" colspan="5">
                                <h6>{{ $t('LedgerPrintReport.ClosingBalance') }}:</h6>
                            </td>
                            <td class="text-right">
                                <h6>{{  nonNegative(ledger.totalDebit)}}</h6>
                            </td>
                            <td class="text-right">
                                <h6>{{nonNegative(ledger.totalCredit)}}</h6>
                            </td>
                            <td class="text-right">
                                <h6> {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</h6>
                            </td>
                        </tr>
                    </table>
                    <!--INFORMATION FOOTER-->
                    <!--<div class="col-md-12 " style="height: 40mm; border-top: 1px solid !important" >
        <div class="row">
            <div class="col-md-4 p-0" style="height:10mm;">
                <div class="col-md-12" style="height:10mm;">
                    <div class="row text-right">
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0" style="height:10mm;">
                <div class="col-md-12" style="height:10mm;">
                    <div class="row text-right">
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-0" style="height:10mm;">
                <div class="col-md-12" style="height:10mm;">
                    <div class="row text-right" >
                        <div class="col-md-12" style="height:10mm; font-weight: bold"> {{ $t('LedgerPrintReport.ClosingBalance') }}: {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
                    <!--<div class="col-md-12  " style="height:30mm;">
        <div class="row">
            <table class="table text-center">
                <tr>
                    <td>
                        {{ $t('AllReports.ReceivedBy') }}
                    </td>
                    <td>
                        {{ $t('AllReports.ApprovedBy') }}
                    </td>
                    <td>
                        {{ $t('AllReports.PreparedBy') }}
                    </td>
                </tr>

            </table>
        </div>
    </div>-->
                    <!--FOOTER-->
                    <!--<div class="border border-dark col-md-12 " style="height: 30mm;" >
        <div class="row">
            <div class="col-md-3 text-center">
                <u><b><span style="font-size:14px;">Terms & Conditions</span></b></u><br />
                <span style="font-size:11px;" v-html="headerFooters.footerEn">

                </span>
            </div>
            <div class="col-md-6  text-center">
                <p style="padding-top:15px;font-size:11px;">
                    Warranty period 5 days after receiving the goods.
                </p>
            </div>
            <div class="col-md-3 text-center">
                <u><b><span style="font-size:14px;">البنود و الظروف</span></b></u><br />
                <span class=" text-center" style="font-size:11px;" v-html="headerFooters.footerAr">

                </span>
            </div>
        </div>
    </div>-->
                    <!--<div style="height: 35mm;" v-else></div>-->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";
    export default {
        props: ['printDetails', 'isShown', 'formName', 'isPrint', 'dates', 'headerFooter', 'fromDate', 'toDate',''],
        data: function () {
            return {
                ledger: {
                    transactionList: [],
                    openingBalance: 0,
                    runningBalance: 0,
                    totalCredit: 0,
                    totalDebit: 0,
                },
                fDate: '',
                tDate: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                Print: false,
                render: 0,
                arabic: '',
                english: '',
            }
        },
        mounted: function () {
            
            this.fDate = this.fromDate;
            this.tDate = this.toDate;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            var root = this;
            
            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            if (this.printDetails.transactionList.length > 0 && root.Print) {
                
                this.ledger = this.printDetails;
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
        },
        methods: {
            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            printInvoice: function () {
                this.$htmlToPaper('inventoryDetailReport');
            }
        }
    }
</script>


<style scoped>
    #font11 {
        font-size: 11px;
        line-height: 0;
    }

    #font16 {
        font-size: 16px;
    }
</style>