<template>
    <div>
        <div ref="mychildcomponent" hidden id='inventoryDetailReport' class="col-md-7">
            <!--HEADER-->
            <div class="col-md-12">
                <table class="table table-borderless">
                    <tr>
                        <td style="width:36%;" class="text-left pt-0 pb-0 pl-0 pr-0">
                            <p class="mb-0">
                                <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:13px;color:black !important;font-weight:bold;">
                                    Tel: {{headerFooters.company.phoneNo}}
                                </span>
                            </p>
                        </td>
                        <td style="width:26%;" class="text-center pt-0 pb-0 pl-0 pr-0">
                            <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                        </td>
                        <td style="width:38%;" class="pt-0 pb-0 pl-0 pr-0">
                            <p class="text-right mb-0" v-if="arabic=='true'">
                                <span style="font-size:23px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                <span style="font-size:15px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                <span style="font-size:14px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                <span style="font-size:13px;color:black !important;font-weight:bold;">
                                    هاتف: {{headerFooters.company.phoneNo}}:
                                </span>
                            </p>
                        </td>
                    </tr>

                    <tr>

                        <td style="width:100%;" class="pt-0 pb-0 pl-0 pr-0" colspan="3">
                            <div style="text-align: center;">
                                <span style="font-size:20px;color:black !important;font-weight:bold;padding-bottom:5px !important">{{ $t('AccountLedger.AccountLedger') }}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>            <!--INFORMATION-->
            <!--<div v-else style="height: 40mm;">

            </div>-->
            <!--INFORMATION-->
            <div style="height:25mm;margin-top:1mm; border:2px solid #000000;">
                <div class="row">
                    <div class="col-md-12 ml-2 mr-2">
                        <table class="table table-borderless">
                            <!--Row 1-->
                            <tr>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">From Date:</td>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{fromDate}}</td>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">:من التاريخ</td>

                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;">To Date:</td>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:25%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;">{{toDate}}</td>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;" v-if="arabic=='true'">  :حتي اليوم</td>
                            </tr>




                            <tr>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Account Name:</span> </td>
                                <td colspan="4" class="pl-0 pr-0 pt-0 pb-0" style="width:75%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span>{{ledger.transactionList[0].accountName}}</span> </td>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:11%;font-weight:bolder;font-size:14px !important;color:black !important;"><span>:أسم الحساب</span></td>

                            </tr>
                            <tr>
                                <td class="pl-0 pr-0 pt-0 pb-0" style="width:14%; font-weight:bolder;text-align:right;color:black !important;font-size:14px !important;"><span>Opening Balance:</span> </td>
                                <td colspan="4" class="pl-0 pr-0 pt-0 pb-0" style="width:60%; text-align:center;color:black !important;font-weight:bold;font-size:14px !important;"><span>{{ledger.openingBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.openingBalance) }}</span> </td>
                                <td class="pr-3 pt-0 pb-0" style="width:26%;font-weight:bolder;font-size:14px !important;color:black !important;"><span>:الرصيد الافتتاحي</span></td>

                            </tr>





                        </table>
                    </div>

                </div>
            </div>            <div class="col-md-12 ">
                <div class="row">

                    <table class="table col-md-12 m-auto">
                        <tr class="heading" style="font-size:14px;">
                            <th class="text-left" style="color:black !important;">#</th>
                            <th class="text-left" style="color:black !important;">{{ $t('LedgerPrintReport.Date') }}</th>
                            <th class="text-left" style="color:black !important;">{{ $t('LedgerPrintReport.TransactionType') }}</th>
                            <th class="text-left" style="color:black !important;">{{ $t('LedgerPrintReport.DocumentNumber') }}</th>
                            <th class="text-left" style="color:black !important;">{{ $t('LedgerPrintReport.Narration') }}</th>
                            <th class="text-right" style="color:black !important;">{{ $t('LedgerPrintReport.Debit') }}</th>
                            <th class="text-right" style="color:black !important;">{{ $t('LedgerPrintReport.Credit') }}</th>
                            <th class="text-right" style="color:black !important;">{{ $t('LedgerPrintReport.Balance') }}</th>
                        </tr>
                        <tr style="font-size:13px; page-break-after: always;" v-for="(item, index) in ledger.transactionList" v-bind:key="item.id">
                            <td class="text-left" style="color:black !important;">{{index+1}}</td>
                            <td class="text-left" style="color:black !important;">{{item.date}}</td>
                            <td class="text-left" style="color:black !important;">{{item.transactionType}}</td>
                            <td class="text-left" style="color:black !important;">{{item.documentNumber}}</td>
                            <td class="text-left" style="color:black !important;">{{item.description}}</td>
                            <td class="text-right" style="color:black !important;">
                                {{  nonNegative(item.debitAmount)}}
                            </td>
                            <td class="text-right" style="color:black !important;">
                                {{nonNegative(item.creditAmount)}}
                            </td>
                            <td class="text-right" style="color:black !important;">
                                {{item.openingBalance>0?'Dr':'Cr'}} {{nonNegative(item.openingBalance) }}
                            </td>
                        </tr>

                        <tr style="font-size:13px;">
                            <td class="text-right" colspan="5" style="color:black !important;">
                                <h6>{{ $t('LedgerPrintReport.ClosingBalance') }}:</h6>
                            </td>
                            <td class="text-right" style="color:black !important;">
                                <h6>{{  nonNegative(ledger.totalDebit)}}</h6>
                            </td>
                            <td class="text-right" style="color:black !important;">
                                <h6>{{nonNegative(ledger.totalCredit)}}</h6>
                            </td>
                            <td class="text-right" style="color:black !important;">
                                <h6> {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</h6>
                            </td>
                        </tr>
                    </table>
                    <!--INFORMATION FOOTER-->
                    <!--<div class="col-md-12 " style="height: 40mm; border-top: 1px solid !important" >
                        <div class="row">
                            <div class="col-md-4 p-0" style="height:10mm;">
                                <div class="col-md-12" style="height:10mm;">
                                    <div class="row text-right">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-0" style="height:10mm;">
                                <div class="col-md-12" style="height:10mm;">
                                    <div class="row text-right">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-0" style="height:10mm;">
                                <div class="col-md-12" style="height:10mm;">
                                    <div class="row text-right" >
                                        <div class="col-md-12" style="height:10mm; font-weight: bold"> {{ $t('LedgerPrintReport.ClosingBalance') }}: {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>-->
                    <!--<div class="col-md-12  " style="height:30mm;">
                        <div class="row">
                            <table class="table text-center">
                                <tr>
                                    <td>
                                        {{ $t('AllReports.ReceivedBy') }}
                                    </td>
                                    <td>
                                        {{ $t('AllReports.ApprovedBy') }}
                                    </td>
                                    <td>
                                        {{ $t('AllReports.PreparedBy') }}
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>-->
                    <!--FOOTER-->
                    <!--<div class="border border-dark col-md-12 " style="height: 30mm;" >
                        <div class="row">
                            <div class="col-md-3 text-center">
                                <u><b><span style="font-size:14px;">Terms & Conditions</span></b></u><br />
                                <span style="font-size:11px;" v-html="headerFooters.footerEn">

                                </span>
                            </div>
                            <div class="col-md-6  text-center">
                                <p style="padding-top:15px;font-size:11px;">
                                    Warranty period 5 days after receiving the goods.
                                </p>
                            </div>
                            <div class="col-md-3 text-center">
                                <u><b><span style="font-size:14px;">البنود و الظروف</span></b></u><br />
                                <span class=" text-center" style="font-size:11px;" v-html="headerFooters.footerAr">

                                </span>
                            </div>
                        </div>
                    </div>-->
                    <!--<div style="height: 35mm;" v-else></div>-->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";
    export default {
        props: ['printDetails', 'isShown', 'formName', 'isPrint', 'dates', 'headerFooter', 'fromDate', 'toDate', ''],
        data: function () {
            return {
                ledger: {
                    transactionList: [],
                    openingBalance: 0,
                    runningBalance: 0,
                    totalCredit: 0,
                    totalDebit: 0,
                },
                fDate: '',
                tDate: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                Print: false,
                render: 0,
                arabic: '',
                english: '',
            }
        },
        mounted: function () {

            this.fDate = this.fromDate;
            this.tDate = this.toDate;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            var root = this;

            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            if (this.printDetails.transactionList.length > 0 && root.Print) {

                this.ledger = this.printDetails;
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
        },
        methods: {
            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            printInvoice: function () {
                
                var form = new FormData();
                form.append('htmlString', this.$refs.mychildcomponent.innerHTML);
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Report/PrintPdf', form, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var date = moment().format('DD MMM YYYY');


                        link.setAttribute('download', 'Account Ledger Report ' + date + '.pdf');
                        document.body.appendChild(link);
                        link.click();

                        root.$emit('close');
                    });
            }
        }
    }
</script>


<style scoped>
    #font11 {
        font-size: 11px;
        line-height: 0;
    }

    #font16 {
        font-size: 16px;
    }
</style>