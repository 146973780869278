<template>
    <div style="background-color:white !important;">
        <div hidden ref="mychildcomponent" id='purchaseInvoice' class="col-md-12 your-background" style="color:black !important;background-color:white !important;height: 210mm !important">
            <div style="background-color:white !important">
                <!--HEADER-->
                <div class="row" style="background-color:white !important">
                    <div class="col-4" style="background-color:white !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px;">
                    </div>
                    <div class="col-4 text-center" style="background-color:white !important">
                        <p style="font-size: 25px; color: #222234 !important; font-weight: bold;padding-top:19px">{{headerFooters.company.nameEnglish}}</p>
                    </div>
                    <div class="col-4 text-center" style="background-color:white !important">
                        <barcode :width="1.9" :height="50" :displayValue="false" v-bind:value="list.barCode"></barcode>
                    </div>
                </div>
                <div class="row" style="background-color:white !important">
                    <div class="col-8" style="background-color:white !important">
                        <div class="row" style="background-color:white !important;font-size: 15px; color: black !important;font-weight:bold;font-style:italic">
                            <div class="col-2" style="margin-bottom:0px !important;padding-bottom:0px !important">Address:</div>
                            <div class="col-10" style="margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.addressEnglish}}</span>

                            </div>
                            <div class="col-2" style="margin-bottom:0px !important;padding-bottom:0px !important">STN/NTN:</div>
                            <div class="col-10" style="margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.vatRegistrationNo}}</span>

                            </div>

                            <div class="col-2" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">Phone:</div>
                            <div class="col-10" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.phoneNo}}</span>

                            </div>
                            <div class="col-2" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">E-Mail:</div>
                            <div class="col-10" style="padding-top:0px !important;margin-top:0px !important;margin-bottom:0px !important;padding-bottom:0px !important">
                                <span>{{headerFooters.company.companyEmail}}</span>

                            </div>
                            <!--<div class="col-12">
        <p style="font-size: 16px; color: #222234 !important; "><span style="padding-right:6px !important"><img src="Group 144.png" style="width:auto;max-width:15px; max-height:15px;"></span><span>{{headerFooters.company.companyEmail}}</span></p>

    </div>
    <div class="col-12">
        <p style="font-size: 16px; color: #222234 !important; "><span style="padding-right:6px !important"><img src="/images/phone.png" style="width:auto;max-width:15px; max-height:15px;"></span><span>{{headerFooters.company.phoneNo}}</span></p>

    </div>-->


                        </div>
                        <!--<p style="font-size: 14px; color: #222234 !important; font-weight: bold;">{{headerFooters.company.addressEnglish}}</p>
                        <p style="font-size: 14px; color: #222234 !important; font-weight: bold;"><span><img src="Group 144.png" /></span><span>{{headerFooters.company.email}}</span></p>
                        <p style="font-size: 14px; color: #222234 !important; font-weight: bold;"><span><img src="Phone45435.png" /></span><span>{{headerFooters.company.phoneNo}}</span></p>-->
                    </div>

                    <div class="col-4 ">
                        <div class="row" style="background-color:white !important;font-size: 16px; color: black !important">
                            <div class="col-6 text-right" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;">Date:</div>
                            <div class="col-6 text-left" style="margin-bottom:0px !important;padding-bottom:0px !important;color:black">
                                <span>{{list.date}}</span>

                            </div>
                            <div class="col-6 text-right" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;">Time:</div>
                            <div class="col-6 text-left" style="margin-bottom:0px !important;padding-bottom:0px !important;color:black">
                                <span>{{list.time}}</span>

                            </div>




                        </div>

                    </div>
                </div>
                <div class="row" style="background-color:white !important;font-size: 15px; color: black !important">
                    <div class="col-1" style="padding-bottom:15px !important;font-weight:bold;padding-top:15px">Customer:</div>
                    <div class="col-11" style="padding-bottom:15px !important;padding-top:15px">
                        <span v-if="list.cashCustomer != null">{{list.cashCustomer}}</span> <span v-else>{{list.customerNameEn}}</span>

                    </div>

                    <div class="col-1" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;padding-top:6px !important"></div>
                    <div class="col-11" style="margin-bottom:0px !important;padding-bottom:0px !important;padding-top:6px !important">
                        <span>{{list.customerAddressWalkIn}}</span>

                    </div>
                    <div class="col-1" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;"></div>
                    <div class="col-11" style="margin-bottom:0px !important;padding-bottom:0px !important">
                        <span>Pakistan</span>

                    </div>
                    <div class="col-1" style="margin-bottom:0px !important;padding-bottom:0px !important;font-weight:bold;"></div>
                    <div class="col-11" style="margin-bottom:0px !important;padding-bottom:0px !important">
                        <span><span v-if="list.cashCustomer != null && headerFooters.customerTelephone">{{list.mobile}}</span> <span v-if="list.creditCustomer != null && headerFooters.customerTelephone">{{list.customerTelephone}}</span></span>

                    </div>




                </div>

                <div class="row" style="font-size:16px">
                    <table class="table ">
                        <tr class="heading" style="font-size:16px !important;background-color:#F2F2F2 !important">
                            <th class="text-left" style="width:25%;padding-top:5px !important; padding-bottom:5px !important;background-color:#F2F2F2 !important;padding-left:20px !important">Refrence No</th>
                            <th class="text-center" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Order Date</th>
                            <th class="text-center" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Delivery Date</th>
                            <th class="text-right" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important;padding-right:25px !important">Mode</th>
                        </tr>
                        <tr class="heading" style="font-size:16px !important;background-color:#F2F2F2">
                            <th class="text-left" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-left:20px !important">{{list.registrationNo}}</th>
                            <th class="text-center" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important">{{list.date}}</th>
                            <th class="text-center" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important">{{list.date}}</th>
                            <th class="text-right" style="width:25%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;padding-right:25px !important"><span v-if="!list.isCredit">Cash</span><span v-else>Credit</span></th>
                        </tr>
                        <tr>
                        </tr>
                    </table>

                </div>


                <div class="row " style="background-color:white !important;height:280mm">
                    <div class="col-md-12 ">
                        <table class="table " style="border-left: 0 !important;border-bottom: 0 !important;">
                            <tr class="heading" style="font-size:16px !important;background-color:#F2F2F2 !important;color:black !important">
                                <th class="text-center" style="width:5%;padding-top:5px !important; padding-bottom:5px !important;background-color:#F2F2F2 !important;"> No</th>
                                <th class="text-center" style="width:40%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Product Name</th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Quantity</th>
                                <th class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Unit Price</th>
                                <th class="text-center" style="width:20%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Exc GST </th>
                                <th class="text-center" style="width:8%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Gst%</th>
                                <th class="text-center" style="width:14%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Gst Amt</th>
                                <th class="text-right" style="width:15%;padding-top:4px !important; padding-bottom:4px !important;background-color:#F2F2F2 !important">Total</th>
                            </tr>


                            <template v-for="(item, index) in list.saleItems">
                                <tr style="font-size:16px;border-bottom:0.01px  solid grey;color:black !important " v-bind:key="item.id">
                                    <td class="text-center" style="width:3%;padding-top:5px !important; padding-bottom:5px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{index+1}}</td>
                                    <td class="text-center" style="width:35%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.product.styleNumber}}{{item.productName}} </td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.quantity }}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">Rs.{{item.unitPrice.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.total.toFixed(3).slice(0,-1)}}</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">{{item.taxRate}} %</td>
                                    <td class="text-center" style="width:10%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey">{{(item.includingVat+item.inclusiveVat).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                    <td class="text-right" style="width:15%;padding-top:4px !important; padding-bottom:4px !important;background-color:white !important;border-bottom:0.01px  solid grey;color:black !important">Rs.{{(((item.total - (item.discountAmount+item.bundleAmount)) + item.includingVat)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</td>
                                </tr>
                            </template>



                            <tr>
                                <td class="text-center" style="color: white !important; padding-top: 10px !important; padding-bottom: 10px !important;border:0!important;background-color:white " colspan="4">
                                </td>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey" colspan="4"><span style="float:left;font-weight:bolder">Discount :</span> <span style="float:right">{{currency}}   {{parseFloat(calulateDiscountAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <tr>
                                <td class="text-center" style="color: white !important; padding-top: 10px !important; padding-bottom: 10px !important;border:0!important;background-color:white " colspan="4" rowspan="3">
                                    <!--<div style="display:flex">
                        <div style="width:40%;color:black;font-size:16px ;margin-left:20px;margin-right:15px;padding:0px !important;margin-top:0px !important">&nbsp;</div>
                        <div style="width:40%;color:black;font-size:16px;color:black;padding:0px !important;margin-top:0px !important;font-weight:bold">Total Balance :Rs. 6</div>
                    </div>-->
                                    <div style="display:flex">

                                        <div style="border:0.5px solid black;width:40%;color:black;font-size:16px ;margin-left:20px;margin-right:15px">
                                            <p style="font-weight:bold">Total Payment Details</p>
                                            <span style="font-weight:bold;float:left;padding-left:5px">Cash:</span><span style="float:right;padding-right:10px">Rs.2</span><br />
                                            <span style="font-weight:bold;float:left;padding-left:5px">Cheque:</span><span style="float:right;padding-right:10px">Rs.10</span><br />
                                            <span style="font-weight:bold;float:left;padding-left:5px">Online:</span><span style="float:right;padding-right:10px"></span><br />
                                            <span style="font-weight:bold;float:left;padding-left:5px">&nbsp;</span><span style="float:right;padding-right:10px"></span><br />
                                        </div>
                                        <div style="border:0.5px solid black;width:40%;color:black;font-size:16px ">
                                            <span style="font-weight:bold;float:left;padding-left:5px">Chq No:</span><span style="float:right;padding-right:10px">gftgr</span><br />
                                            <span style="font-weight:bold;float:left;padding-left:5px">Amount:</span><span style="float:right;padding-right:10px">Rs.10</span><br />
                                            <span style="font-weight:bold;float:left;padding-left:5px">Date:</span><span style="float:right;padding-right:10px">{{list.date}}</span><br />
                                            <span style="font-weight:bold;float:left;padding-left:5px">Bank:</span><span style="float:right;padding-right:10px">Meezan Bank</span><br />
                                        </div>
                                    </div>
                                </td>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey" colspan="4">
                                    <span style="float:left;font-weight:bolder">GST:</span> <span style="float:right;font-weight:bolder">{{currency}} {{parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey" colspan="4"><span style="float:left;font-weight:bolder">Total After Discount:</span> <span style="float:right;font-weight:bolder">{{currency}}   {{parseFloat(calulateTotalExclVAT-calulateDiscountAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <tr>
                                <td style="color:black !important;background-color: white !important;font-size:15px;padding-top:10px !important; padding-bottom:10px !important;border-bottom:0.01px solid grey" colspan="4"><span style="float:left;font-weight:bolder">Total With GST :</span> <span style="float:right;">{{currency}}   {{parseFloat(calulateNetTotal - (calulateDiscountAmount + calulateBundleAmount)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
                            </tr>
                            <!--<tr style="font-weight:bold;">
                <td class="text-center" style="color: #222234 !important; padding-top: 10px !important; padding-bottom: 10px !important;;border:0!important " colspan="2"></td>
                <td class="text-center" style="color: #222234 !important; padding-top: 10px !important; padding-bottom: 10px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                <td class="text-left" style="color:#222234 !important;font-size:19px;padding-top:10px !important; padding-bottom:10px !important;border:0!important;background-color:#F2F2F2 !important;border-top:1px solid #BCB0C4" colspan="4">Total After Discount</td>
                <td class="text-center" style="color:#222234 !important;background-color: #F2F2F2 !important;font-size:19px;padding-top:10px !important; padding-bottom:10px !important;border-top:1px solid #BCB0C4;" colspan="3"><span style="float:right;">{{currency}}   {{parseFloat(calulateTotalExclVAT-calulateDiscountAmount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
            </tr>
            <tr style="font-weight:bold;">
                <td class="text-center" style="color: #222234 !important; padding-top: 10px !important; padding-bottom: 10px !important;border:0 !important;border:0!important " colspan="2"></td>
                <td class="text-center" style="color: #222234 !important; padding-top: 10px !important; padding-bottom: 10px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                <td class="text-left" style="color:#222234 !important;font-size:19px;padding-top:10px !important; padding-bottom:10px !important;border:0!important;background-color:#F2F2F2 !important;border-top:1px solid #BCB0C4" colspan="4">GST</td>
                <td class="text-center" style="color:#222234 !important;background-color: #F2F2F2 !important;font-size:19px;padding-top:10px !important; padding-bottom:10px !important;border-top:1px solid #BCB0C4;" colspan="3"> <span style="float:right;">{{currency}}  {{parseFloat(calulateTotalVAT).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
            </tr>
            <tr style="font-weight:bold;">
                <td class="text-center" style="color: #222234 !important;  padding-top: 10px !important; padding-bottom: 10px !important; border:0  !important" colspan="2"></td>
                <td class="text-center" style="color: #222234 !important; padding-top: 10px !important; padding-bottom: 10px !important; border: 0 !important;" v-if="isMultiUnit=='true'"></td>
                <td class="text-left" style="color:#F2F2F2 !important;font-size:20px;padding-top:10px !important; padding-bottom:10px !important;border:1px solid #222234;background-color:#222234 !important" colspan="4"> Total With GST</td>
                <td class="text-center" style="color:#F2F2F2 !important;background-color: #222234 !important;font-size:19px;padding-top:10px !important; padding-bottom:10px !important;border:1px solid #222234;font-weight:bold;font-size:20px" colspan="3"> <span style="float:right;">{{currency}}   {{parseFloat(calulateNetTotal - (calulateDiscountAmount + calulateBundleAmount)).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></td>
            </tr>-->

                        </table>
                    </div>
                    <div class="col-md-12 ">
                        <div class="row" style="background-color:white !important;padding-top:60px;">

                            <div class="col-12 " style="font-size: 16px; color: black !important;padding-top:8px !important">
                                <p style="font-size: 16px; color: black !important; font-weight: bold;border-top:0.5px solid;display:inline-block">
                                    &nbsp;&nbsp;(Signature) &nbsp; &nbsp;
                                </p><br />
                                <span style="font-size: 16px; color: black !important;padding-top:8px !important">
                                    <span style="font-weight:bold">Serial No(s):</span><span></span>
                                </span><br />

                            </div>
                            <!--<div class="col-9"  style="font-size: 16px; color: black !important">
                <span style="font-weight:bold">Battery/UPS Card:</span><br />
                <span>With Customer <input type="checkbox" checked /></span>
            </div>-->
                            <!--<div class="col-3" style="font-size: 16px; color: black !important">
                <span style="font-weight:bold">Warranty:</span><br />
                <span>Company Warr <input type="checkbox" checked /></span>
            </div>-->


                        </div>

                    </div>
                    <div class="row" style=" position: absolute; bottom: 40px; line-height:1;background-color:white">
                        <div class="col-8 ">
                            <span style="font-size: 16px; color: black !important; font-weight: bold;border-bottom:0.5px solid;display:inline-block">
                                Terms & Conditions:

                            </span><br />
                            <!--<p style="font-size: 16px; color: black !important; font-weight: bold;border-bottom:0.01px solid black">
            </p>-->
                            <p style="font-size: 16px; color: #222234 !important; " v-html="headerFooters.footerEn">
                            </p>
                        </div>
                        <div class="col-4 text-center">
                            <span style="font-size: 16px; color: black !important; font-weight: bold;border-top:0.5px solid;">
                                (Customer Signature)
                            </span><br />
                            <span>I have read and accept Terms and Conditions.</span>
                        </div>


                    </div>

                </div>
                   

                </div>
        </div>
    </div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    import VueBarcode from 'vue-barcode';

    import axios from 'axios'
    const options = {
        name: '',
        specs: [
            'fullscreen=no',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',

        ],
        timeout: 700,
        autoClose: true,
        windowTitle: window.document.title,

    }
    export default {
        components: {
            'barcode': VueBarcode,

        },
        props: ['printDetails', 'headerFooter', 'isTouchScreen'],
        mixins: [clickMixin],
        data: function () {
            return {
                currency: "",
                customer: true,
                qrValue: "",
                isHeaderFooter: '',
                invoicePrint: '',
                IsDeliveryNote: '',
                arabic: '',
                english: '',
                userName: '',
                emptyListCount: 0,
                indexCount: 0,
                page: 0,
                isMultiUnit: '',
                list: {
                    number: 0,
                    listItemTotal: [],
                    registrationNo: '',
                    date: '',
                    time: '',
                    dueDate: '',
                    companyName: '',
                    companyPhoneNo: '',
                    companyAddress: '',
                    discountAmount: '',
                    cashCustomer: '',
                    creditCustomer: '',
                    customerPhoneNo: '',
                    customerAddress: '',
                    paymentMethod: '',
                    paymentMethodNo: '',
                    invocieType: '',
                    saleItems:
                        [

                        ]
                },
                render: 0,
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                htmlData: {
                    htmlString: ''
                }
            }
        },
        filters: {
            toWords: function (value) {
                var converter = require('number-to-words');
                if (!value) return ''
                return converter.toWords(value);
            }
        },
        computed: {
            calulateTotalQty: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + (Number((c.quantity) || 0) > 0 ? Number((c.quantity) || 0) : 0) }, 0)
            },
            calulateNetTotal: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0)
            },
            calulateTotalExclVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.total) || 0) }, 0)
            },
            calulateTotalVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0)
            },
            calulateTotalInclusiveVAT: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number((c.inclusiveVat) || 0) }, 0)
            },
            calulateDiscountAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            }
        },
        methods: {

            calulateDiscountAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.discountAmount || 0) }, 0)
            },
            calulateBundleAmount1: function () {
                return this.list.saleItems.reduce(function (a, c) { return a + Number(c.bundleAmount || 0) }, 0)
            },
            calulateNetTotalWithVAT: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.total + c.includingVat) || 0) }, 0);
                var grandTotal = parseFloat(total) - (this.calulateDiscountAmount1() + this.calulateBundleAmount1())
                return (parseFloat(grandTotal).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },
            calulateTotalVATofInvoice: function () {
                var total = this.list.saleItems.reduce(function (a, c) { return a + Number((c.includingVat + c.inclusiveVat) || 0) }, 0);
                return (parseFloat(total).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"));
            },

            printInvoice: function () {

                var root = this;
                this.$htmlToPaper('purchaseInvoice', options, () => {
                    if (root.isTouchScreen === 'TouchInvoice') {
                        root.$router.go('/TouchInvoice')
                    }
                    else if (root.isTouchScreen === 'addSale') {
                        root.$router.go('/addSale')
                    }
                    else if (root.isTouchScreen === 'sale') {
                        root.$router.push('/sale');
                    }
                    else {

                        console.log('No Rander the Page');
                    }

                });
            },
            printBlindInvoice: function () {
                var root = this;
                // this.$htmlToPaper('purchaseInvoice');
                this.htmlData.htmlString = this.$refs.mychildcomponent.innerHTML;
                //  var html1 = this.$refs.mychildcomponent.innerHTML;
                //  var data = { html: html1 }
                //
                var printerName = localStorage.getItem('PrinterName')
                var form = new FormData();
                form.append('htmlString', this.htmlData.htmlString);
                form.append('NoOfPagesPrint', 0);
                form.append('PrintType', 'invoice');
                form.append('PrinterName', printerName);
                //this.$htmlToPaper('purchaseInvoice');
                //axios.post('http://localhos:7013/print/from-pdf', form);
                //axios.post('http://127.0.0.1:7013/print/from-pdf', form);
                //alert();
                //var root = this;



                if (!this.$ServerIp.includes('localhost')) {
                    axios.post('http://127.0.0.1:7014/print/from-pdf', form).then(function (x) {
                        console.log(x);

                    });
                    //if (root.isTouchScreen === true) {
                    //    root.$router.go('/TouchInvoice')
                    //}
                }
                else {
                    this.$htmlToPaper('purchaseInvoice', options, () => {
                        if (root.isTouchScreen === true) {
                            root.$router.go('/TouchInvoice')
                        }
                        else {
                            root.$router.go('/addSale')
                        }
                    });

                }


                //var token = '';
                //if (this.$session.exists()) {
                //    token = localStorage.getItem('token');
                //}
                //root.loading = true;
                //root.$https.post('/EmployeeRegistration/PrintPos', data, { headers: { "Authorization": `Bearer ${token}` } }).then(function (x) {
                //    alert(x.data)
                //});



            },
            GetTLVForValue: function (tagNumber, tagValue) {
                var tagBuf = Buffer.from([tagNumber], 'utf-8')
                var tagValueLenBuf = Buffer.from([tagValue.length], 'utf-8')
                var tagValueBuf = Buffer.from(tagValue, 'utf-8')
                var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
                return Buffer.concat(bufsArray)
            }

        },
        mounted: function () {
            this.isMultiUnit = localStorage.getItem('IsMultiUnit');
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
            this.IsDeliveryNote = localStorage.getItem('IsDeliveryNote');
            this.userName = localStorage.getItem('FullName');
            this.currency = localStorage.getItem('currency');
            var root = this;
            if (this.printDetails.saleItems.length > 0) {
                this.list = this.printDetails;
                this.headerFooters = this.headerFooter;

                if (this.headerFooters.blindPrint) {
                    this.printBlindInvoice();
                }
                else {
                    var sellerNameBuff = root.GetTLVForValue('1', this.headerFooters.company.nameEnglish)
                    var vatRegistrationNoBuff = root.GetTLVForValue('2', this.headerFooters.company.vatRegistrationNo)
                    var timeStampBuff = root.GetTLVForValue('3', this.list.date)
                    var totalWithVat = root.GetTLVForValue('4', this.calulateNetTotalWithVAT())
                    var totalVat = root.GetTLVForValue('5', this.calulateTotalVATofInvoice())
                    var tagArray = [sellerNameBuff, vatRegistrationNoBuff, timeStampBuff, totalWithVat, totalVat]
                    var qrCodeBuff = Buffer.concat(tagArray)
                    root.qrValue = qrCodeBuff.toString('base64')

                    var count = this.printDetails.saleItems.length;
                    this.page = Math.ceil(count / 2);
                    if (count <= 20) {
                        this.emptyListCount = 12 - count;
                        this.indexCount = 12 - this.emptyListCount;

                    }
                    else {
                        console.log(count, this.page);
                    }
                    this.list.date = moment().format('DD MMM YYYY');
                    this.list.time = moment().format('hh:mm A');
                    setTimeout(function () {
                        root.printInvoice();
                    }, 125)
                }

            }
        },

    }
</script>
<style>
    body {
        background-color: white !important;
    }
    .page {
        background-color: white !important;
    }

    .your-background:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        border-bottom: 1000px solid #eee; /* Make it fit your needs */
    }

    .img {
        text-align: right !important;
        margin-top: 2px;
        padding-left: 2px
    }
</style>


