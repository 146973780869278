<template>
    <div v-if="isValid('CanViewItem')">
        <div class="card-body" v-if="loading">
            <div class="text-center" id="preloader">
                <div id="loader"></div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-lg-12 col-sm-12 ml-auto mr-auto">
                <div class="row mb-4" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <h5 class="page_title">{{ $t('Product.ListOfITems') }}</h5>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link :to="'/StartScreen'"><a href="javascript:void(0)"> {{ $t('Product.Home') }}</a></router-link></li>

                                <li class="breadcrumb-item active" aria-current="page"> {{ $t('Product.InventoryItems') }}</li>
                            </ol>
                        </nav>
                    </div>
                    <div class=" col-sm-6 col-md-6 col-lg-6">
                        <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                            <a href="javascript:void(0)" class="btn btn-outline-primary " v-if="isValid('CanAddItem')" v-on:click="AddProduct"><i class="fa fa-plus"></i> {{ $t('Product.AddNew') }}</a>
                            <router-link :to="'/StartScreen'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('Product.Close') }}</a></router-link>
                        </div>
                    </div>
                </div>
                <div class="card ">
                    <div class="card-header">

                        <div class="row mb-3 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                                <div class="form-group">
                                    <label>{{ $t('Product.SearchbyProductName') }}</label>
                                    <div>
                                        <input type="text" class="form-control search_input" v-model="search" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" name="search" id="search" :placeholder="$t('Product.SearchbyProductName')" />
                                        <span class="fas fa-search search_icon"></span>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-md-1 col-lg-1 mt-3 " v-if="isValid('CanFilterItem')">
                                <div class="form-group" >
                                    <div class="form-group ml-3">
                                        <a href="javascript:void(0)" v-on:click="FilterWareHouse" class="btn btn-outline-primary "><i class="fa fa-filter"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3  col-12 " style="margin-top:27px" id="hide" >
                                <warehouse-dropdown v-if="advanceFilters" v-model="warehouseId" v-on:selected="getPage()" v-on:input="getPage()" />
                            </div>
                            <div class="col-md-1 col-lg-1  " style="margin-top:14px">
                                <a href="javascript:void(0)" v-if="advanceFilters" id="hide" v-on:click="OpenProductModel" class="btn btn-outline-primary  float-right">{{ $t('Product.Item') }}</a>
                            </div>
                            <div class="col-md-3 col-lg-3 mt-3 text-right "  v-if=" isValid('CanImportProduct')">
                                <a href="javascript:void(0)" class="btn btn-outline-primary " v-on:click="ImportDataFromCsv">{{$t('Product.ImportItems')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">

                                <div class="mt-2">
                                    <div class=" table-responsive ">
                                        <table class="table table-hover table-striped table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                            <thead class="m-0">
                                                <tr>
                                                    <th style="width:10%;text-align:center;">
                                                        {{ $t('Product.Image') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Product.Code') }}
                                                    </th>
                                                    <th v-if="english=='true'">
                                                        {{ $t('Product.ItemName') |englishLanguage}}
                                                    </th>

                                                    <th v-if="isOtherLang()">
                                                        {{ $t('Product.ItemName') |arabicLanguage}}
                                                    </th>
                                                    <th class="text-center" width="30%">
                                                        {{ $t('Product.Description') }}
                                                    </th>
                                                    <th class="text-center">
                                                        {{ $t('Product.SalePrice') }}
                                                    </th>

                                                    <th class="text-center" v-if="isValid('CanViewCurrentStock')">
                                                        {{ $t('Product.CurrentStock') }}
                                                    </th>
                                                    <!--<th class="text-center">
                                                        {{ $t('Product.CurrentStockValue') }}
                                                    </th>-->

                                                    <th class="text-center" v-if="isValid('CanViewDetailItem')">
                                                        {{ $t('Product.ViewDetail') }}
                                                    </th>
                                                    <!--<th>
                                                        {{ $t('Product.LENGTH') }}
                                                    </th>
                                                    <th>
                                                        {{ $t('Product.SALE_RETURN_DAYS') }}
                                                    </th>-->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="details in productList" v-bind:key="details.id">

                                                    <td v-if="details.image==null || details.image=='' || details.image==undefined" class="text-center"><img src="productList.svg" style="width: 50px;" /></td>
                                                    <td v-else class="text-center"><productimage v-bind:path="details.image" /></td>
                                                    <td v-if="isValid('CanEditItem')">
                                                        <strong v-if="details.isRaw">
                                                            <a href="javascript:void(0)" style="color:red" v-on:click="EditProductInfo(details.id)">{{details.code}}</a>
                                                        </strong>
                                                        <strong v-else>
                                                            <a href="javascript:void(0)" v-on:click="EditProductInfo(details.id)">{{details.code}}</a>
                                                        </strong>
                                                    </td>
                                                    <td v-else v-bind:style="details.isRaw == true ? 'color:!important red' : ''"> {{details.code}}</td>

                                                    <td v-if="english=='true' && isOtherLang()">
                                                        <span v-if="details.isRaw">
                                                            {{details.englishName}}
                                                            <label style="color:red">{{ $t('Product.RawMaterial') }}</label>
                                                        </span>
                                                        <span v-else>
                                                            {{details.englishName}}
                                                        </span>
                                                    </td>

                                                    <td v-else-if="english=='true'">
                                                        <span v-if="details.isRaw">
                                                            <span v-if="details.englishName != ''">{{details.englishName}}</span>
                                                            <span v-else>{{details.arabicName}}</span>
                                                            <label style="color:red">{{ $t('Product.RawMaterial') }}</label>
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="details.englishName != ''">{{details.englishName}}</span>
                                                            <span v-else>{{details.arabicName}}</span>
                                                        </span>
                                                    </td>
                                                    <td v-if="english=='true' && isOtherLang()">
                                                        <span v-if="details.isRaw">
                                                            {{details.arabicName}}
                                                            <label style="color:red">{{ $t('Product.RawMaterial') }}</label>
                                                        </span>
                                                        <span v-else>
                                                            {{details.arabicName}}
                                                        </span>
                                                    </td>
                                                    <td v-else-if="isOtherLang()">
                                                        <span v-if="details.isRaw">
                                                            <span v-if="details.arabicName != ''">{{details.arabicName}}</span>
                                                            <span v-if="details.arabicName != ''">{{details.arabicName}}</span>
                                                            <span v-else>{{details.englishName}}</span>
                                                            <label style="color:red">{{ $t('Product.RawMaterial') }}</label>
                                                        </span>
                                                        <span v-else>
                                                            <span v-if="details.arabicName != ''">{{details.arabicName}}</span>
                                                            <span v-else>{{details.englishName}}</span>
                                                        </span>
                                                    </td>

                                                    <td class="text-center">{{details.description}}</td>
                                                    <td class="text-center">{{details.salePrice}}</td>
                                                    <!--<td class="text-center">{{details.purchasePrice==null? 0: (details.purchasePrice.price)}}</td>-->

                                                    <td class="text-center" v-if="isValid('CanViewCurrentStock')">
                                                        {{details.inventory==null? 0: details.inventory.currentQuantity}} <br />
                                                        <div class="badge badge-danger" v-if="details.stockLevel< (details.inventory==null? 0: details.inventory.currentQuantity)? '' : 'higlight'">{{ $t('Product.LowStock') }}</div>
                                                    </td>
                                                    <!--<td class="text-center">{{details.inventory==null? 0: details.inventory.currentStockValue}}</td>-->

                                                    <td class="text-center" v-if="isValid('CanViewDetailItem')">
                                                        <button value="View" class="btn btn-icon btn-sm  btn-primary mr-1" v-on:click="ViewProductInfo(details.id)"><i class="fas fa-eye"></i></button>
                                                        <!--<a href="javascript:void(0)" title="A4" class="btn  btn-icon btn-primary btn-sm" v-on:click="PrintProductInfo(details.id)"><i class=" fa fa-print"></i></a>-->
                                                    </td>

                                                    <!--<td>{{details.length}}</td>
                                                    <td>{{details.saleReturnDays}}</td>-->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="float-left">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="float-right">
                            <div class="overflow-auto" v-on:click="GetProductData()" v-if="($i18n.locale == 'en' ||isLeftToRight())">
                                <b-pagination pills size="lg" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              first-text="First"
                                              prev-text="Previous"
                                              next-text="Next"
                                              last-text="Last"></b-pagination>
                            </div>
                            <div class="overflow-auto" v-on:click="GetProductData()" v-else>
                                <b-pagination pills size="lg" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              first-text="الأولى"
                                              prev-text="السابقة"
                                              next-text="التالية"
                                              last-text="الأخيرة"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <productfiltermodel :show="show"
                                    v-if="show"
                                    @close="show=false" />
            </div>
        </div>
        <productPrintReport v-if="showPrint" :printDetails="productData" :headerFooter="headerFooter" v-bind:key="printRender"></productPrintReport>
    </div>
    <div v-else>  <acessdenied></acessdenied></div>
</template>
<style scoped>
    .higlight {
        background-color: red;
    }
</style>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        name: 'product',
        data: function () {
            return {
                arabic: '',
                english: '',
                show: false,
                type: '',
                showPrint: false,
                productData: '',
                productList: [

                ],
                warehouseId: '00000000-0000-0000-0000-000000000000',

                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                searchQuery: '',
                image: '',
                loading: true,
                renderedImage: 0,
                advanceFilters: false,
                headerFooter: {
                    company: '',

                },
                printRender: 0
            }
        },
        watch: {
            search: function (val) {
                this.GetProductData(val, 1);
            },

        },
        methods: {
            GetHeaderDetail: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get("/Company/GetCompanyDetail?id=" + localStorage.getItem('CompanyID'), { headers: { Authorization: `Bearer ${token}` }, })
                    .then(function (response) {
                        if (response.data != null) {
                            root.headerFooter.company = response.data;
                        }
                    });
            },
            OpenProductModel: function () {
                this.show = !this.show;
            },
            FilterWareHouse: function () {

                this.advanceFilters = !this.advanceFilters;
                if (this.advanceFilters == false) {
                    this.warehouseId = '00000000-0000-0000-0000-000000000000';
                }
                this.currentPage = 1;
                this.GetProductData(this.search, 1, this.warehouseId);

            },
            ImportDataFromCsv: function () {
                var root = this;
                root.$router.push({
                    path: '/ImportExportRecords',
                    query: {data:'Item'}
                })
            },

            getPage: function () {


                this.GetProductData(this.search, this.currentPage, this.warehouseId);
            },
            GetProductData: function () {
                if (localStorage.getItem("BarcodeScan") != 'Product')
                    return
                var root = this;

                

                var url = '/Product/GetProductInformation?searchTerm=' + this.search + '&pageNumber=' + this.currentPage + '&wareHouseId=' + this.warehouseId;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        console.log(response.data.results.products);
                        root.productList = response.data.results.products;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            AddProduct: function () {
                var root = this;
                root.$router.push({
                    path: '/addproduct',
                    query: {
                        prop: Math.ceil(root.rowCount / 10)
                    }
                })
            },
            EditProductInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/ProductDetailsViaId?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$router.push({
                            path: '/addproduct',
                            query: {
                                data: response.data,
                                prop: root.currentPage
                            }
                        })
                    }
                });
            },
            PrintProductInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/ProductViewDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.productData = response.data;
                        root.showPrint = true;
                        root.printRender++

                    }
                });
            },
            ViewProductInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Product/ProductViewDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$router.push({
                            path: '/ProductView',
                            query: {
                                data: response.data,
                                prop: root.currentPage,

                            }
                        })
                    }
                });
            },
        },

        created: function () {
            var root = this;
            //For Scanner Code 
            var barcode = '';
            var interval;
            document.addEventListener('keydown', function (evt) {
                if (interval)
                    clearInterval(interval);
                if (evt.code === 'Enter') {
                    if (barcode) {
                        root.search = barcode
                        root.GetProductData();
                    }
                    barcode = '';
                    return;

                }
                if (evt.key !== 'Shift')
                    barcode += evt.key;
            });

            //End
            
            this.$emit('input', this.$route.name);
            localStorage.setItem("BarcodeScan", 'Product')
        },
        mounted: function () {
            this.GetHeaderDetail();
            this.showPrint = false;
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.$route.query.data != undefined) {
                this.currentPage = this.$route.query.data;
                this.GetProductData();
            }
            else {
                this.GetProductData(this.search, 1);
            }
        }
    }
</script>