<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" :disabled="disabled" v-bind:placeholder="$t('SizeDropdown.SelectSize')" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <!--<p slot="noResult" class="text-danger"> Oops! No Size found.</p>-->
            <span slot="noResult" class="btn btn-primary " v-on:click="AddSize('Add')" v-if=" isValid('CanAddSize')">{{ $t('SizeDropdown.AddProductSize') }}</span><br />
        </multiselect>
        <modal :show="show" v-if="show">

        <div style="margin-bottom:0px" class="card">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title" id="myModalLabel"> Update Size</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title" id="myModalLabel"> {{ $t('SizeDropdown.AddProductSize') }}</h5>

                        </div>
                        <div class="">
                            <div class="card-body">
                                <div class="row ">
                                    <div :key="render" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.size.code.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('SizeDropdown.Code') }}:<span class="LabelColour"> *</span></label>
                                        <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.size.code.$model" type="text" />
                                        <span v-if="$v.size.code.$error" class="error">
                                            <span v-if="!$v.size.code.maxLength">{{ $t('SizeDropdown.CodeLength') }}</span>
                                        </span>
                                    </div>
                                    <div v-if="english=='true'" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.size.name.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('SizeDropdown.SizeName') |englishLanguage}}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.size.name.$model" type="text" />
                                        <span v-if="$v.size.name.$error" class="error">
                                            <span v-if="!$v.size.name.required">{{ $t('SizeDropdown.NameRequired') }}</span>
                                            <span v-if="!$v.size.name.maxLength">{{ $t('SizeDropdown.NameLength') }}</span>
                                        </span>
                                    </div>
                                    <div v-if="isOtherLang()" class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.size.nameArabic.$error} ">
                                        <label class="text  font-weight-bolder"> {{ $t('SizeDropdown.SizeNameAr') |arabicLanguage}}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control  " v-bind:class="isLeftToRight() ? 'text-left' : 'arabicLanguage'"  v-model="$v.size.nameArabic.$model" type="text" />
                                        <span v-if="$v.size.nameArabic.$error" class="error">
                                            <span v-if="!$v.size.nameArabic.required"> {{ $t('SizeDropdown.NameRequired') }}</span>
                                            <span v-if="!$v.size.nameArabic.maxLength">{{ $t('SizeDropdown.NameLength') }}</span>
                                        </span>
                                    </div>


                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.size.description.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('SizeDropdown.Description') }}: </label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="$v.size.description.$model" type="text" />
                                        <span v-if="$v.size.description.$error" class="error">{{ $t('SizeDropdown.descriptionLength') }}</span>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label style="margin: 7px;">{{ $t('SizeDropdown.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': size.isActive, 'bootstrap-switch-off': !size.isActive}" v-on:click="size.isActive = !size.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit'">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveSize" v-bind:disabled="$v.size.$invalid"> Update</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">Cancel</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-else>
                                <button type="button" class="btn btn-primary  " v-on:click="SaveSize" v-bind:disabled="$v.size.$invalid"> {{ $t('SizeDropdown.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('SizeDropdown.btnClear') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'
    import { maxLength, requiredIf } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'sizedropdown',
        props: ["values", 'disabled'],
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                options: [],
                value: '',
                show: false,
                type: '',
                size: {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                },
                render: 0,
                loading: false,
            }
        },
        validations: {
            size: {
                name: {
                    maxLength: maxLength(50)
                },
                nameArabic: {
                    required: requiredIf((x) => {
                        if (x.name == '' || x.name == null)
                            return true;
                        return false;
                    }),
                    maxLength: maxLength(50)
                },
                code: {
                    maxLength: maxLength(30)
                },
                description: {
                    maxLength: maxLength(200)
                }
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.get('/Product/SizeList?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                    
                    if (response.data != null) {
                        response.data.results.sizes.forEach(function (cat) {
                            
                            root.options.push({
                                id: cat.id,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' && cat.name != null) ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic : (cat.nameArabic != '' && cat.nameArabic != null) ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name
                            })
                       })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                });
            },
            AddSize: function (type) {
                this.$v.$reset();
                this.GetAutoCodeGenerator();
                this.size = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    isActive: true
                }

                this.show = !this.show;
                this.type = type;
            },
            close: function () {
                this.show = false;
            },
            GetAutoCodeGenerator: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Product/SizeCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.size.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveSize: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveSize', this.size, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                    if (response.data.isSuccess == true) {
                        if (root.type != "Edit") {
                            
                            root.$store.state.sizeList.push({
                                id: response.data.size.id,
                                name: response.data.size.name,
                                nameArabic: response.data.size.nameArabic,
                                description: response.data.size.description,
                                code: response.data.size.code,
                            })
                            root.options.push({
                                id: response.data.size.id,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (response.data.size.name != '' && response.data.size.name != null) ? response.data.size.code + ' ' + response.data.size.name : response.data.size.code + ' ' + response.data.size.nameArabic : (response.data.size.nameArabic != '' && response.data.size.nameArabic != null) ? response.data.size.code + ' ' + response.data.size.nameArabic : response.data.size.code + ' ' + response.data.size.name,
                                nameArabic: response.data.size.nameArabic,
                                description: response.data.size.description,
                                code: response.data.size.code,
                            })
                            
                            root.show = false;
                            root.$swal({
                                icon: 'success',
                                title: 'Saved Successfully!',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            var data = root.$store.state.sizeList.find(function (x) {
                                return x.id == response.data.size.id;
                            });
                            data.id = response.data.size.id;
                            data.name = (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (response.data.size.name != '' && response.data.size.name != null) ? response.data.size.code + ' ' + response.data.size.name : response.data.size.code + ' ' + response.data.size.nameArabic : (response.data.size.nameArabic != '' && response.data.size.nameArabic != null) ? response.data.size.code + ' ' + response.data.size.nameArabic : response.data.size.code + ' ' + response.data.size.name,
                            data.nameArabic = response.data.size.nameArabic;
                            data.description = response.data.size.description;
                            data.code = response.data.size.code;
                            root.show = false;
                        }
                    }
                    else {
                        root.$swal({
                            title: "Error!",
                            text: "Your Size Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 800,
                            timerProgressBar: true,
                        });
                    }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            }
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value.id);
                }
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.getData();
        },
    }
</script>