﻿<template>
    <div class="container-fluid login_main" v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? '' : 'direction: rtl;'" v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-left' : 'arabicLanguage'">

        <div class="row" style="height:100vh;">
            <div id="fadeshow1" class="col-sm-6 col-lg-6  col-md-6 backgroundColorlightBlue">
                <div class="row" style="height:20vh;padding-top: 35px;">
                    <div class="col-12" style="margin-bottom:0px !important;">
                        <h6 class="nobleHeading " v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'padding-left: 40px;margin-bottom: 3px;' : 'padding-right: 40px !important;margin-bottom: 3px;text-align:right'">
                            {{heading}}
                        </h6>
                        <p class="lightParagraph" v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'padding-left: 40px;' : 'padding-right: 40px;text-align:right'">
                            {{description }}
                        </p>
                    </div>
                    <div class="col-12" style="margin-top:18px">

                    </div>
                </div>
                <div class="row" style="height:70vh; margin-top:5vh;margin-bottom:10vh;">
                    <div class="rounded mx-auto d-block">
                        <img src="/invoiceImage.png" style="height:60vh;" />
                    </div>
                </div>
                <div style="position: fixed; bottom: 40px; " v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'left: 40px;' : 'right: 40px;'">
                    <img src="/VatScreen.png" />
                </div>

                <p style=" position: absolute; bottom: 40px; line-height:1;" v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-align: right;right: 15px;' : 'text-align: left;left: 15px;'">
                    <span class="headingOfVersion">VERSION 1.2.9.5 Last Updated Sep 12, 2022</span><br />
                    <span style="font-weight:bold;font-size:12px;">{{companyName}}</span>
                </p>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 " style="background-color:white;align-self: center;margin-top:-30px;">


                <ul class="navbar-nav text-right" style="position: fixed; top: 15px;z-index:1;" v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? ' right: 20px;' : ' left: 20px;'">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle  pr-3" style="font-size:16px;" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-globe"></i>
                        </a>
                        <div class="dropdown-menu" v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'dropdown-menu-right' : 'dropdown-menu-left'" aria-labelledby="navbarDropdownMenuLink">
                            <a class="dropdown-item" v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-left' : 'text-right'" @click="setLocale('en')">English</a>
                            <a class="dropdown-item" v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-left' : 'text-right'" style="font-size: 0.8571em;  font-weight: 700" @click="setLocale('ar')">الـعــربـيـة</a>
                        </div>
                    </li>
                </ul>


                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">

                        <div v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'login_div' : 'login_div-right'">
                            <div>

                                <img src="NobleLogin.png" style="width:auto;height:90px;">
                            </div>
                            <div class=" lightParagraph2" style="margin-top:50px">
                                <p>{{ $t('login.WelcomePleaselogintoyouraccount') }}</p>
                            </div>
                            <div class="form-group" style="margin-top:0px">
                                <label class="labelHeading font-weight-bold" style="margin-top:5px"> {{ $t('login.UsernameorEmail') }}</label>
                                <input class="form-control inputLength" :placeholder="$t('login.Enteryourusernameoremail')" id="email" @keyup.enter="userlogin" v-model="$v.login.email.$model" name="email" type="text" v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-left' : 'arabicLanguage'" />
                            </div>
                            <div class="form-group" style="margin-top:25px">
                                <label class="labelHeading font-weight-bold" style="margin-top:0px">  {{ $t('login.RegisterUser_Password') }}</label>
                                <input class="form-control inputLength" :placeholder="$t('login.EnteryourPassword')" id="email" @keyup.enter="userlogin" v-model="$v.login.password.$model" name="password" :type="password" v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-left' : 'arabicLanguage'" />
                                <span style="position:absolute;  top:43px;" v-bind:style="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'right:9px;' : 'left:9px;'">

                                    <span @click="showPassword" style="color:#D3D7DF">
                                        <i style="font-size: 18px; color: #50565F;" v-bind:class="eyeValue?  'fa fa-eye-slash' : 'fas fa-eye'"></i>
                                    </span>
                                </span>
                            </div>

                            <div class="row" style="margin-top:24px;">
                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                    <div class="form-group" style="display:inline-flex;">
                                        <input style="width: 20px; height: 20px;" v-model="login.rememberMe" type="checkbox" />
                                        <span v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'RememberMe' : 'RememberMeRight' ">{{ $t('login.Rememberme') }}</span>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 " v-bind:class="($i18n.locale == 'en' ||$i18n.locale == 'pt') ? 'text-right' : 'text-left' ">
                                    <a href="javascript:void(0)" style="color: #3178F6; " v-on:click="openmodel">{{ $t('login.ForgotPassword?') }}</a>
                                </div>
                            </div>
                            <div class="row" style="margin-top:35px;">
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <button v-if="!loading" type="button" class="btn btn-block btn-primary" v-on:click="userlogin" v-bind:disabled="$v.login.$invalid">
                                        <span>{{ $t('login.Login1') }} <i class="fas fa-arrow-right pl-2"></i></span>
                                    </button>
                                    <button v-else type="button" class="btn btn-block btn-primary" disabled>
                                        <img src="/images/loading.gif" style="height:15px;" />
                                    </button>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <p v-if="customError != ''" class="text-left text-danger">{{ customError }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<loading :active.sync="loading" :can-cancel="false" ></loading>-->

            </div>


        </div>
        <forgotPassword :show="show"
                        v-if="show"
                        @close="show = false" />
    </div>
</template>
<script>

    import { required } from 'vuelidate/lib/validators';

    export default {

        data() {
            return {
                langs: ['en', 'ar'],
                login: {
                    email: '',
                    password: '',
                    rememberMe: true
                },
                password: "password",
                eyeValue: false,
                roles: [],
                open: false,
                website: 'https://www.techqode.com/',
                error: '',
                show: false,
                customError: '',
                loading: false,
                isEmployee: true,
                isAccount: false,
                IsProceed: false,
                heading: '',
                description: '',
                companyName: '',
                applicationName: '',
                email: '',
                favName: '',
                terms: false,
                os: '',
                loginHistory: {
                    userId: '',
                    isLogin: true,
                    operatingSystem: '',
                    companyId: ''
                },
                isPayment: false,
                expirationMsg: '',
            }
        },
        validations: {
            login:
            {
                email:
                {
                    required
                },
                password:
                {
                    required
                }
            }
        },
        mounted() {
            this.getOs();
        },
        methods: {
            
            isLeftToRight: function () {
                console.log('dsfsdfs')
            },
            
            showPassword() {
                if (this.password === "password") {
                    this.password = "text";
                    this.eyeValue = true;
                }
                else {
                    this.password = "password"
                    this.eyeValue = false;
                }

            },
            hidepassword() {
                this.password = "password"
                this.eyeValue = false;
            },
            setLocale(locale) {

                this.$i18n.locale = locale
                localStorage.setItem('locales', locale);
            },

            GetCurrency: function () {

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Account/GetCompanySetupAccount', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        localStorage.setItem('currency', response.data.currency);
                        localStorage.setItem('taxMethod', response.data.taxMethod);
                        localStorage.setItem('TaxRateId', response.data.taxRateId);
                    }
                });
            },
            GetData: function () {

                if (this.IsProceed == false) {



                    this.isAccount = true;
                    localStorage.setItem("isAccount", this.isAccount);
                    if (this.terms == true) {
                        this.$router.push('/setup');

                    }
                    else {
                        this.$router.push('/TermsAndConditions');

                    }
                }

                else {

                    this.$router.push('/StartScreen');
                }


            },
            GetModuleWiseClaims: function () {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/GetModuleWiseClaims', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        response.data.forEach(function (x) {
                            localStorage.setItem(x.tokenName + '_token', x.token)
                        })
                    }
                });
            },
            GetCompanyPermission: function (name, isTouch, dayStart) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Company/GetCompanyPermission', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        if (response.data.length == 0) {
                            root.$router.push({
                                path: '/NotPermission',
                                query: { data: 'You do not have any permission. Please contact to support.' }
                            });
                        }
                        else {
                            localStorage.setItem("permission", JSON.stringify(response.data));
                            localStorage.setItem('LimitedCustomer', 0);
                            localStorage.setItem('LimitedSupplier', 0);

                            localStorage.setItem('CashVoucher', false);
                            localStorage.setItem('isForMedical', false);
                            localStorage.setItem('InvoiceWoInventory', false);
                            localStorage.setItem('SoInventoryReserve', false);
                            localStorage.setItem('b2c', false);
                            localStorage.setItem('b2b', false);
                            localStorage.setItem('ExpenseToGst', false);
                            localStorage.setItem('AutoPurchaseVoucher', false);
                            localStorage.setItem('PartiallyPurchase', false);
                            localStorage.setItem('InternationalPurchase', false);
                            localStorage.setItem('coaCode', false);
                            localStorage.setItem('IsExpenseAccount', false);
                            localStorage.setItem('DayStart', false);
                            localStorage.setItem('IsOpenDay', false);
                            localStorage.setItem('IsTransferAllow', false);
                            localStorage.setItem('IsMultiUnit', false);
                            localStorage.setItem('IsArea', false);
                            localStorage.setItem('English', false);
                            localStorage.setItem('Arabic', false);
                            localStorage.setItem('Portugues', false);
                            localStorage.setItem('LeftToRight', false);
                            localStorage.setItem('SuperAdminDayStart', false);
                            localStorage.setItem('BankDetail', false);
                            localStorage.setItem('decimalQuantity', false);
                            localStorage.setItem('expenseAmount', false);
                            localStorage.setItem('IsProduction', false);
                            localStorage.setItem('IsSerial', false);
                            localStorage.setItem('fIFO', false);
                            localStorage.setItem('ChequeDateRequired', false);
                            localStorage.setItem('ValidityDateAuto', false);
                            localStorage.setItem('CanViewUnitPerPack', false);
                            localStorage.setItem('ServicePurchase', false);
                            localStorage.setItem('ColorVariants', false);
                            localStorage.setItem('VatSelectOnSale', true);
                            //localStorage.setItem('DefaultVat', 'DefaultVat');
                            //localStorage.setItem('DefaultVat', 'DefaultVatHead');
                            //localStorage.setItem('DefaultVat', 'DefaultVatItem');
                            /*localStorage.setItem('DefaultVat', 'DefaultVatHeadItem');*/
                            //localStorage.setItem('SaleDefaultVat', 'DefaultVat');
                            //localStorage.setItem('SaleDefaultVat', 'DefaultVatHead');
                            /*localStorage.setItem('SaleDefaultVat', 'DefaultVatItem');*/
                            //localStorage.setItem('SaleDefaultVat', 'DefaultVatHeadItem');

                            localStorage.setItem('VatSelectOnSale', false);
                            localStorage.setItem('ImportExportSale', false);
                            response.data.forEach(function (x) {

                                if (x.moduleDescription === '3a0a2ddf-0773-4746-913d-0ef1397cc14f') {
                                    switch (x.permissionCategory) {
                                        case 'CashVoucher':
                                            localStorage.setItem('CashVoucher', true);
                                            break;
                                        case 'Medical':
                                            localStorage.setItem('isForMedical', true);
                                            break;
                                        case 'InvoiceWoInventory':
                                            localStorage.setItem('InvoiceWoInventory', true);
                                            break;
                                        case 'SaleOrderInventoryReserve':
                                            localStorage.setItem('SoInventoryReserve', true);
                                            break;
                                        case 'B2B':
                                            localStorage.setItem('b2b', true);
                                            break;
                                        case 'B2C':
                                            localStorage.setItem('b2c', true);
                                            break;
                                        case 'ExpenseToGST':
                                            localStorage.setItem('ExpenseToGst', true);
                                            break;
                                        case 'AutoPurchaseVoucher':
                                            localStorage.setItem('AutoPurchaseVoucher', true);
                                            break;
                                        case 'PartiallyPurchase':
                                            localStorage.setItem('PartiallyPurchase', true);
                                            break;
                                        case 'InternationalPurchase':
                                            localStorage.setItem('InternationalPurchase', true);
                                            break;
                                        case 'CoaAutoCode':
                                            localStorage.setItem('coaCode', true);
                                            break;
                                        case 'ExpenseWithAccount':
                                            localStorage.setItem('IsExpenseAccount', true);
                                            break;
                                        case 'CanStartDay':
                                            localStorage.setItem('DayStart', true);
                                            break;
                                        case 'IsOpenDay':
                                            localStorage.setItem('IsOpenDay', true);
                                            break;
                                        case 'CanTransferCounter':
                                            localStorage.setItem('IsTransferAllow', true);
                                            break;
                                        case 'MultiUnit':
                                            localStorage.setItem('IsMultiUnit', true);
                                            break;
                                        case 'IsArea':
                                            localStorage.setItem('IsArea', true);
                                            break;
                                        case 'English':
                                            localStorage.setItem('English', true);
                                            //localStorage.setItem('locales', 'en');
                                            break;
                                        case 'Arabic':
                                            localStorage.setItem('Arabic', true);
                                            //localStorage.setItem('locales', 'ar');
                                            break;
                                        case 'Portugues':
                                            localStorage.setItem('Portugues', true);
                                            break;
                                        case 'LeftToRight':
                                            localStorage.setItem('LeftToRight', true);
                                            break;
                                        case 'Terminal':
                                            //localStorage.setItem('BankDetail', true);
                                            break;
                                        case 'AdminDayStart':
                                            localStorage.setItem('SuperAdminDayStart', true);
                                            break;
                                        case 'BankDetail':
                                            localStorage.setItem('BankDetail', true);
                                            break;
                                        case 'DecimalQuantity':
                                            localStorage.setItem('decimalQuantity', true);
                                            break;
                                        case 'ExpenseAmountExceed':
                                            localStorage.setItem('expenseAmount', true);
                                            break;
                                        case 'Production':
                                            localStorage.setItem('IsProduction', true);
                                            break;
                                        case 'فاتورة ضريبية':
                                            localStorage.setItem('taxInvoiceLabelAr', 'فاتورة ضريبية');
                                            break;
                                        case 'Tax Invoice':
                                            localStorage.setItem('taxInvoiceLabel', 'Tax Invoice');
                                            break;
                                        case 'فاتورة ضريبية مبسطة':
                                            localStorage.setItem('simplifyTaxInvoiceLabelAr', 'فاتورة ضريبية مبسطة');
                                            break;
                                        case 'Simplified Tax Invoice':
                                            localStorage.setItem('simplifyTaxInvoiceLabel', 'Simplified Tax Invoice');
                                            break;
                                        case 'IsSerial':
                                            localStorage.setItem('IsSerial', true);
                                            break;
                                        case 'IsFifo':
                                            localStorage.setItem('fIFO', true);
                                            break;
                                        case 'ChequeDateRequired':
                                            localStorage.setItem('ChequeDateRequired', true);
                                            break;
                                        case 'ValidityDateAuto':
                                            localStorage.setItem('ValidityDateAuto', true);
                                            break;
                                        case 'CanViewUnitPerPack':
                                            localStorage.setItem('CanViewUnitPerPack', true);
                                            break;
                                        case 'ImportExportSale':
                                            localStorage.setItem('ImportExportSale', true);
                                            break;


                                        case 'VatSelectOnSale':
                                            localStorage.setItem('VatSelectOnSale', true);
                                            break;
                                        case 'DefaultSaleVat':
                                            localStorage.setItem('SaleDefaultVat', 'DefaultVat');
                                            break;
                                        case 'DefaultSaleVatHead':
                                            localStorage.setItem('SaleDefaultVat', 'DefaultVatHead');
                                            break;
                                        case 'DefaultSaleVatItem':
                                            localStorage.setItem('SaleDefaultVat', 'DefaultVatItem');
                                            break;
                                        case 'DefaultSaleVatHeadItem':
                                            localStorage.setItem('SaleDefaultVat', 'DefaultVatHeadItem');
                                            break;
                                        case 'DefaultPurchaseVat':
                                            localStorage.setItem('DefaultVat', 'DefaultVat');
                                            break;
                                        case 'DefaultPurchaseVatHead':
                                            localStorage.setItem('DefaultVat', 'DefaultVatHead');
                                            break;
                                        case 'DefaultPurchaseVatItem':
                                            localStorage.setItem('DefaultVat', 'DefaultVatItem');
                                            break;
                                        case 'DefaultPurchaseVatHeadItem':
                                            localStorage.setItem('DefaultVat', 'DefaultVatHeadItem');
                                            break;

                                    }
                                    if (x.key === '8cb9768b-f76c-4614-a8a8-c22c7f1a0c81') {

                                        localStorage.setItem('openBatch', x.permissionCategory);
                                    }
                                }
                                else if (x.key === '7dc50e60-d5a2-419a-b12a-200ac71d7cb6') {
                                    localStorage.setItem('LimitedCustomer', x.permissionCategory);
                                }
                                else if (x.key === '3d1f65f1-3f72-4898-a175-1b6ab42b2b9d') {
                                    localStorage.setItem('LimitedSupplier', x.permissionCategory);
                                }
                            })

                            var isEnglish = response.data.findIndex(x => x.moduleDescription === '3a0a2ddf-0773-4746-913d-0ef1397cc14f' && x.permissionCategory === 'English')
                            var isArabic = response.data.findIndex(x => x.moduleDescription === '3a0a2ddf-0773-4746-913d-0ef1397cc14f' && x.permissionCategory === 'Arabic')

                            var getLocale = localStorage.getItem('locales');

                            localStorage.setItem('CompanyName', root.companyName);
                            localStorage.setItem('ApplicationName', root.applicationName);
                            localStorage.setItem('favName', root.favName);
                            localStorage.setItem('email', root.email);

                            if (isEnglish >= 0 && isArabic < 0) {
                                //localStorage.setItem('locales', 'ar');
                                localStorage.setItem('locales', 'en');
                            }
                            else if (isEnglish < 0 && isArabic >= 0) {
                                localStorage.setItem('locales', 'ar');
                            }
                            else {
                                if (getLocale == 'ar') {
                                    localStorage.setItem('locales', 'ar');
                                }
                                else {
                                    localStorage.setItem('locales', 'en');
                                }    
                            }
                            if (root.isPayment) {
                                root.$swal.fire(
                                    {
                                        toast: true,
                                        position: 'bottom-end',
                                        showConfirmButton: false,
                                        timer: 10000,
                                        timerProgressBar: true,
                                        icon: 'error',
                                        title: root.expirationMsg,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', root.$swal.stopTimer)
                                            toast.addEventListener('mouseleave', root.$swal.resumeTimer)
                                        }
                                    });
                            }
                            else {
                                root.$swal.fire(
                                    {
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 1500,
                                        timerProgressBar: true,
                                        icon: 'success',
                                        title: 'Logged in Successfully',
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', root.$swal.stopTimer)
                                            toast.addEventListener('mouseleave', root.$swal.resumeTimer)
                                        }
                                    });
                            }

                            if (root.isEmployee == false) {


                                root.GetData();
                            }
                            else {
                                if (name == 'Sales Man') {
                                    if (dayStart == false) {
                                        if (localStorage.getItem("BankDetail") == "true")
                                            root.$router.push('/WholeSaleDay');
                                        else
                                            root.$router.push('/dayStart');
                                    }
                                    else {
                                        if (isTouch == 'Touch Invoice') {
                                            root.$router.push('/TouchScreen');
                                        }
                                        else if (isTouch == 'Invoice Barcode') {
                                            root.$router.push('/InvoiceBarcode');
                                        }
                                        else if (isTouch == 'Invoice Barcode Item') {
                                            root.$router.push('/InvoiceBarCodeItem');
                                        }
                                        else {
                                            root.$router.push('/TouchScreen');
                                        }
                                    }
                                }
                                else {

                                    root.$router.push('/StartScreen');

                                }
                            }


                        }
                    }
                });
            },
            GetUserPermission: function (x) {

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Account/GetUserPermission?id=' + x, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        localStorage.setItem('changePriceDuringSale', response.data.changePriceDuringSale);
                        localStorage.setItem('giveDicountDuringSale', response.data.giveDicountDuringSale);
                        //    localStorage.setItem('InvoiceWoInventoryUser', response.data.invoiceWoInventory);
                    }
                });
            },
            gotoWebSite: function () {
                window.location.href = this.website;
            },
            openmodel: function () {
                this.show = !this.show;
            },
            loginHistorySave: function () {

                this.$https.post('/account/LoginHistory', this.loginHistory).then(function (response) {
                    if (response.data == 1)
                        console.log('Logout History save done');
                    else
                        console.log('Logout History not save due to some error ' + response.data);
                });
            },
            userlogin: function () {
                var root = this;
                root.customError = '';
                root.loading = true;

                var url = '/account/login';

                this.$https.post(url, this.login).then(function (response) {


                    if (response.data == true) {
                        root.$router.push({
                            path: '/Authenticator',
                            query: { data: root.login }
                        })

                    }
                    else {
                        document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
                        //root.clearCookie()
                        //if (root.login.rememberMe) {
                        //    root.setCookie();
                        //} else {
                        //    document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
                        //}
                        if (response.data.companyId == "00000000-0000-0000-0000-000000000000") {

                            root.loading = false;
                            root.customError = 'Invalid Login Attempt.';
                            //    root.$swal.fire(
                            //        {
                            //            icon: 'error',
                            //            title: 'Email or Password is Incorrect!',
                            //            text: root.customError,

                            //            showConfirmButton: false,
                            //            timer: 5000,
                            //            timerProgressBar: true,
                            //        });
                        }
                        else if (response.data.isNotPayment) {
                            root.$session.start();
                            localStorage.setItem('CompanyID', response.data.companyId)
                            localStorage.setItem('token', response.data.token);
                            root.$router.push({
                                path: '/NotPermission',
                                query: { data: response.data.expiration }
                            });
                        }
                        else if (response.data.expiration != "" && response.data.expiration != null && response.data.isNotPayment == false && response.data.isPayment == false) {
                            root.$session.start();
                            localStorage.setItem('CompanyID', response.data.companyId)
                            localStorage.setItem('token', response.data.token);
                            root.$router.push({
                                path: '/NotPermission',
                                query: { data: response.data.expiration }
                            });
                        }
                        else {
                            root.loginHistory.userId = response.data.userId;
                            root.loginHistory.companyId = response.data.companyId;
                            root.loginHistorySave();
                            root.loading = false;

                            root.isPayment = response.data.isPayment
                            root.expirationMsg = response.data.expiration
                            
                            var getLocale = localStorage.getItem('locales');
                            root.$session.start();
                            localStorage.clear();

                            localStorage.setItem('locales', getLocale);

                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('UserName', response.data.userName);
                            localStorage.setItem('overWrite', response.data.overWrite);
                            localStorage.setItem('LoginUserName', response.data.loginUserName);
                            localStorage.setItem('RoleName', response.data.roleName);
                            localStorage.setItem('ImagePath', response.data.imagePath);
                            localStorage.setItem('CompanyID', response.data.companyId);
                            localStorage.setItem('UserID', response.data.userId);
                            localStorage.setItem('FullName', response.data.fullName);
                            localStorage.setItem('DayStartTime', response.data.dayStartTime);
                            localStorage.setItem('PrinterName', response.data.printerName);
                            localStorage.setItem('IsHeaderFooter', response.data.isHeaderFooter);

                            //localStorage.setItem('SimpleInvoice', response.data.simpleInvoice);

                            localStorage.setItem('InvoicePrint', response.data.invoicePrint);
                            //localStorage.setItem('PurchaseOrder', response.data.purchaseOrder);

                            //localStorage.setItem('VersionAllow', response.data.versionAllow);





                            //localStorage.setItem('IsProduction', response.data.isProduction);

                            /*PrintHeaderDetail*/
                            localStorage.setItem('PrintTemplate', response.data.printTemplate);
                            localStorage.setItem('ReturnDays', response.data.returnDays);
                            localStorage.setItem('PrintSizeA4', response.data.printSizeA4);
                            localStorage.setItem('TermsInAr', response.data.termsInAr);
                            localStorage.setItem('TermsInEng', response.data.termsInEng);
                            localStorage.setItem('AllowAll', response.data.allowAll);

                            localStorage.setItem('IsDayStart', response.data.isDayStart);
                            localStorage.setItem('Step1', response.data.step1);
                            localStorage.setItem('Step2', response.data.step2);
                            localStorage.setItem('Step3', response.data.step3);
                            localStorage.setItem('Step4', response.data.step4);
                            localStorage.setItem('Step5', response.data.step5);
                            localStorage.setItem('CounterId', response.data.counterId);
                            localStorage.setItem('IsSupervisor', response.data.isSupervisor);
                            localStorage.setItem('TermsCondition', response.data.termsCondition);
                            localStorage.setItem('WareHouseId', response.data.warehouseId);

                            localStorage.setItem('IsPermissionToStartDay', response.data.isPermissionToStartDay);
                            localStorage.setItem('IsDailyExpense', response.data.isExpenseAccount);
                            localStorage.setItem('IsPermissionToCloseDay', response.data.isPermissionToCloseDay);
                            //localStorage.setItem('IsMasterProductPermission', response.data.isMasterProductPermission);
                            localStorage.setItem('NobleRole', response.data.nobleRole);

                            localStorage.setItem('BusinessLogo', response.data.businessLogo);
                            localStorage.setItem('BusinessNameArabic', response.data.businessNameArabic);
                            localStorage.setItem('BusinessNameEnglish', response.data.businessNameEnglish);
                            localStorage.setItem('BusinessTypeArabic', response.data.businessTypeArabic);
                            localStorage.setItem('BusinessTypeEnglish', response.data.businessTypeEnglish);
                            localStorage.setItem('CompanyNameArabic', response.data.companyNameArabic);
                            localStorage.setItem('CompanyNameEnglish', response.data.companyNameEnglish);

                            root.terms = response.data.termsCondition;
                            localStorage.setItem('IsExpenseDay', response.data.isExpenseDay);
                            localStorage.setItem('TransferCounter', response.data.transferCounter);
                            /*PrintHeaderDetail End*/

                            localStorage.setItem('IsBlindPrint', response.data.isBlindPrint);
                            localStorage.setItem('AutoPaymentVoucher', response.data.autoPaymentVoucher);
                            localStorage.setItem('IsDeliveryNote', response.data.isDeliveryNote);
                            localStorage.setItem('SalesMan', response.data.userRoleName);
                            localStorage.setItem('OnPageLoadItem', response.data.onPageLoadItem);
                            localStorage.setItem('isTouchInvoice', response.data.isTouchInvoice);
                            localStorage.setItem('touchScreen', response.data.touchScreen);


                            response.data.companyOptions.forEach(function (item) {
                                if (item.optionValue == 'bool') {
                                    localStorage.setItem(item.label, item.value);
                                }
                                else {
                                    localStorage.setItem(item.label, item.optionValue);
                                }
                            });
                            //localStorage.setItem("CompanyOption", JSON.stringify(response.data.companyOptions));
                            
                            
                            
                            if (response.data.phoneNo != null) {
                                localStorage.setItem('PhoneNo', response.data.phoneNo);
                                root.isEmployee = false
                            }
                            if (response.data.employeeId != null) {
                                /*   root.$router.push('/daystart')*/
                                localStorage.setItem('EmployeeId', response.data.employeeId);
                            }

                            /*iSProceed*/
                            if (response.data.isProceed) {
                                root.IsProceed = true;
                            }

                            if (response.data.roleName == 'User') {
                                root.GetCompanyPermission(response.data.userRoleName, response.data.touchScreen, response.data.isDayStart);
                                root.GetModuleWiseClaims();
                                root.GetCurrency();
                                root.GetUserPermission(response.data.userId);
                            }
                            else {
                                if (response.data.userRoleName == 'Sales Man') {

                                    if (response.data.isDayStart == false) {
                                        if (localStorage.getItem("BankDetail") == "true")
                                            root.$router.push('/WholeSaleDay');
                                        else
                                            root.$router.push('/dayStart');
                                    }
                                    else {
                                        if (response.data.touchInvoice == 'Touch Invoice') {
                                            root.$router.push('/TouchScreen');
                                        }
                                        else if (response.data.touchInvoice == 'Invoice Barcode') {
                                            root.$router.push('/InvoiceBarcode');
                                        }
                                        else if (response.data.touchInvoice == 'Invoice Barcode Item') {
                                            root.$router.push('/InvoiceBarCodeItem');
                                        }
                                        else {
                                            root.$router.push('/TouchScreen');
                                        }
                                    }
                                }
                                else {

                                    root.$router.push('/StartScreen');
                                }
                            }

                            //  root.GetUserAcessPermission(response.data.userId);

                        }
                    }

                }).catch(error => {
                    root.customError = JSON.stringify(error.response.data.error);
                    root.loading = false;
                })
            },

            getWhiteLabeling: function () {
                var root = this;
                this.$https.get('/account/GetWhiteLabeling').then(function (response) {
                    if (response.data != null) {
                        root.heading = response.data.heading
                        root.description = response.data.description
                        root.companyName = response.data.companyName
                        root.applicationName = response.data.applicationName,
                        root.favName = response.data.favName,
                        root.email = response.data.email
                    }
                });
            },
            getOs: function () {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                    os = null;

                if (macosPlatforms.indexOf(platform) !== -1) {
                    os = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    os = 'iOS';
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    os = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    os = 'Android';
                } else if (!os && /Linux/.test(platform)) {
                    os = 'Linux';
                }
                this.loginHistory.operatingSystem = os;
            }
        },
        created: function () {
            this.getWhiteLabeling()
        }
    }
</script>
<style scoped>
    @media only screen and (max-width: 768px) {
        #fadeshow1 {
            display: none;
        }

        .login_div {
            width: 100%;
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        .login_div-right {
            width: 100%;
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .nobleHeading {
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: 1px;
            text-align: left;
            color: #3B4350
        }

        .login_div {
            width: 100%;
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }

        .login_div-right {
            width: 100%;
            max-width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        .nobleHeading {
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: 1px;
            text-align: left;
            color: #3B4350
        }

        .login_div {
            width: 450px;
            max-width: 450px;
            padding-left: 40px;
        }

        .login_div-right {
            width: 450px;
            max-width: 450px;
            padding-right: 40px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1399.98px) {
        .nobleHeading {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: 1px;
            text-align: left;
            color: #3B4350
        }

        .login_div {
            width: 500px;
            max-width: 500px;
            padding-left: 60px;
        }

        .login_div-right {
            width: 500px;
            max-width: 500px;
            padding-right: 60px;
        }
    }

    @media (min-width: 1400px) {
        .nobleHeading {
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: 1px;
            text-align: left;
            color: #3B4350
        }

        .login_div {
            width: 500px;
            max-width: 500px;
            padding-left: 140px;
        }

        .login_div-right {
            width: 500px;
            max-width: 500px;
            padding-right: 140px;
        }
    }

    .RememberMe {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1px;
        margin-left: 5px;
    }

    .RememberMeRight {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1px;
        margin-right: 5px;
    }

    .headingOfVersion {
        font-size: 10px;
        font-style: normal;
        line-height: 12px;
        letter-spacing: 0em;
        font-style: normal;
        font-weight: 400 !important;
        letter-spacing: 0em;
        color: black !important;
    }

    .backgroundColorlightBlue {
        background-color: #F1F6FF;
    }

    .inputLength {
        height: 45px;
        width: 100%;
        max-width: 100%;
    }

    .labelLength {
        height: 45px;
        width: 100%;
        left: 790px;
        border-radius: 4px;
    }

    .lightParagraph {
        height: 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1px;
        color: #898D93
    }




    .labelHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: left;
        color: #50565F;
    }

    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid blue;
        border-bottom: 16px solid blue;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    .headingColor {
        color: #1e6eca !important;
        font-size: 15px;
    }

    .headingColor3 {
        color: #1e6eca !important;
        font-size: 26px;
    }

    .headingColor2 {
        color: #1e6eca !important;
        font-size: 10px;
    }

    .forgotPassword {
        color: blue;
        font-size: 16px;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .footer {
        line-height: 0px;
    }
</style>

