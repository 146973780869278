<template>
    <div class="row " v-if=" isValid('Can Edit Bundles') || isValid('Can Save Bundles') " v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-8 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" >
            <div class="card">
                <div >
                    <div class="card-header">
                        <h5 class="modal-title DayHeading " id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddBundles.Bundle') }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row ">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="{'has-danger' : $v.bundleCategory.offer.$error}">
                                <label class="text  font-weight-bolder ">{{ $t('AddBundles.NameEn') }} :<span class="LabelColour"> *</span></label>
                                <input class="form-control" v-model="$v.bundleCategory.offer.$model" type="text" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                <span v-if="$v.bundleCategory.offer.$error" class="error text-danger">
                                    <span v-if="!$v.bundleCategory.offer.required">{{ $t('AddBundles.Offer') }}</span>
                                    <span v-if="!$v.bundleCategory.offer.maxLength">{{ $t('AddBundles.OfferLength') }}</span>
                                </span>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-if="bundleCategory.id=='00000000-0000-0000-0000-000000000000'">
                                <label>{{ $t('AddBundles.ProductList') }}:<span class="LabelColour"> *</span></label>
                                <div>
                                    <product-dropdown v-model="productList" v-bind:value="bundleCategory.productId" :status="'isBundle'" :emptyselect="true" :type=false></product-dropdown>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-if="bundleCategory.id!='00000000-0000-0000-0000-000000000000'">
                                <label>{{ $t('AddBundles.ProductList') }}:<span class="LabelColour"> *</span></label>
                                <div>
                                    <input type="text" v-model='bundleCategory.name' class="form-control" disabled name="name" />
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="{'has-danger' : $v.bundleCategory.buy.$error}">
                                <label class="text  font-weight-bolder ">{{ $t('AddBundles.Buy') }} :<span class="LabelColour"> *</span></label>
                                <input class="form-control" v-model="$v.bundleCategory.buy.$model" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                <span v-if="$v.bundleCategory.buy.$error" class="error text-danger">
                                    <span v-if="!$v.bundleCategory.buy.required">{{ $t('AddBundles.Buy2') }}</span>
                                    <span v-if="!$v.bundleCategory.buy.maxLength">{{ $t('AddBundles.BuyLength') }}</span>
                                </span>
                            </div>


                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="{'has-danger' : $v.bundleCategory.get.$error}">
                                <label class="text  font-weight-bolder ">{{ $t('AddBundles.Get') }} :<span class="LabelColour"> *</span></label>
                                <input class="form-control" v-model="$v.bundleCategory.get.$model" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                <span v-if="$v.bundleCategory.get.$error" class="error text-danger">
                                    <span v-if="!$v.bundleCategory.get.required">{{ $t('AddBundles.Get2') }}</span>
                                    <span v-if="!$v.bundleCategory.get.maxLength">{{ $t('AddBundles.GetLength') }}</span>
                                </span>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="{'has-danger' : $v.bundleCategory.quantityLimit.$error}">
                                <label class="text  font-weight-bolder ">{{ $t('AddBundles.Limit') }} :<span class="LabelColour"> *</span></label>
                                <input class="form-control" v-model="$v.bundleCategory.quantityLimit.$model" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                <span v-if="$v.bundleCategory.quantityLimit.$error" class="error text-danger">
                                    <span v-if="!$v.bundleCategory.quantityLimit.required">{{ $t('AddBundles.Limit2') }}</span>
                                    <span v-if="!$v.bundleCategory.quantityLimit.maxLength">{{ $t('AddBundles.LimitLength') }}</span>
                                </span>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="{'has-danger' : $v.bundleCategory.stockLimit.$error}">
                                <label class="text  font-weight-bolder ">{{ $t('AddBundles.StockLimit') }} :<span class="LabelColour"> *</span></label>
                                <input class="form-control" v-model="$v.bundleCategory.stockLimit.$model" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                <span v-if="$v.bundleCategory.stockLimit.$error" class="error text-danger">
                                    <span v-if="!$v.bundleCategory.stockLimit.required">{{ $t('AddBundles.Limit2') }}</span>
                                    <span v-if="!$v.bundleCategory.stockLimit.maxLength">{{ $t('AddBundles.LimitLength') }}</span>
                                </span>
                            </div>

                            <div :key="dateRender+'fromDate'" class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <label>{{ $t('AddBundles.FromDate') }}:<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.bundleCategory.fromDate.$error}">
                                    <datepicker v-model="$v.bundleCategory.fromDate.$model" :key="daterander"></datepicker>
                                    <span v-if="$v.bundleCategory.fromDate.$error" class="error text-danger">
                                        <span v-if="!$v.bundleCategory.fromDate.required">From Date is Required</span>
                                    </span>
                                </div>
                            </div>
                            <div :key="dateRender+'toDate'" class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <label>{{ $t('AddBundles.ToDate') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.bundleCategory.toDate.$error}">
                                    <datepicker v-model="$v.bundleCategory.toDate.$model" :key="daterander"></datepicker>
                                    <span v-if="$v.bundleCategory.toDate.$error" class="error text-danger">
                                        <span v-if="!$v.bundleCategory.toDate.required">To Date is Required</span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'flex-direction: row-reverse;'">
                                <label style="margin: 7px;">{{ $t('AddBundles.Active') }}</label> <br />
                                <toggle-button v-model="bundleCategory.isActive" class="mt-1" color="#3178F6"/>
                            </div>
                            <div class="col-sm-12 arabicLanguage" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <button type="button" class="btn btn-success  " v-bind:disabled="$v.bundleCategory.$invalid" v-on:click="SaveOffer" v-if="bundleCategory.id=='00000000-0000-0000-0000-000000000000' && isValid('Can Save Bundles') ">{{ $t('AddBundles.btnSave') }}</button>
                                <button type="button" class="btn btn-success  " v-bind:disabled="$v.bundleCategory.$invalid" v-on:click="SaveOffer" v-if="bundleCategory.id!='00000000-0000-0000-0000-000000000000' && isValid('Can Edit Bundles') ">{{ $t('AddBundles.btnUpdate') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="Close()">{{ $t('AddBundles.btnClear') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from 'moment';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { required, maxLength } from 'vuelidate/lib/validators';

    export default ({
        mixins: [clickMixin],
        
        data: function () {
            return {
                bundleCategory: {
                    id: '00000000-0000-0000-0000-000000000000',
                    buy: '',
                    offer:'',
                    productId: [],
                    get: '',  
                    toDate: '',
                    fromDate: '',
                    quantityLimit:'',
                    stockLimit:'',
                    isActive: true
                },
                productList: '',
                listOfProduct: [],
                loading:false,
                daterander: 0,
                dateRender: 0,
                language: 'Nothing',
            }
        },
        validations: {
            bundleCategory:
            {
                 offer: {
                     required,
                     maxLength: maxLength(50)
                 },
                 
                 quantityLimit: {
                     required,
                     maxLength: maxLength(20)
                 },
                 stockLimit: {
                     required,
                     maxLength: maxLength(20)
                 },
                 buy: {
                     required,
                     maxLength: maxLength(20)},
                 get: {
                     required,
                     maxLength: maxLength(20)},
                 isActive: {},
                 toDate: { required },
                 fromDate: { required }
            },
            
             productList: { required },
        },
        methods: {
            languageChange: function (lan) {
                
                if (this.language == lan) {
                    if (this.bundleCategory.id == '00000000-0000-0000-0000-000000000000') {
                        
                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },
             Close: function() {                 
                this.$router.push('/bundles');   
            },
            getProductList: function () {
                for (var i = 0; i < this.productList.length; i++) {
                    this.listOfProduct[i] = this.productList[i].id
                }
            },
            SaveOffer: function () {
                this.loading = true;
                var root = this;
                
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.bundleCategory.id =='00000000-0000-0000-0000-000000000000') {
                    this.bundleCategory.productId.push({ id: root.productList });
                }
                root.$https
                    .post('/Product/SaveBundleCategoryItems', root.bundleCategory, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/bundles');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/bundles');
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }       
                         
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Something Went Wrong!',
                                text: error,
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });
               
                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        },
        created: function () {
            
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            if (this.$route.query.data == undefined) {                
                this.bundleCategory.fromDate = moment().format('llll');
                this.bundleCategory.toDate = moment().format('llll');
                this.daterander++;
            }
            if (this.$route.query.data != undefined) {                
                this.bundleCategory = this.$route.query.data;
                this.dateRender++;
            }
        }
    })
</script>