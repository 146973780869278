<template>
    <div v-if="isDisable">
        <multiselect v-model="DisplayValue" disabled :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" v-bind:placeholder="$t('TerminalDropDown.SelectOption')" label="name" :preselect-first="true" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <p slot="noResult" class="text-danger"> Oops! No terminal found.</p>
        </multiselect>

    </div>
    <div v-else>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" v-bind:placeholder="$t('TerminalDropDown.SelectOption')" label="name" :preselect-first="true" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <p slot="noResult" class="text-danger"> Oops! No terminal found.</p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect'
    import clickMixin from '@/Mixins/clickMixin'

    export default {
        name: 'Terminaldropdown',
        props: ["values", 'isAllSelected', 'isDayStart', 'isDisable', 'isSelect', 'terminalType','allShow'],
        mixins: [clickMixin],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: [],
                show: false,
                type: '',
                render: 0,
                isDayStarts: false
            }
        },
        methods: {

            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.isDayStarts == undefined)
                    this.isDayStarts = false

                var IsSelected = false;
                if (this.isSelect) {
                    IsSelected = true;
                }
                var allShown = false;
                if (this.allShow) {
                    allShown = true;
                }

                
                var url = ""
                if (root.terminalType == 'All') {
                     url = '/Company/TerminalList?isActive=false' + '&IsSelected=true' + '&allShown=' + allShown
                }
                else {
                    url = '/Company/TerminalList?isActive=true' + '&IsSelected=' + IsSelected + '&terminalType=' + root.terminalType + '&allShown=' + allShown
                }
                this.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                    if (response.data != null) {
                        
                        response.data.terminals.forEach(function (cat) {
                            root.options.push({
                                id: cat.id,
                                name: cat.code
                            })
                        })
                    }


                }).then(function () {
                    if (root.values != undefined && root.values != '') {
                        root.options.forEach(function (x) {
                            if (x.id === root.values) {
                                

                                root.value.push({
                                    id: x.id,
                                    name: x.name
                                });
                                root.$emit('input', x.id);
                            }
                        })
                    }
                });
            },

           
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        

                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    
                    this.value = value;
                    this.$emit('input', value.id);


                }
            }
        },
        mounted: function () {
            this.getData();
            this.isDayStarts = this.isDayStart
        },
    }
</script>