<template>
    <div class="row" v-if="isValid('CanAddRunPayroll') || isValid('CanDraftRunPayroll') || isValid('CanEditOpenRunPayroll')">
        <div class="col-lg-12 col-sm-12 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">

            <div class="card">
                <div class="card-header mt-3 mb-3">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group">
                            <span class=" DayHeading">{{ $t('AddRunPayroll.AddRunPayroll') }}</span>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group text-right">
                            <label class="font-weight-bold">{{ $t('AddRunPayroll.Status') }}: </label>
                            <span class="badge badge-success" v-if="!runPayroll.status">{{ $t('AddRunPayroll.Open') }}</span>
                            <span class="badge badge-danger" v-else>{{ $t('AddRunPayroll.Close') }}</span>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group" v-if="runPayroll.id=='00000000-0000-0000-0000-000000000000'">
                            <label class="text  font-weight-bolder">{{ $t('AddRunPayroll.PayPeriod') }} :<span class="LabelColour"> *</span></label>
                            <payroll-schedule-dropdown v-model="runPayroll.payrollScheduleId" :values="runPayroll.payrollScheduleId" @input="GetPayrollSchedule" />
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group">
                            <label>{{ $t('AddRunPayroll.Designation') }}:</label>
                            <designationDropdown v-model="designationId" @input="GetSalaryTemplateData" />
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-group">
                            <label>{{ $t('AddRunPayroll.Department') }}:</label>
                            <departmentDropdown v-model="departmentId" @input="GetSalaryTemplateData" />
                        </div>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                            <table class="table add_table_list_bg" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <thead v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr>
                                        <th>#</th>
                                        <th>
                                            {{ $t('AddRunPayroll.EmployeeEnglish') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.EmployeeArabic') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.BaseSalary') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.Allowance') }}
                                        </th>
                                        <th class="text-center" v-if="runPayroll.hourly">
                                            {{ $t('AddRunPayroll.Hour') }}
                                        </th>
                                        <th v-if="runPayroll.hourly">
                                            {{ $t('AddRunPayroll.HourAmount') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.GrossSalary') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.Deduction') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.CONTRIBUTION') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.IncomeTax') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.Loan') }}
                                        </th>
                                        <th>
                                            {{ $t('AddRunPayroll.NetSalary') }}
                                        </th>
                                        <!--<th>

                                        </th>-->
                                    </tr>
                                </thead>
                                <tbody v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
                                    <tr v-for="(salaryTemplate ,index) in runPayroll.salaryTemplateList" v-bind:key="salaryTemplate.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="EditSalaryTemplate(salaryTemplate, salaryTemplate.id)">{{salaryTemplate.employeeEnglishName}}</a>
                                            </strong>
                                        </td>
                                        <td>
                                            {{salaryTemplate.employeeArabicName}}
                                        </td>
                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.baseSalary).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>

                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.allowanceAmount).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>


                                        <td v-if="runPayroll.hourly">
                                            <input v-model="salaryTemplate.hour" @keyup="updateLineTotal(salaryTemplate.hour, salaryTemplate)" type="number" @focus="$event.target.select()" class="form-control text-center" />

                                        </td>

                                        <td v-if="runPayroll.hourly">
                                            {{currency}} {{  parseFloat(salaryTemplate.hourAmount).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>

                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.grossSalary).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.deductionAmount).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.contributionAmount).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.incomeTaxAmount).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.loanAmount).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <td>
                                            {{currency}} {{  parseFloat(salaryTemplate.netSalary).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}
                                        </td>
                                        <!--<td>
                                            <a href="javascript:void(0)" class="btn btn-danger btn-sm btn-icon btn-round" v-on:click="removeItem(salaryTemplate.id)"><i class=" fa fa-trash"></i></a>
                                        </td>-->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="float-left">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-right">
                        <div class="overflow-auto" v-on:click="GetBrandData()">
                            <b-pagination pills size="lg" v-model="currentPage"
                                          :total-rows="rowCount"
                                          :per-page="10"
                                          first-text="First"
                                          prev-text="Previous"
                                          next-text="Next"
                                          last-text="Last"></b-pagination>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right" >
                            <button type="button" class="btn btn-danger" v-on:click="Close()">{{ $t('AddRunPayroll.Cancel') }} </button>
                            <button type="button" class="btn btn-primary mr-1 ml-1" v-bind:disabled="$v.runPayroll.$invalid" v-on:click="SaveSalaryTemplate(false)" v-if="isValid('CanDraftRunPayroll')">{{ $t('AddRunPayroll.Submit') }} </button>
                            <button type="button" class="btn btn-primary" v-bind:disabled="$v.runPayroll.$invalid" v-on:click="SaveSalaryTemplate(true)" v-if="isValid('CanAddRunPayroll')">{{ $t('AddRunPayroll.SubmitAndApprove') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <salaryTemplateModel :data="data"
                             :show="show"
                             v-if="show"
                             @close="show=false"
                             @input="UpdateRecord" />
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required } from 'vuelidate/lib/validators';
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                salaryTemplateId: '',
                designationId: '',
                departmentId: '',
                employeeId: '',
                currency: '',
                searchQuery: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
                data: '',
                show: false,

                runPayroll: {
                    id: '00000000-0000-0000-0000-000000000000',
                    payrollScheduleId: '',
                    status: false,
                    hourly: false,
                    salaryTemplateList: [],
                }
            }
        },
        validations: {
            runPayroll:
            {
                payrollScheduleId: {
                    required
                },
                salaryTemplateList: {
                    required
                },
            },

        },
        watch: {
            search: function () {
                this.GetSalaryTemplateData();
            }
        },
        methods: {
            Close: function () {
                if (this.isValid('CanViewRunPayroll')) {
                    this.$router.push('/RunPayroll');
                }
                else {
                    this.$router.go();
                }
               
            },

            UpdateRecord: function (record, salaryTemplate) {
                
                this.show = false;
                var result = this.runPayroll.salaryTemplateList.find((x) => {
                    return x.id == this.salaryTemplateId;
                });

                if (result != undefined && !salaryTemplate.zeroSalary) {
                    result.salaryDetailList = salaryTemplate.salaryDetailList;

                    result.autoIncomeTax = salaryTemplate.autoIncomeTax;
                    result.incomeTax = salaryTemplate.incomeTax;

                    result.grossSalary = record.grossSalary;
                    result.allowanceAmount = record.allowanceAmount;
                    result.deductionAmount = record.deductionAmount;
                    result.contributionAmount = record.contributionAmount;
                    result.incomeTaxAmount = record.taxPerPeriod;
                    result.netSalary = parseFloat(record.netSalary);
                    result.netSalary = parseFloat(record.netSalary) - result.loanAmount;
                    result.zeroSalary = false;
                    result.reason = '';
                }

                if (result != undefined && salaryTemplate.zeroSalary) {
                    result.employeeSalaryDetail = salaryTemplate;
                    result.runPayrollSalaryDetailList = [];

                    result.baseSalary = 0;
                    result.grossSalary = 0;
                    result.allowanceAmount = 0;
                    result.deductionAmount = 0;
                    result.contributionAmount = 0;
                    result.incomeTaxAmount = 0;
                    result.loanAmount = 0;
                    result.netSalary = 0;
                    result.zeroSalary = true;
                    result.reason = salaryTemplate.reason;
                }

            },

            updateLineTotal: function (e, item) {
                
                e = Math.round(e);
                item.hour = e;
                item.hourAmount = item.weekdayHourlySalary * e;
                item.netSalary = (item.grossSalary + item.hourAmount) - (item.deductionAmount + item.contributionAmount + item.incomeTaxAmount + item.loanAmount);
            },

            getPage: function () {
                this.GetSalaryTemplateData();
            },

            GetPayrollSchedule: function () {
                
                this.runPayroll.hourly = this.runPayroll.payrollScheduleId.isHourlyPay;
                this.runPayroll.payrollScheduleId = this.runPayroll.payrollScheduleId.id;
                this.GetSalaryTemplateData();
            },

            SaveSalaryTemplate: function (status) {
                this.runPayroll.status = status;

                this.loading = true;
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Payroll/SaveRunPayroll', root.runPayroll, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        if (response.data.isSuccess) {
                            root.loading = false

                            root.$swal({
                                title: "Saved!",
                                text: "Saved Sucessfully",
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    if (root.isValid('CanViewRunPayroll')) {
                                        root.$router.push('/RunPayroll');
                                    }
                                    else {
                                        root.$router.go();
                                    }
                                }
                            });
                        }

                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,

                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            },


            GetSalaryTemplateData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                

                root.$https.get('Payroll/RunPayrollDetailList?searchTerm=' + this.search + '&pageNumber=' + this.currentPage + '&designationId=' + this.designationId + '&departmentId=' + this.departmentId + '&payrollScheduleId=' + this.runPayroll.payrollScheduleId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        response.data.results.forEach(function (result) {
                            result.hour = 0;
                            result.hourAmount = 0;
                            result.zeroSalary = false;
                            result.reason = '';
                        });

                        root.runPayroll.salaryTemplateList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },

            EditSalaryTemplate: function (detail, id) {
                var root = this;

                root.data = detail;
                root.salaryTemplateId = id;
                root.show = !root.show;
            },

            
            removeItem: function (id) {
                this.runPayroll.salaryTemplateList = this.runPayroll.salaryTemplateList.filter((prod) => {
                    return prod.id != id;
                });

            },

        },

        created: function () {
            this.$emit('input', this.$route.name);
            var root = this;
            if (this.$route.query.data != undefined) {

                var data = this.$route.query.data;
                this.runPayroll.id = data.id;
                this.runPayroll.status = data.status;
                this.runPayroll.hourly = data.hourly;
                this.runPayroll.payrollScheduleId = data.payrollScheduleId;
                this.runPayroll.salaryTemplateList = data.salaryTemplateList;
                
                if (this.runPayroll.hourly) {
                    this.runPayroll.salaryTemplateList.forEach(function (result) {
                        root.updateLineTotal(result.hour, result);
                    });
                }

            }
        },

        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.currency = localStorage.getItem('currency');
        }
    }
</script>