<template>
    <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-if=" isValid('Can Update Promotion') || isValid('Can Save Promotion') " v-bind:style="$i18n.locale == 'ar' ? languageChange('en') : languageChange('ar')">
        <div class="col-md-8 ml-auto mr-auto" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card">
                <div class="card-header">
                    <h5 class="modal-title DayHeading" id="myModalLabel" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">{{ $t('AddPromotion.Promotions') }}</h5>

                </div>
                <div class="card-body">
                    <div class="row ">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <label>{{ $t('AddPromotion.PromotionName') }} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.offer.$error}">
                                <input class="form-control" v-model="$v.promotion.offer.$model" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                                <span v-if="$v.promotion.offer.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.offer.required">{{ $t('AddPromotion.Offerequired') }}</span>
                                    <span v-if="!$v.promotion.offer.maxLength">{{ $t('AddPromotion.OfferMaximum') }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-if="promotion.id=='00000000-0000-0000-0000-000000000000'">
                            <label>{{ $t('AddPromotion.ProductList') }} :<span class="LabelColour"> *</span></label>
                            <div>
                                <product-dropdown :emptyselect="true" v-model="productList" v-bind:productIds="productList" :status="'isPromotion'" :type=false></product-dropdown>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6" v-if="promotion.id!='00000000-0000-0000-0000-000000000000'">
                            <label>{{ $t('AddPromotion.ProductList') }} :<span class="LabelColour"> *</span></label>
                            <div>
                                <input type="text" name="name" class="form-control" v-model="promotion.name" disabled v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" />
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <label>{{ $t('AddPromotion.FixedDiscount') }} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.fixedDiscount.$error}">
                                <input class="form-control" @focus="$event.target.select()" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.promotion.fixedDiscount.$model" :disabled="promotion.discountPercentage != 0" />
                                <span v-if="$v.promotion.fixedDiscount.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.fixedDiscount.maxLength">{{ $t('AddPromotion.FixedDiscountmax') }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <label>{{ $t('AddPromotion.Discount') }} % :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.discountPercentage.$error}">
                                <input class="form-control" @focus="$event.target.select()" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.promotion.discountPercentage.$model" :disabled="promotion.fixedDiscount != 0" />
                                <span v-if="$v.promotion.discountPercentage.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.discountPercentage.maxLength">{{ $t('AddPromotion.DiscountageMaximum') }}</span>
                                </span>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <label>{{ $t('AddPromotion.FromDate') }}:<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.fromDate.$error}">
                                <datepicker v-model="$v.promotion.fromDate.$model" :key="daterander"></datepicker>
                                <span v-if="$v.promotion.fromDate.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.fromDate.required">From Date is Required</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <label>{{ $t('AddPromotion.ToDate') }} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.toDate.$error}">
                                <datepicker v-model="$v.promotion.toDate.$model" :key="daterander"></datepicker>
                                <span v-if="$v.promotion.toDate.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.toDate.required">To Date is Required</span>
                                </span>
                                <span v-if="promotion.toDate<promotion.fromDate" class="text-danger">{{ $t('AddPromotion.ToDateMust') }}</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label>{{ $t('AddPromotion.Quantity') }} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.quantity.$error}">
                                <input class="form-control" type="number" @focus="$event.target.select()" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.promotion.quantity.$model" />
                                <span v-if="$v.promotion.quantity.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.quantity.required">{{ $t('AddPromotion.Required') }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label>{{ $t('AddPromotion.StockLimit') }} :<span class="LabelColour"> *</span></label>
                            <div v-bind:class="{'has-danger' : $v.promotion.stockLimit.$error}">
                                <input class="form-control" @focus="$event.target.select()" type="number" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.promotion.stockLimit.$model" />
                                <span v-if="$v.promotion.stockLimit.$error" class="error text-danger">
                                    <span v-if="!$v.promotion.stockLimit.required">{{ $t('AddPromotion.Required') }}</span>
                                </span>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label style="margin: 7px;">{{ $t('AddPromotion.Active') }}</label> <br />
                            <toggle-button v-model="promotion.isActive" class="mt-1" color="#3178F6" />

                        </div>
                        <div class="col-sm-12 arabicLanguage" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <button type="button" class="btn btn-success  " :disabled="$v.promotion.$invalid" v-on:click="SaveOffer" v-if="promotion.id=='00000000-0000-0000-0000-000000000000' && isValid('Can Save Promotion') "><i class="far fa-save"></i> {{ $t('AddPromotion.btnSave') }}</button>
                            <button type="button" class="btn btn-success  " :disabled="$v.promotion.$invalid" v-on:click="SaveOffer" v-if="promotion.id!='00000000-0000-0000-0000-000000000000' && isValid('Can Edit Promotion') ">{{ $t('AddPromotion.btnUpdate') }}</button>

                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="Close()">{{ $t('AddPromotion.btnClear') }}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import { required, maxLength, requiredIf } from 'vuelidate/lib/validators';
    import moment from 'moment';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default ({
        mixins: [clickMixin],
        data: function () {
            return {
                promotion: {
                    id: '00000000-0000-0000-0000-000000000000',
                    offer: '',
                    productId: [],
                    discountPercentage: 0,
                    fixedDiscount: 0,
                    toDate: '',
                    fromDate: '',
                    quantity: 0,
                    stockLimit: 0,
                    isActive: true
                },
                productList: '',
                language: 'Nothing',
                listOfProduct: [],
                loading: false,
                daterander: 0
            }
        },
        validations: {
            promotion:
            {
                offer: { required, maxLength: maxLength(50) },
                /*productId: { required },*/
                discountPercentage: {
                    required: requiredIf(function (model) {
                        if (model.id == '') {
                            return true;
                        }
                        return false;
                    }), maxLength: maxLength(3)
                },
                fixedDiscount: {
                    required: requiredIf(function (model) {
                        if (model.id == '') {
                            return true;
                        }
                        return false;
                    }), maxLength: maxLength(3)
                },
                toDate: { required },
                fromDate: { required },
                quantity: { required },
                stockLimit: { required },
                isActive: {}
            }
        },
        methods: {
            languageChange: function (lan) {

                if (this.language == lan) {
                    if (this.promotion.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addSale');
                    }
                    else {
                        this.$swal({
                            title: 'Error!',
                            text: 'You cannot Change language during update, otherwise your current page data will be lose',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            Close: function () {
                this.$router.push('/promotion');
            },
            getProductList: function () {
                for (var i = 0; i < this.productList.length; i++) {
                    this.listOfProduct[i] = this.productList[i].id
                }
            },
            SaveOffer: function () {
                this.loading = true;
                var root = this;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.promotion.id == '00000000-0000-0000-0000-000000000000') {
                    this.promotion.productId.push({ id: root.productList });
                }
                root.promotion.toDate = root.promotion.toDate + " " + moment().format("hh:mm A");
                root.promotion.fromDate = root.promotion.fromDate + " " + moment().format("hh:mm A");
                root.$https
                    .post('/Product/SavePromotionOffer', root.promotion, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/promotion');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: "Saved!",
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push('/promotion');
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch(error => {
                        this.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                                showConfirmButton: false,
                                timer: 1000,
                                timerProgressBar: true,

                            });

                        this.loading = false
                    })
                    .finally(() => this.loading = false)
            }
        },
        created: function () {

            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            if (this.$route.query.data == undefined) {
                this.promotion.fromDate = moment().format('llll');
                this.promotion.toDate = moment().format('llll');
                this.daterander++;
            }
            if (this.$route.query.data != undefined) {
                this.promotion = this.$route.query.data;
                this.promotion.id = this.$route.query.data.id;
                this.daterander++;
            }
        }
    })
</script>