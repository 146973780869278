<template>
    <div v-if="isValid('CanEditServiceQuotation') || isValid('CanDraftServiceQuotation') || isValid('CanAddServiceQuotation')" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 poHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <span v-if="purchase.id === '00000000-0000-0000-0000-000000000000'">{{ $t('AddQuotation.AddQuotation') }} <span style="font-weight:bold">  - {{purchase.registrationNo}}</span></span>
                                <span v-else>{{ $t('AddQuotation.UpdateQuotation') }} <span style="font-weight:bold">  - {{purchase.registrationNo}}</span></span>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 dateHeading" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                {{purchase.date}}
                            </div>
                        </div>
                        <div class="row bottomBorder"></div>


                        <div class="row mt-3">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.PaymentMethod') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger' : $v.purchase.paymentMethod.$error}">
                                    <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight())" v-model="$v.purchase.paymentMethod.$model" v-on:input="RanderCustomer" :options="['Cash', 'Credit']" :show-labels="false" v-bind:placeholder="$t('SelectOption')">
                                    </multiselect>
                                    <multiselect v-else v-model="$v.purchase.paymentMethod.$model" v-on:input="RanderCustomer" :options="['آجـل', 'الـنـقـدي']" :show-labels="false" v-bind:placeholder="$t('SelectOption')">
                                    </multiselect>
                                    <span v-if="$v.purchase.paymentMethod.$error" class="error text-danger">
                                        <span v-if="!$v.purchase.paymentMethod.required">{{ $t('PaymentMethodRequired') }}</span>
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.Customer') }} :<span class="LabelColour"> *</span></label>
                                <div v-bind:class="{'has-danger': $v.purchase.customerId.$error,}">
                                    <customerdropdown v-model="$v.purchase.customerId.$model" :paymentTerm="purchase.paymentMethod" v-bind:values="purchase.customerId" :key="randerCustomer" />
                                    <span v-if="$v.purchase.customerId.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.Refrence') }} :</label>
                                <div v-bind:class="{'has-danger': $v.purchase.refrence.$error,}">
                                    <input class="form-control" style="height:42px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                           v-model="$v.purchase.refrence.$model" />
                                    <span v-if="$v.purchase.refrence.$error"
                                          class="error text-danger">
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.ClientPurchaseNo') }} :</label>
                                <input class="form-control" style="height:42px;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                       v-model="purchase.clientPurchaseNo" />
                            </div>

                            <div v-if="saleDefaultVat=='DefaultVatHead' || saleDefaultVat=='DefaultVatHeadItem'" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class=" $i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.TaxMethod') }} :<span class="LabelColour"> *</span></label>
                                <multiselect v-if="this.$i18n.locale == 'en'" :options="['Inclusive', 'Exclusive']" v-bind:disabled="purchase.saleOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('AddStockValue.SelectMethod')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                                <multiselect v-else :options="['شامل', 'غير شامل']" v-bind:disabled="purchase.saleOrderItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('AddStockValue.SelectMethod')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                </multiselect>
                            </div>
                            <div v-if="saleDefaultVat=='DefaultVatHead' || saleDefaultVat=='DefaultVatHeadItem'" class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddPurchase.VAT%') }} :<span class="LabelColour"> *</span></label>
                                <taxratedropdown v-model="purchase.taxRateId" v-bind:value="purchase.taxRateId" :isDisable="purchase.saleOrderItems.length>0? true :false" />
                            </div>
                        </div>

                        <div class="row" v-if="purchase.paymentMethod=='Cash' || purchase.paymentMethod=='السيولة النقدية' " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label>{{ $t('AddQuotation.SheduleDelivery') }} :</label>
                                <div>
                                    <multiselect v-if="($i18n.locale == 'en' ||isLeftToRight())" v-model="purchase.sheduleDelivery" :options="['Advance', 'After Delivery']" :show-labels="false" v-bind:placeholder="$t('SelectOption')">
                                    </multiselect>
                                    <multiselect v-else v-model="purchase.sheduleDelivery" :options="['تقدم', 'بعد الولادة']" :show-labels="false" v-bind:placeholder="$t('AddQuotation.SelectOption')">
                                    </multiselect>

                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 " v-if="purchase.sheduleDelivery=='After Delivery' || purchase.sheduleDelivery=='بعد الولادة'">
                                <label>{{ $t('AddQuotation.Days') }} :</label>
                                <div>
                                    <input class="form-control " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'"
                                           v-model="purchase.days" />
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <label class="row pl-3 mt-2 ">{{ $t('AddQuotation.DeliveryTerms') }}  :</label>
                                <div class="row pl-3 " :key="render+'add'">
                                    <label>{{ $t('AddQuotation.Fregiht') }}  :</label> <toggle-button v-model="purchase.isFreight" class="ml-2" color="#3178F6" style="z-index:0 !important" />
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                                <div class="row pl-3 pt-2 mt-4" :key="render+'add'">

                                    <label>{{ $t('AddQuotation.Labour') }} :</label> <toggle-button v-model="purchase.isLabour" class="ml-2" color="#3178F6" style="z-index:0 !important" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 form-group" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <label>{{ $t('AddQuotation.Description') }}  :</label>
                                <textarea class="form-control" v-model="purchase.description"></textarea>
                            </div>
                        </div>

                        <br />

                        <quotation-service-item @input="SavePurchaseItems" :key="rander" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" />

                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <label>{{ $t('AddQuotation.TermAndConditionEnglish') }}:</label>
                                <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="purchase.note" />
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <label>{{ $t('AddQuotation.TermAndConditionArabic') }}:</label>
                                <textarea class="form-control " rows="3" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'" v-model="purchase.noteAr" />
                            </div>
                        </div>

                        <div class="row">
                            <div v-if="!loading && purchase.id === '00000000-0000-0000-0000-000000000000'" class="col-md-12  " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddQuotation.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Draft')"
                                        v-if="isValid('CanDraftServiceQuotation')"
                                        v-bind:disabled="$v.$invalid ">
                                    <i class="far fa-save"></i>  {{ $t('AddQuotation.SaveAsDraft') }}
                                </button>
                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Approved')"
                                        v-if="isValid('CanAddServiceQuotation')"
                                        v-bind:disabled="$v.$invalid ">
                                    <i class="far fa-save"></i>  {{ $t('AddQuotation.SaveAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddQuotation.Cancel') }}
                                </button>

                            </div>
                            <div v-if="!loading && purchase.id!='00000000-0000-0000-0000-000000000000'" class="col-md-12 " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <button class="btn btn-primary mr-2 float-left" v-on:click="Attachment()">
                                    {{ $t('AddQuotation.Attachment') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Draft')"
                                        v-if="isValid('CanDraftServiceQuotation')"
                                        v-bind:disabled="$v.$invalid">
                                    <i class="far fa-save"></i>  {{ $t('AddQuotation.UpdateAsDraft') }}
                                </button>

                                <button class="btn btn-primary  mr-2"
                                        v-on:click="savePurchase('Approved')"
                                        v-if="isValid('CanEditServiceQuotation')"
                                        v-bind:disabled="$v.$invalid ">
                                    <i class="far fa-save"></i> {{ $t('AddQuotation.UpdateAsPost') }}
                                </button>
                                <button class="btn btn-danger  mr-2"
                                        v-on:click="goToPurchase">
                                    {{ $t('AddQuotation.Cancel') }}
                                </button>
                            </div>
                            <div class="card-footer col-md-3" v-else>
                                <loading :active.sync="loading"
                                         :can-cancel="true"
                                         :is-full-page="true"></loading>
                            </div>

                        </div>
                        

                    </div>

                </div>
            </div>
            <bulk-attachment :attachmentList="purchase.attachmentList" :show="show" v-if="show" @close="attachmentSave" />

        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect';

    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
            Loading
        },

        data: function () {
            return {
                saleDefaultVat: '',
                randerCustomer: 0,
                daterander: 0,
                rander: 0,
                render: 0,
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    customerId: "",
                    refrence: "",
                    days: '',
                    purchaseOrder: "",
                    paymentMethod: "",
                    sheduleDelivery: "",
                    note: '',
                    noteAr: '',
                    isFreight: false,
                    isLabour: false,
                    isQuotation: true,
                    saleOrderItems: [],
                    attachmentList: [],
                    path: '',
                    clientPurchaseNo: '',
                    description: '',
                    isService: true,
                    taxMethod: '',
                    taxRateId: '',
                },
                loading: false,
                show: false,
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                customerId: { required },
                refrence: {},

                paymentMethod: { required },

                saleOrderItems: { required },
            },
        },
        methods: {
            Attachment: function () {
                this.show = true;
            },

            attachmentSave: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.show = false;
            },

            RanderCustomer: function () {
                this.randerCustomer++;
            },

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/SaleOrderAutoGenerateNo?isQuotation=" + root.purchase.isQuotation, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (saleOrderItems) {

                this.purchase.saleOrderItems = saleOrderItems;
            },
            savePurchase: function (status) {
                this.purchase.approvalStatus = status
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.purchase.saleOrderItems.forEach(function (x) {
                    x.quantity = x.totalPiece;
                });
                this.$https.post('/Purchase/SaveSaleOrderInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        root.loading = false
                        root.info = response.data

                        root.$swal({
                            title: "Saved!",
                            text: "Data Saved Successfully!",
                            type: 'success',
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,
                        }).then(function (response) {
                            if (response != undefined) {
                                if (root.purchase.id == "00000000-0000-0000-0000-000000000000") {
                                    root.$router.go('AddServiceQuotation');

                                } else {
                                    if (root.isValid('CanDraftServiceQuotation') || root.isValid('CanViewServiceQuotation')) {
                                        root.$router.push('/ServiceQuotation');
                                    }
                                    else {
                                        root.$router.go();
                                    }
                                }
                            }
                        });

                    })
                    .catch(error => {
                        console.log(error)
                        if (localStorage.getItem('IsMultiUnit') == 'true') {
                            root.purchase.saleOrderItems.forEach(function (x) {

                                x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.unitPerPack));
                                x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.unitPerPack));

                            });
                        }
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },

            goToPurchase: function () {
                if (this.isValid('CanDraftServiceQuotation') || this.isValid('CanViewServiceQuotation')) {
                    this.$router.push('/ServiceQuotation');
                }
                else {
                    this.$router.go();
                }
                
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.saleDefaultVat = localStorage.getItem('SaleDefaultVat');

            if (this.$route.query.data != undefined) {

                this.purchase = this.$route.query.data;
                this.purchase.date = moment(this.purchase.date).format('llll');
                if (localStorage.getItem('IsMultiUnit') == 'true') {

                    this.purchase.saleOrderItems.forEach(function (x) {

                        x.highQty = parseInt(parseInt(x.quantity) / parseInt(x.product.unitPerPack));
                        x.quantity = parseInt(parseInt(x.quantity) % parseInt(x.product.unitPerPack));
                        x.unitPerPack = x.product.unitPerPack;
                    });
                }
                this.attachment = true;
                this.rander++;
                this.render++;
                this.rendered++;
            }
            else {
                this.purchase.wareHouseId = localStorage.getItem('WareHouseId');
                this.purchase.taxRateId = localStorage.getItem('TaxRateId');
                this.purchase.taxMethod = localStorage.getItem('taxMethod');
            }
        },
        mounted: function () {

            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.purchase.date = moment().format('llll');
                this.daterander++;
            }
        },
    };
</script>

<style scoped>
    .poHeading {
        font-size: 32px;
        font-style: normal;
        line-height: 37px;
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        color: #3178F6;
        text-align: center
    }

    .dateHeading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #35353D;
    }

    .bottomBorder {
        padding-top: 24px !important;
        border-bottom: 1px solid #EFF4F7;
    }
</style>

