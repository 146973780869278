<template>
    <div>
        <div hidden id='customerBalancePrintReport' class="col-md-7">
            <!--HEADER-->
            <div class="col-md-12" style="height:45mm;border:2px solid #000000;">
                <div class="row" style="height:35mm">
                    <div class="col-md-4 ">
                        <table class="text-left">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameEnglish}}</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryEnglish}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">VAT No.: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">Cr No.:{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            Tel: {{headerFooters.company.phoneNo}}
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-4 text-center my-5" style="padding:0px !important; margin:0 !important">
                        <img :src="headerFooters.company.logoPath" style="width:auto;max-width:300px; max-height:100px; padding:5px !important; margin:0 !important">
                    </div>
                    <div class="col-md-4 ">
                        <table class="text-right" v-if="arabic=='true'">
                            <tr>
                                <td>
                                    <p>
                                        <span style="font-size:25px;color:black !important;font-weight:bold;">{{headerFooters.company.nameArabic}}.</span><br />
                                        <span style="font-size:17px;color:black !important;font-weight:bold;">{{headerFooters.company.categoryArabic}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم ضريبة القيمة المضافة: {{headerFooters.company.vatRegistrationNo}}</span><br />
                                        <span style="font-size:16px;color:black !important;font-weight:bold;">رقم السجل التجاري :{{headerFooters.company.companyRegNo}}</span><br />
                                        <span style="font-size:15px;color:black !important;font-weight:bold;">
                                            هاتف: {{headerFooters.company.phoneNo}}:
                                        </span>
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row " style="margin-bottom:10px !important;height:10mm">
                    <div class="col-md-12">
                        <p style="text-align: center; margin: 0px; padding: 0px; line-height: 1; ">
                            <span style="font-size:25px;color:black !important;font-weight:bold;padding-bottom:5px !important">
                                <span v-if="formName=='Customer'">{{ $t('CustomerBalancePrintReport.CustomerBalanceReport') }} </span>
                                <span v-if="formName=='Supplier'">{{ $t('CustomerBalancePrintReport.SupplierBalanceReport') }}</span>
                            </span>
                        </p>
                    </div>
                </div>

            </div>
            <!--INFORMATION-->
            <div class="border border-dark col-md-12" style="height:50mm;" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                <br />
                <div class="row">
                    <div class="col-md-7" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">From Date:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{fromDate}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:من التاريخ</div>
                    </div>
                    <div class="col-md-5" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">To Date:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{toDate}}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :حتي اليوم
                        </div>
                    </div>
                </div>

                <div class="row" v-if="formName=='Customer'">
                    <div class="col-md-7" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">Customer Name:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;" v-if="ledgers.customerNameAr !=''">{{ledgers.customerNameAr}}</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;" v-else-if="ledgers.customerNameEn !=''">{{ledgers.customerNameEn}}</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;" v-else>{{ledgers.customerNameEn}} {{ledgers.customerNameAr}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:اسم الزبون</div>
                    </div>
                    <div class="col-md-5" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">Customer Vat:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{ledgers.customerVat}}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :العميل ضريبة
                        </div>
                    </div>
                </div>
                <div class="row" v-if="formName=='Supplier'">
                    <div class="col-md-7" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">Supplier Name:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;" v-if="ledgers.customerNameAr !=''">{{ledgers.customerNameAr}}</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;" v-else-if="ledgers.customerNameEn !=''">{{ledgers.customerNameEn}}</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;" v-else>{{ledgers.customerNameEn}} {{ledgers.customerNameAr}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:اسم المورد</div>
                    </div>
                    <div class="col-md-5" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">Customer Vat:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{ledgers.customerVat}}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :العميل ضريبة
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">Customer No:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{ledgers.customerNo}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:رقم العميل</div>
                    </div>

                    <div class="col-md-5" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">Tel:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{ledgers.phoneNumber}}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :هاتف
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7" style="display:flex;">
                        <div style="width:28%; font-weight:bolder;text-align:right;color:black !important;">Address:</div>
                        <div style="width:50%; text-align:center;font-weight:bold;color:black !important;">{{ledgers.address}}</div>
                        <div style="width:22%;font-weight:bolder;color:black !important;font-size:15px !important" v-if="arabic=='true'">:تبوك </div>
                    </div>
                    <div class="col-md-5" style="display:flex;">

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7" style="display:flex;">
                        <div style="width:28%;font-weight:bolder;text-align:right;color:black !important;">Opening Balance:</div>
                        <div style="width:50%;text-align:center;font-weight:bold;color:black !important;"><span>{{ledgers.openingBalance>0?'Dr':'Cr'}} {{nonNegative(ledgers.openingBalance) }}</span> </div>
                        <div style="width:22%;font-weight:bolder;color:black !important; padding-right:20px;font-size:15px !important" v-if="arabic=='true'">
                            :الرصيد الافتتاحي
                        </div>
                    </div>
                    <div class="col-md-5" style="display:flex;">

                    </div>
                </div>
            </div>
            <div class="row ml-1 mr-1 mt-1">
                <table class="table col-md-12 m-auto">
                    <tr class="heading" style="font-size:13px !important" >
                        <th class="text-left" style="padding-top:3px !important; padding-bottom:3px !important;">#</th>
                        <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">  {{ $t('CustomerBalancePrintReport.Date') }}</th>
                        <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">{{ $t('CustomerBalancePrintReport.TransactionType') }}</th>
                        <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">{{ $t('CustomerBalancePrintReport.DocumentNumber') }}</th>
                        <th class="text-center" style="padding-top:3px !important; padding-bottom:3px !important;">{{ $t('CustomerBalancePrintReport.Description') }}</th>
                        <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;"> {{ $t('CustomerBalancePrintReport.Debit') }}</th>
                        <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;">{{ $t('CustomerBalancePrintReport.Credit') }}</th>
                        <th class="text-right" style="padding-top:3px !important; padding-bottom:3px !important;">
                            {{ $t('CustomerBalancePrintReport.Balance') }}
                        </th>

                    </tr>
                    <tr style="font-size:13px !important;" v-for="(item, index) in ledgers.contactList" v-bind:key="item.id">
                        <td class="text-left">{{index+1}}</td>
                        <td class="text-left">{{item.date}}</td>
                        <td class="text-left">{{item.transactionType}}</td>
                        <td class="text-left">{{item.documentNumber}}</td>
                        <td class="text-left">{{item.description}}</td>
                        <td class="text-right">
                            {{  nonNegative(item.debitAmount)}}
                        </td>
                        <td class="text-right">
                            {{nonNegative(item.creditAmount)}}
                        </td>
                        <td class="text-right">
                            {{item.openingBalance>0?'Dr':'Cr'}} {{nonNegative(item.openingBalance) }}
                        </td>
                    </tr>

                    <tr style="font-size:13px;">
                        <td class="text-right" colspan="5">
                            <h6>{{ $t('CustomerBalancePrintReport.ClosingBalance') }}:</h6>
                        </td>
                        <td class="text-right">
                            <h6>{{  nonNegative(ledger.totalDebit)}}</h6>
                        </td>
                        <td class="text-right">
                            <h6>{{nonNegative(ledger.totalCredit)}}</h6>
                        </td>
                        <td class="text-right">
                            <h6> {{ledger.runningBalance>0?'Dr':'Cr'}} {{nonNegative(ledger.runningBalance) }}</h6>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from "moment";
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['printDetails', 'formName', 'isPrint', 'fromDate', 'toDate', 'headerFooter', 'ledger'],
        data: function () {
            return {
                list: [],
                invoicePrint: '',
                ledgers: {
                    contactList: [],
                    openingBalance: 0,
                    runningBalance: 0,
                    totalCredit: 0,
                    totalDebit: 0,
                    customerNameEn: '',
                    customerNameAr: '',
                    address: '',
                    customerNo: '',
                    customerVat: '',
                    phoneNumber: '',
                },
                Print: false,
                openingCash: 0,
                totalBalance: 0,
                render: 0,
                customerNameEn: '',
                customerNameAr: '',
                isHeaderFooter: '',

                address: '',
                phoneNumber: '',
                customerNo: '',
                customerVat: '',
                headerFooters: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },
                english: '',
                arabic: '',

            }
        },

        mounted: function () {
           
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.invoicePrint = localStorage.getItem('InvoicePrint');
            var root = this;
            this.headerFooters = this.headerFooter
            root.Print = root.isPrint;
            this.ledgers = this.ledger;
            if (this.ledgers.contactList.length > 0) {
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }

        },
        methods: {
            nonNegative: function (value) {
                return parseFloat(Math.abs(value)).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
            },
            convertDate: function (x) {
                return moment(x).format('DD MMM YYYY');
            },
            printInvoice: function () {

                this.$htmlToPaper('customerBalancePrintReport');
            }
        }
    }
</script>