<template>
    <modal :show="show">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('UpdateInquiryStatus.UpdateStatus') }}</h5>

                        </div>
                        <div class="">
                            <div class="card-body" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="row ">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                        <label>{{ $t('UpdateInquiryStatus.Status') }}: <span class="LabelColour"> *</span></label>
                                        <multiselect :options="options" v-model="updateStatus.status" :show-labels="false" :placeholder="$t('UpdateInquiryStatus.SelectStatus')">

                                        </multiselect>
                                    </div>

                                    <div class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('UpdateInquiryStatus.ReasonDescription') }}: </label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="updateStatus.reason" type="text" />

                                    </div>



                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer " v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-right' : 'text-left'">
                                <button type="button" class="btn btn-primary  " v-on:click="UpdateStatus"> {{ $t('UpdateInquiryStatus.Save') }}</button>

                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('UpdateInquiryStatus.Close') }}</button>
                            </div>

                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </modal>

</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Multiselect from 'vue-multiselect'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        mixins: [clickMixin],
        props: ['show', 'inquiryId','status'],
        components: {
            Loading,
            Multiselect
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                render: 0,
                loading: false,
                options: ['Pending', 'Completed', 'Overdue', 'Closed'],
                value: '',
                updateStatus: {
                    status: '',
                    reason: '',
                    inquiryId:'00000000-0000-0000-0000-000000000000'
                }
            }
        },
        validations: {

        },
        methods: {

            close: function () {
                this.$emit('close');
            },


            UpdateStatus: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Project/UpdateInquiryStatus', this.updateStatus , { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data) {
                        root.$swal({
                            title: root.$t('SavedSuccessfully'),
                            text: "Status Changed Successfully",
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        })
                        root.$emit('input', response.data)
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            this.updateStatus.inquiryId = this.inquiryId
            this.updateStatus.status = this.status

        }
    }</script>