<template>
    <modal :show="show" v-if=" isValid('CanAddInquirySetup') || isValid('CanEditInquirySetup') ">

        <div style="margin-bottom:0px" class="card" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="modal-header" v-if="type=='Edit'">

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('addInquirySetup.UpdateInquirySetup') }}</h5>

                        </div>
                        <div class="modal-header" v-else>

                            <h5 class="modal-title DayHeading" id="myModalLabel"> {{ $t('addInquirySetup.CreateInquirySetup') }}</h5>

                        </div>
                        <div class="">
                            <div class="card-body" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <div class="row ">
                                    <div :key="render" class="form-group has-label col-sm-12 ">
                                        <label class="text  font-weight-bolder"> {{ $t('addInquirySetup.Code') }}:<span class="LabelColour"> *</span></label>
                                        <input disabled class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="module.code" type="text" />

                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.module.name.$error}">
                                        <label class="text  font-weight-bolder">{{ $t('addInquirySetup.Name') }}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.module.name.$model" type="text" />
                                        <span v-if="$v.module.name.$error" class="error">
                                            <span v-if="!$v.module.name.required"> {{ $t('addInquirySetup.NameRequired') }}</span>
                                        </span>
                                    </div>
                                    <div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.module.label.$error}">
                                        <label class="text  font-weight-bolder">{{ $t('addInquirySetup.Label') }}: <span class="LabelColour"> *</span></label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.module.label.$model" type="text" />
                                        <span v-if="$v.module.label.$error" class="error">
                                            <span v-if="!$v.module.label.required"> {{ $t('addInquirySetup.LabelRequired') }}</span>
                                        </span>
                                    </div>
                                    <!--<div class="form-group has-label col-sm-12 " v-bind:class="{'has-danger' : $v.module.description.$error}">
                                        <label class="text  font-weight-bolder"> {{ $t('addInquirySetup.Description') }}: </label>
                                        <textarea class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.module.description.$model" type="text" />
                                        <span v-if="$v.module.description.$error" class="error">{{ $t('addInquirySetup.descriptionLength') }}</span>
                                    </div>-->
                                    <div class="form-group has-label col-sm-11">
                                        <label class="text  font-weight-bolder">{{ $t('addInquirySetup.Question') }} : </label>
                                        <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="question" type="text" />

                                    </div>
                                    <div class="form-group has-label col-sm-1 mt-3">
                                        <button title="Add Item" v-on:click="AddQuestionToList"
                                                class="btn btn-secondary  btn-sm btn-round btn-icon float-right">
                                            <i class="nc-icon nc-simple-add"></i>
                                        </button>

                                    </div>


                                    <div class=" col-sm-12 " v-for="(ques,index) in module.moduleQuestionLookUps" :key="ques">
                                        <div class="row">
                                            <div class="col-lg-10">
                                                <p style="margin:0px !important;padding:0px !important">
                                                    Q{{index+1}}:{{ques.question}}

                                                </p>
                                            </div>
                                            <div class="col-lg-2 " style="margin:0px !important;padding:0px !important">
                                                <span style="margin:0px !important;padding:0px !important">
                                                    <button @click="RemoveQuestionFromList(ques.id)"
                                                            title="Remove Item"
                                                            style="margin:0px !important;padding:0px !important"
                                                            class="btn btn-secondary btn-neutral btn-sm btn-round btn-icon"
                                                            v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'float-right' : 'float-left'">
                                                        <i class="nc-icon nc-simple-remove"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="form-group col-md-12">
                                        <label style="margin: 7px;">{{ $t('addInquirySetup.Active') }}</label> <br />
                                        <div class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate" v-bind:class="{'bootstrap-switch-on': module.isActive, 'bootstrap-switch-off': !module.isActive}" v-on:click="module.isActive = !module.isActive" style="width: 72px;">
                                            <div class="bootstrap-switch-container" style="width: 122px; margin-left: 0px;">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-check-2"></i>
                                                </span>
                                                <span class="bootstrap-switch-label" style="width: 30px;">&nbsp;</span>
                                                <span class="bootstrap-switch-handle-off bootstrap-switch-success" style="width: 50px;">
                                                    <i class="nc-icon nc-simple-remove"></i>
                                                </span>
                                                <input class="bootstrap-switch" type="checkbox" data-toggle="switch" checked="" data-on-label="<i class='nc-icon nc-check-2'></i>" data-off-label="<i class='nc-icon nc-simple-remove'></i>" data-on-color="success" data-off-color="success">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!loading">
                            <div class="modal-footer justify-content-right" v-if="type=='Edit' && isValid('CanEditInquirySetup')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveModule" v-bind:disabled="$v.module.$invalid"> {{ $t('addInquirySetup.btnUpdate') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('addInquirySetup.btnClear') }}</button>
                            </div>
                            <div class="modal-footer justify-content-right" v-if="type!='Edit' && isValid('CanAddInquirySetup')">
                                <button type="button" class="btn btn-primary  " v-on:click="SaveModule" v-bind:disabled="$v.module.$invalid"> {{ $t('addInquirySetup.btnSave') }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{ $t('addInquirySetup.btnClear') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import { maxLength, required } from "vuelidate/lib/validators"
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        mixins: [clickMixin],
        props: ['show', 'module', 'type'],
        components: {
            Loading
        },
        data: function () {
            return {
                arabic: '',
                english: '',
                render: 0,
                loading: false,
                question: ''
            }
        },
        validations: {
            module: {
                name: {
                    required
                },
                label: {
                    required
                },
                description: {
                    maxLength: maxLength(200)
                }
            }
        },
        methods: {
            AddQuestionToList: function () {
                if (this.question != '') {
                    var uid = this.createUUID()
                    this.module.moduleQuestionLookUps.push({
                        id: uid,
                        question: this.question,
                        inquiryModuleId: '00000000-0000-0000-0000-000000000000'
                    })
                }

                this.question = ''
            },
            RemoveQuestionFromList: function (id) {

                this.module.moduleQuestionLookUps = this.module.moduleQuestionLookUps.filter((x) => {
                    return x.id != id;
                });

            },
            createUUID: function () {

                var dt = new Date().getTime();
                var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (dt + Math.random() * 16) % 16 | 0;
                    dt = Math.floor(dt / 16);
                    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
                return uuid;
            },
            close: function () {
                this.$emit('close');
            },
            GetAutoCodeGenerator: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/Project/InquiryModuleCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.module.code = response.data;
                        root.render++;
                    }
                });
            },
            SaveModule: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Project/SaveInquiryModule', this.module, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {
                            if (root.type != "Edit") {

                                root.$swal({
                                    title: "Saved!",
                                    text: "Saved Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close();
                            }
                            else {

                                root.$swal({
                                    title: "Update!",
                                    text: "Update Successfully!",
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();

                            }
                        }
                        else {
                            root.$swal({
                                title: "Error!",
                                text: "Your Module Name  Already Exist!",
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Something Went Wrong!',
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false);
            }
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
            if (this.module.id == '00000000-0000-0000-0000-000000000000' || this.module.id == undefined || this.module.id == '')
                this.GetAutoCodeGenerator();

        }
    }
</script>
